import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SCLoader } from 'rollup-allspark';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import {
  REVENUE_KEYS,
  ORDER_COUNT_KEYS,
  AVG_BASKET_KEYS,
  ANALYTICS_HEATMAP_RANGE_COLORS,
  ANALYTICS_HEATMAP_DEFAULT_COLOR,
  BUSY_MODE_KEYS,
} from '../../../config/constants/analytics';
import ProviderSpecificHeatmapChart from './ProviderSpecificHeatmapChart';
import AggregatedHeatMapChart from './AggregatedHeatmapChart';
import { DAYS } from '../../../config/constants/dateTime';
import { centToMoney, abbreviateNumber } from '../../../utils/getCurrency';
import { exportedCsvHeaderTitleForXAxis } from '../../../utils/nativeAnalyticsUtils';

const AnalyticsHeatmapContainer = ({
  analyticsData,
  analyticsDataLoading,
  targetKey,
  selectedTimePeriod,
  isComparisonEnabled,
  selectedProviderListForProviderComparison,
}) => {
  // CONSTANTS
  const currencyCodeList =
    analyticsData && analyticsData.length
      ? analyticsData.map(b => b.currency)
      : [];

  const branchCurrency =
    analyticsData && analyticsData.length
      ? analyticsData[0].currency_symbol
      : '';

  const shouldShowUsd = [...new Set(currencyCodeList)].length > 1;
  const currencySymbol = shouldShowUsd ? '$' : branchCurrency;
  const exportedCsvHeaderTitle =
    exportedCsvHeaderTitleForXAxis(selectedTimePeriod);
  const isRevenueKey = Object.values(REVENUE_KEYS).includes(targetKey);
  const isOrderCountChart = Object.values(ORDER_COUNT_KEYS).includes(targetKey);
  const isAvgBasketsKey = Object.values(AVG_BASKET_KEYS).includes(targetKey);
  const isBusyModeKey = Object.values(BUSY_MODE_KEYS).includes(targetKey);

  // HOOKS
  const [heatmapData, setHeatmapData] = useState([]);
  const [heatmapColorRanges, setHeatmapColorRanges] = useState([]);
  const [providerData, setProviderData] = useState([]);

  const initializeProviderData = () => {
    const initialData = {};

    selectedProviderListForProviderComparison.forEach(provider => {
      initialData[provider.id] = {};
      for (let i = 0; i < 7; i += 1) {
        for (let j = 0; j < 24; j += 1) {
          initialData[provider.id][`${i}-${j}`] = [0];
        }
      }
    });

    return initialData;
  };

  const isHeatmapDataIsZero = data =>
    data.every(day => day.every(value => parseFloat(value) === 0));

  useEffect(() => {
    if (analyticsData && analyticsData.length) {
      const hourlyAggregateData = new Array(7)
        .fill(null)
        .map(() => new Array(24).fill(0));
      analyticsData.forEach(item => {
        const date = new Date(item.date || item.start_date);
        const dayOfWeek = date.getDay();
        const hourOfDay = date.getHours();
        hourlyAggregateData[dayOfWeek][hourOfDay] += item[targetKey] || 0;
      });

      if (isRevenueKey || isAvgBasketsKey) {
        for (let i = 0; i < hourlyAggregateData.length; i += 1) {
          for (let j = 0; j < hourlyAggregateData[i].length; j += 1) {
            hourlyAggregateData[i][j] = centToMoney(hourlyAggregateData[i][j]);
          }
        }
      }

      // MAX VALUE & COLOR RANGES
      if (isHeatmapDataIsZero(hourlyAggregateData)) {
        // display no data
        const series = hourlyAggregateData.map((dayData, i) => ({
          name: DAYS[i],
          data: dayData.map((hourData, j) => ({ x: `${j}:00`, y: 0 })),
        }));
        setHeatmapData(series);
        setHeatmapColorRanges([
          {
            from: 0,
            to: 0,
            color: ANALYTICS_HEATMAP_DEFAULT_COLOR,
            name: 'No data',
          },
        ]);
      } else {
        let max = 0;

        hourlyAggregateData.forEach(day => {
          const maxHour = Math.max(...day);
          if (maxHour > max) max = maxHour;
        });

        const rangeCount = ANALYTICS_HEATMAP_RANGE_COLORS.length;
        const step = Math.ceil(max / rangeCount);

        const ranges = ANALYTICS_HEATMAP_RANGE_COLORS.map((color, index) => {
          const from = index * step;
          const to = (index + 1) * step;

          let name;
          if (isOrderCountChart || isBusyModeKey) {
            name = `${abbreviateNumber(from)} - ${abbreviateNumber(to)}`;
          } else {
            name = `${currencySymbol}${abbreviateNumber(
              from,
            )} - ${currencySymbol}${abbreviateNumber(to)}`;
          }

          return {
            from,
            to,
            color,
            name,
          };
        }).reverse();

        ranges.push({
          from: 0,
          to: 0,
          color: ANALYTICS_HEATMAP_DEFAULT_COLOR,
          name: isOrderCountChart || isBusyModeKey ? `0` : `${currencySymbol}0`,
        });
        setHeatmapColorRanges(ranges);

        // SERIES
        const rearrangedSeriesData = [];
        hourlyAggregateData.forEach((dayData, i) => {
          const hDataForDay = {
            name: DAYS[i],
            data: dayData.map((hourData, j) => ({ x: `${j}:00`, y: hourData })),
          };

          // Move Sunday to end of week
          if (i > 0) {
            rearrangedSeriesData[7 - i] = hDataForDay;
          } else {
            rearrangedSeriesData[i] = hDataForDay;
          }
        });
        setHeatmapData(rearrangedSeriesData);
      }

      // PROVIDER DATA
      setProviderData([]);
      const newProviderData = initializeProviderData(
        selectedProviderListForProviderComparison,
      );
      analyticsData.forEach(item => {
        const date = new Date(item.date);
        const dayOfWeek = date.getDay();
        const rearrangedDayOfWeek = dayOfWeek > 0 ? 7 - dayOfWeek : dayOfWeek;
        const hourOfDay = date.getHours();
        const providerKey = `${rearrangedDayOfWeek}-${hourOfDay}`;

        if (!newProviderData[item.provider_id]) {
          newProviderData[item.provider_id] = {};
        }

        if (!newProviderData[item.provider_id][providerKey]) {
          newProviderData[item.provider_id][providerKey] = [];
        }

        newProviderData[item.provider_id][providerKey].push(item[targetKey]);
      });
      setProviderData(newProviderData);
    }
  }, [analyticsData, targetKey]);

  // render
  const renderNoData = (
    <div className="flex justify-center items-center h-full text-2xl text-grey-darker">
      No Data Found!
    </div>
  );

  const renderAnalyticsHeatmapContainer = () => {
    if (analyticsDataLoading) {
      return (
        <div className="flex items-center justify-center h-full">
          <SCLoader lineWidth={5} diameter={50} />
        </div>
      );
    }

    if (analyticsData && analyticsData.length) {
      const renderChart = isComparisonEnabled ? (
        <ProviderSpecificHeatmapChart
          id={targetKey}
          data={heatmapData}
          currencySymbol={currencySymbol}
          exportedCsvHeaderTitle={exportedCsvHeaderTitle}
          heatmapColorRanges={heatmapColorRanges}
          isComparisonEnabled={isComparisonEnabled}
          providerData={providerData}
          selectedProviderListForProviderComparison={
            selectedProviderListForProviderComparison
          }
        />
      ) : (
        <AggregatedHeatMapChart
          id={targetKey}
          data={heatmapData}
          currencySymbol={currencySymbol}
          exportedCsvHeaderTitle={exportedCsvHeaderTitle}
          heatmapColorRanges={heatmapColorRanges}
          isComparisonEnabled={isComparisonEnabled}
          providerData={providerData}
          selectedProviderListForProviderComparison={
            selectedProviderListForProviderComparison
          }
        />
      );

      return renderChart;
    }
    return renderNoData;
  };

  return renderAnalyticsHeatmapContainer();
};

AnalyticsHeatmapContainer.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  targetKey: PropTypes.string,
  selectedTimePeriod: PropTypes.string,
  isComparisonEnabled: PropTypes.bool,
  selectedProviderListForProviderComparison: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  isComparisonEnabled: selectors.makeSelectComparisonEnabled(),
  selectedProviderListForProviderComparison:
    selectors.makeSelectSelectedProvidersForProviderComparison(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(AnalyticsHeatmapContainer);
