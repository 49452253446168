import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the profile state domain
 */

const selectNativeAnalyticsDomain = state =>
  state.nativeAnalytics || initialState;

export const makeSelectSummaryLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.summaryLoading,
  );
export const makeSelectAnalyticsDataLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsDataLoading,
  );
export const makeSelectAnalyticsData = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsData,
  );
export const makeSelectSummary = () =>
  createSelector(selectNativeAnalyticsDomain, substate => substate.summary);
export const makeSelectOrdersData = () =>
  createSelector(selectNativeAnalyticsDomain, substate => substate.ordersData);
export const makeSelectOrdersDataLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.ordersDataLoading,
  );
// BUSINESS
export const makeSelectAnalyticsBusinessLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.businessLoading,
  );
export const makeSelectAnalyticsBusinesses = () =>
  createSelector(selectNativeAnalyticsDomain, substate => substate.businesses);
export const makeSelectAnalyticsSelectedBusiness = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedBusiness,
  );
// BRAND
export const makeSelectAnalyticsBrandLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.brandLoading,
  );
export const makeSelectAnalyticsBrands = () =>
  createSelector(selectNativeAnalyticsDomain, substate => substate.brands);
export const makeSelectAnalyticsSelectedBrand = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedBrand,
  );
// BRANCH
export const makeSelectAnalyticsBranchLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.branchLoading,
  );
export const makeSelectAnalyticsBranches = () =>
  createSelector(selectNativeAnalyticsDomain, substate => substate.branches);
export const makeSelectAnalyticsSelectedBranch = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedBranch,
  );

// PROVIDERS
export const makeSelectAnalyticsProviders = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsProviders,
  );
export const makeSelectAnalyticsSelectedProvider = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedAnalyticsProvider,
  );
export const makeSelectAnalyticsProviderLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsProvidersLoading,
  );

export const makeSelectAnalyticsStartDate = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsStartDate,
  );

export const makeSelectAnalyticsEndDate = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsEndDate,
  );

export const makeSelectSelectedTimePeriod = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedTimePeriod,
  );
export const makeSelectSelectedTimePeriodStatus = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.isTimePeriodFilterStatusChanged,
  );
export const makeSelectComparisonEnabled = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.comparisonEnabled,
  );
export const makeSelectPaginationObj = () =>
  createSelector(selectNativeAnalyticsDomain, substate => substate.pagination);

export const makeSelectStatus = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.analyticsOrderStatus,
  );
export const makeSelectIsFirstTimeLoaded = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.isFirstTimeLoaded,
  );
export const makeSelectTimePeriodSlug = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.timePeriodSlug,
  );
export const makeSelectSelectedProvidersForProviderComparison = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedProviderListForProviderComparison,
  );
export const makeSelectItemBreakdownData = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.itemBreakDownData,
  );
export const makeSelectItemBreakDownDataLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.itemBreakDownDataLoading,
  );
export const makeSelectSelectedChannel = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedChannel,
  );

// Busy mode
export const makeSelectBusyModeAnalyticsData = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.busyModeAnalyticsData,
  );

export const makeSelectBusyModeAnalyticsDataLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.busyModeAnalyticsDataLoading,
  );

export const makeSelectBusyModeLogData = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.busyModeLogData,
  );
export const makeSelectBusyModeLogDataLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.busyModeLogDataLoading,
  );

// PAYMENT METHOD
export const makeSelectPaymentMethodLoading = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.paymentMethodDataLoading,
  );

export const makeSelectPaymentMethodData = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.paymentMethodData,
  );

export const makeSelectIsMoreThan30Days = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.isMoreThan30Days,
  );

export const makeSelectSelectedComparisonType = () =>
  createSelector(
    selectNativeAnalyticsDomain,
    substate => substate.selectedComparisonType,
  );

export const makeSelectProperty = key =>
  createSelector(selectNativeAnalyticsDomain, substate => substate[key]);
