export const GET_LIST = 'app/RatingAndReview/GET_LIST';
export const GET_LIST_SUCCESS = 'app/RatingAndReview/GET_LIST_SUCCESS';
export const GET_LIST_FAILED = 'app/RatingAndReview/SETTINGS_LIST_FAILED';

export const GET_DETAILS = 'app/RatingAndReview/GET_DETAILS';
export const GET_DETAILS_SUCCESS = 'app/RatingAndReview/GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILED = 'app/RatingAndReview/GET_DETAILS_FAILED';

export const CHANGE_ENTITY = 'app/RatingAndReview/CHANGE_ENTITY';

export const CREATE_SETTINGS = 'app/RatingAndReview/CREATE_SETTINGS';
export const DELETE_SETTINGS = 'app/RatingAndReview/DELETE_SETTINGS';
export const SETTINGS_RESOLVED = 'app/RatingAndReview/SETTINGS_RESOLVED';

export const SETTINGS_REJECTED = 'app/RatingAndReview/SETTINGS_REJECTED';
