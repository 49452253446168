export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAYS_OF_WEEK = [
  { id: 0, label: 'Sunday', shortLabel: 'Sun' },
  { id: 1, label: 'Monday', shortLabel: 'Mon' },
  { id: 2, label: 'Tuesday', shortLabel: 'Tue' },
  { id: 3, label: 'Wednesday', shortLabel: 'Wed' },
  { id: 4, label: 'Thursday', shortLabel: 'Thu' },
  { id: 5, label: 'Friday', shortLabel: 'Fri' },
  { id: 6, label: 'Saturday', shortLabel: 'Sat' },
];

export const DEFAULT_TIME_SLOTS = Array.from(
  { length: DAYS_OF_WEEK.length },
  () => [],
);

export const DEFAULT_DAY = {
  id: 7,
  label: 'Default',
  shortLabel: 'Default',
};

export const DEFAULT_SLOT = {
  id: 0,
  isValid: true,
  errorMsg: '',
  startTime: 0,
  endTime: 5,
};

export const DEFAULT_24_HOUR_SLOT = {
  id: 0,
  isValid: true,
  errorMsg: '',
  startTime: 0,
  endTime: 2359,
};
