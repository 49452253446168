/*
 *
 * Auth reducer
 *
 */
import { produce } from 'immer';
import {
  CHANGE_USER,
  CHANGE_PASSWORD,
  AUTH_FAILED,
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  AUTH_REQUESTED_BY_SOCIAL_SIGN_IN,
} from './constants';

export const initialState = {
  username: '',
  password: '',
  loginError: '',
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AUTH_REQUESTED:
        draft.loading = true;
        break;
      case CHANGE_USER:
        draft.username = action.username;
        break;
      case CHANGE_PASSWORD:
        draft.password = action.password;
        break;
      case AUTH_SUCCESS:
        draft.loading = initialState.loading;
        break;
      case AUTH_FAILED:
        draft.loginError = action.loginError;
        draft.loading = initialState.loading;
        break;
      case AUTH_REQUESTED_BY_SOCIAL_SIGN_IN:
        draft.loading = true;
        break;
    }
  });

export default authReducer;
