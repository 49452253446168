import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectIntegrationDomain = state => state.integration || initialState;

export const makeSelectLocationLoading = () =>
  createSelector(selectIntegrationDomain, substate => substate.locationLoading);
export const makeSelectLocations = () =>
  createSelector(selectIntegrationDomain, substate => substate.locations);
export const makeSelectBrands = () =>
  createSelector(selectIntegrationDomain, substate => substate.brands);
export const makeSelectSelectedLocation = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.selectedLocation,
  );
export const makeSelectLocationWiseBrands = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.locationWiseBrands,
  );
export const makeSelectBrandsWithStatus = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.brandsListWithStatus,
  );
export const makeSelectSelectedBrand = () =>
  createSelector(selectIntegrationDomain, substate => substate.selectedBrand);
export const makeSelectBrandStatusUpdateLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.brandStatusUpdateLoading,
  );
export const makeSelectPaginationObj = () =>
  createSelector(selectIntegrationDomain, substate => substate.pagination);
export const makeSelectBrandsListLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.brandsListLoading,
  );

export const makeSelectBusinesses = () =>
  createSelector(selectIntegrationDomain, substate => substate.businesses);
export const makeSelectSelectedBusiness = () =>
  createSelector(selectIntegrationDomain, substate => substate.business);
export const makeSelectBrandsFilter = () =>
  createSelector(selectIntegrationDomain, substate => substate.brandsFilter);
export const makeSelectProviders = () =>
  createSelector(selectIntegrationDomain, substate => substate.providers);
export const makeSelectTiedProviders = () =>
  createSelector(selectIntegrationDomain, substate => substate.tiedProviders);
export const makeSelectProvidersLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.providersLoading,
  );
export const makeSelectProvider = () =>
  createSelector(selectIntegrationDomain, substate => substate.provider);
export const makeSelectShowV1Form = () =>
  createSelector(selectIntegrationDomain, substate => substate.showV1Form);

export const makeSelectUpdateProviderCredLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.updateProviderCredLoading,
  );
export const makeSelectProviderCred = () =>
  createSelector(selectIntegrationDomain, substate => substate.providerCred);
export const makeSelectAutoaccept = () =>
  createSelector(selectIntegrationDomain, substate => substate.autoAccept);
export const makeSelectSelectedIntegrationType = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.selectedIntegrationType,
  );
export const makeSelectAutoPilot = () =>
  createSelector(selectIntegrationDomain, substate => substate.autoPilot);
export const makeSelectMenuSync = () =>
  createSelector(selectIntegrationDomain, substate => substate.menuSync);
export const makeSelectDeleteProviderLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.deleteProviderLoading,
  );
export const makeSelectIsSingleDevice = () =>
  createSelector(selectIntegrationDomain, substate => substate.isSingleDevice);

export const makeSelectOTPTokenForGoFood = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.otpTokenForGoFood,
  );

export const makeSelectIsOTPFieldVisibleForGoFood = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.isOTPFieldVisibleForGoFood,
  );

export const makeSelectSendOTPRequestForGoFoodLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.sendOTPRequestForGoFoodLoading,
  );

export const makeSelectVerifyOTPTokenForGoFoodLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.verifyOTPTokenForGoFoodLoading,
  );

export const makeSelectIsOTPFieldVisibleForShopee = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.isOTPFieldVisibleForShopee,
  );

export const makeSelectLoginShopeeCredentialsLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.loginShopeeCredentialsLoading,
  );

export const makeSelectLoginShopeeCredentialsSuccessful = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.loginShopeeCredentialsSuccessful,
  );

export const makeSelectLoginShopeeOTPPhoneNumber = () =>
  createSelector(selectIntegrationDomain, substate => substate.shopeeOTPPhone);

export const makeSelectUberEatsLoginUrl = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.uberEatsLoginUrl,
  );

export const makeSelectUberEatsLoginUrlLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.getUberEatsLoginUrlLoading,
  );

export const makeSelectIsUberEatsLoginSuccessful = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.isUberEatsLoginSuccessful,
  );

export const makeSelectUberEatsLoginRequired = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.uberEatsLoginRequired,
  );

export const makeSelectAvailableUberEatsStores = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.availableUberEatsStores,
  );

export const makeSelectSelectedUberEatsStore = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.selectedUberEatsStore,
  );

export const makeSelectGetUberEatsStoreLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.getUberEatsStoreLoading,
  );

export const makeSelectSquareLoginUrl = () =>
  createSelector(selectIntegrationDomain, substate => substate.squareLoginUrl);

export const makeSelectSquareLoginUrlLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.getSquareLoginUrlLoading,
  );

export const makeSelectIsSquareLoginSuccessful = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.isSquareLoginSuccessful,
  );

export const makeSelectGetSquareStoreLoading = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.getSquareStoreLoading,
  );

export const makeSelectAvailableSquareStores = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.availableSquareStores,
  );
export const makeSelectSelectedSquareStore = () =>
  createSelector(
    selectIntegrationDomain,
    substate => substate.selectedSquareStore,
  );

export const makeSelectProperty = key =>
  createSelector(selectIntegrationDomain, substate => substate[key]);
