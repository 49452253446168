const defaultTtl = 60 * 60 * 1000;
// If we're testing, use a local storage polyfill

// If not, use the browser one
// eslint-disable-next-line prefer-destructuring,no-global-assign
// localStorage = global.window.localStorage;
Object.defineProperty(window, 'localStorage', {
  value: global.localStorage,
  configurable: true,
  enumerable: true,
  writable: true,
});

/**
 * Set item to localstorage
 * @param key
 * @param value
 * @param ttl -millisecond
 */
const setItem = (key, value, ttl = defaultTtl) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getItem = key => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
const removeItem = key => localStorage.removeItem(key);
const lc = { setItem, getItem, removeItem };
export default lc;
