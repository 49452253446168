import { KLIKIT_API_BASE_URL, KLIKIT_CDN_BASE_URL } from './env';

export const CDN_URL = KLIKIT_CDN_BASE_URL;
export const CDN_BASE_URL = KLIKIT_CDN_BASE_URL;
export const API_BASE_URL = KLIKIT_API_BASE_URL;
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZES = [10, 20, 50];
export const DEFAULT_ALL_PAGE_SIZE = 5000;
export const PERMISSION_DEFAULT_PAGE_SIZES = [20, 50, 100];
export const PERMISSION_DEFAULT_PAGE_SIZE = 20;
export const MIN_IMAGE_SIZE = 100 * 1024;
export const MAX_IMAGE_SIZE = 1024 * 1024;
export const MAX_IMG_SIZE = 1;
export const ACCEPTED_IMAGE_EXTENSIONS = '.jpg, .jpeg, .png, .bmp, .webp';
export const MAX_BANNER_COUNT = 10;
export const MAX_BUSINESS_BANNER_COUNT = 6;
export const MAX_IMAGE_COUNT_FOR_IMAGE_MANAGER = 20;

export const IMAGE_EXTENSION_MIME_TYPES = {
  WEBP: 'image/webp',
  JPEG: 'image/jpeg',
};

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const ROLE = {
  ADMIN: 'admin',
  BUSINESS_OWNER: 'business_owner',
  BRAND_MANAGER: 'brand_manager',
  BRANCH_MANAGER: 'branch_manager',
};
export const ADMIN_ROLE_ID = 1;
export const BPO_ROLE_ID = 8;

export const ORDER_STATUS = {
  PLACED: 1,
  ACCEPTED: 2,
  CANCELLED: 3,
  READY: 4,
  DELIVERED: 5,
  SCHEDULED: 6,
  DRIVER_ASSIGNED: 7,
  DRIVER_ARRIVED: 8,
  PICKEDUP: 9,
  VOIDED: 11,
};

export const ORDER_TYPE = {
  DELIVER: 2,
  PICKUP: 1,
  DINE_IN: 3,
};

export const PROVIDERS = [
  {
    id: 1,
    title: 'Klikit',
  },
  {
    id: 2,
    title: 'Uber Eats',
  },
];

export const PAID_PAYMENT_STATUS_ID = 1;
export const PENDING_PAYMENT_STATUS_ID = 3;
export const FAILED_PAYMENT_STATUS_ID = 2;
export const DEFAULT_PAYMENT_STATUS_ID_FOR_MANUAL_ORDER =
  PENDING_PAYMENT_STATUS_ID;
export const DEFAULT_PAYMENT_STATUS_LABEL_FOR_MANUAL_ORDER = 'Pending';

export const SOCIAL_SIGN_IN_PROVIDERS = {
  GOOGLE: {
    id: 1,
    code: 'g',
    title: 'Google',
  },
};

export const USERS_TAB_TYPES = {
  USERS: {
    title: 'Users',
    identifier: 'USERS',
    url_identifier: 'user',
    url: '/user',
  },
  ADMINS: {
    title: 'Admins',
    identifier: 'ADMINS',
    url_identifier: 'admins',
    url: '/user/admins',
  },
};
export const ADMINS_TAB = USERS_TAB_TYPES.ADMINS.identifier;
export const USERS_TAB = USERS_TAB_TYPES.USERS.identifier;

export const PAYMENT_METHOD = {
  CARD: 1,
  CASH: 2,
  PAYPAL: 3,
  GCASH: 4,
  OTHER: 5,
  EWALLET: 14,
  CUSTOM: 16,
};

export const PAYMENT_STATUS_PAID_ID = 1;

export const CUSTOM_PAYMENT_PLATFORM_CHANNEL_CODES = {
  BANK: 'custom_bank',
  CARD_ON_DELIVERY: 'custom_card',
};

export const CUSTOM_PAYMENT_TYPES = {
  BANK: 2,
  CARD_ON_DELIVERY: 3,
};
