import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { SCLoader } from 'rollup-allspark';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readablePrice } from '../../../utils/getCurrency';
import {
  exportedCsvHeaderTitleForXAxis,
  generateStackedBarChartData,
  generateComparisonData,
  updateBasketSizes,
} from '../../../utils/nativeAnalyticsUtils';
import {
  MONTHLY_TIME_PERIOD,
  WEEKLY_TIME_PERIOD,
  DEFAULT_CURRENCY_OBJ,
  COMPARISON_TYPE,
} from '../../../config/constants/analytics';
import {
  formatDate,
  getStartOfWeek,
  formatDateWithoutTimezone,
} from '../../../utils/format-date';
import {
  makeSelectAnalyticsDataLoading,
  makeSelectAnalyticsData,
  makeSelectTimePeriodSlug,
  makeSelectSelectedProvidersForProviderComparison,
  makeSelectSelectedComparisonType,
  makeSelectAnalyticsSelectedBranch,
  makeSelectAnalyticsSelectedBrand,
} from '../selectors';

const stackedLineChart = ({
  targetKey,
  chartData,
  chartDataLoading,
  selectedTimePeriod,
  selectedProviderListForProviderComparison,
  selectedComparisonType,
  selectedBranches,
  selectedBrands,
}) => {
  const currencyCodeList =
    chartData && chartData.length ? chartData.map(b => b.currency) : [];
  const branchCurrencyObj =
    chartData && chartData.length
      ? {
          code: chartData[0].currency,
          symbol: chartData[0].currency_symbol,
        }
      : '';

  const shouldShowUsd = [...new Set(currencyCodeList)].length > 1;
  const currencyObj = shouldShowUsd ? DEFAULT_CURRENCY_OBJ : branchCurrencyObj;
  const exportedCsvHeaderTitle =
    exportedCsvHeaderTitleForXAxis(selectedTimePeriod);

  // GENERATE COMPARISON DATA
  const providerComparisonData =
    generateComparisonData(
      chartData,
      selectedComparisonType,
      selectedTimePeriod,
    ) || [];

  // GENERATE X AXIS DATA
  const prepareXaxisData = data => {
    if (selectedTimePeriod === WEEKLY_TIME_PERIOD) {
      const reportDate = new Date(data.date);
      const wStartDate = getStartOfWeek(reportDate);
      return formatDate(wStartDate.toISOString(), `MMM dd ''yy`);
    }
    if (selectedTimePeriod === MONTHLY_TIME_PERIOD) {
      return formatDate(data.date, `MMM ''yy`);
    }
    return formatDateWithoutTimezone(data.date, `MMM dd`);
  };
  const xAxisData = providerComparisonData.map(prepareXaxisData) || [];

  // GENERATE Y AXIS DATA
  const selectedComparisonTypeData = (() => {
    if (selectedComparisonType === COMPARISON_TYPE.BRANCH) {
      return selectedBranches;
    }
    if (selectedComparisonType === COMPARISON_TYPE.BRAND) {
      return selectedBrands;
    }
    return selectedProviderListForProviderComparison;
  })();
  const updatedProviderComparisonData = updateBasketSizes(
    providerComparisonData,
    selectedComparisonType,
  );
  const yAxisData =
    generateStackedBarChartData(
      selectedComparisonTypeData,
      updatedProviderComparisonData,
      targetKey,
      selectedComparisonType,
      selectedTimePeriod,
    ) || [];

  const totalDataPoints = yAxisData.reduce(
    (total, series) => total + series.data.length,
    0,
  );
  const dashArrayValue = Math.ceil(totalDataPoints / 10);

  const chartConfig = {
    series: yAxisData,
    options: {
      tooltip: {
        enabled: true,
        y: {
          formatter(value) {
            return readablePrice(value * 100, currencyObj);
          },
        },
      },
      chart: {
        targetKey,
        type: 'line',
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: `provider_wise_${targetKey}`,
              headerCategory: exportedCsvHeaderTitle,
              headerValue: 'value',
            },
            svg: {
              filename: `provider_wise_${targetKey}`,
            },
            png: {
              filename: `provider_wise_${targetKey}`,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.1,
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: xAxisData,
      },
      markers: {
        size: 6,
      },
      stroke: {
        dashArray: Array(dashArrayValue).fill(6).join(','),
        curve: 'smooth',
        width: 2,
      },
    },
  };

  if (chartDataLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <SCLoader lineWidth={5} diameter={50} />
      </div>
    );
  }
  if (chartData && chartData.length) {
    return (
      <Chart
        options={chartConfig.options}
        series={chartConfig.series}
        type="line"
        height="350"
      />
    );
  }

  return (
    <div className="flex justify-center items-center h-full text-2xl text-grey-darker">
      No Data Found!
    </div>
  );
};

stackedLineChart.propTypes = {
  targetKey: PropTypes.string,
  seriesName: PropTypes.string,
  chartData: PropTypes.array,
  chartDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  selectedProviderListForProviderComparison: PropTypes.array,
  selectedComparisonType: PropTypes.string,
  selectedBranches: PropTypes.array,
  selectedBrands: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  chartDataLoading: makeSelectAnalyticsDataLoading(),
  chartData: makeSelectAnalyticsData(),
  selectedTimePeriod: makeSelectTimePeriodSlug(),
  selectedProviderListForProviderComparison:
    makeSelectSelectedProvidersForProviderComparison(),
  selectedComparisonType: makeSelectSelectedComparisonType(),
  selectedBranches: makeSelectAnalyticsSelectedBranch(),
  selectedBrands: makeSelectAnalyticsSelectedBrand(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(stackedLineChart);
