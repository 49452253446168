import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { COMPARISON_TYPE } from '../../../config/constants/analytics';
import { isEmpty } from '../../../utils';
import { makeSelectProperty } from '../selectors';

const BusyModeReasonPieChart = ({
  busyModeReasonData,
  selectedComparisonType,
}) => {
  const [pieChartData, setPieChartData] = useState({});

  const breakdownTooltip = (reason, total, entities) => {
    const tooltipElm = (
      <div className="flex flex-col gap-y-2">
        {entities.map(entity => (
          <div
            key={entity.id}
            className="flex items-center justify-between gap-x-4"
          >
            <p>{entity.title}</p>
            <p>{entity.count}</p>
          </div>
        ))}
      </div>
    );

    return ReactDOMServer.renderToString(tooltipElm);
  };

  useEffect(() => {
    if (isEmpty(busyModeReasonData)) {
      setPieChartData({});
      return;
    }

    const series = [];
    const labels = [];
    const tooltips = [];

    if (selectedComparisonType === COMPARISON_TYPE.BRAND) {
      Object.keys(busyModeReasonData).forEach(reason => {
        const { brands: reasonBrands } = busyModeReasonData[reason];
        const totalBrands = reasonBrands.reduce(
          (acc, brand) => acc + brand.count,
          0,
        );
        labels.push(`${reason} (${totalBrands})`);
        series.push(totalBrands);
        tooltips.push(breakdownTooltip(reason, totalBrands, reasonBrands));
      });
    } else {
      Object.keys(busyModeReasonData).forEach(reason => {
        const { branches: reasonBranches } = busyModeReasonData[reason];
        const totalBranches = reasonBranches.reduce(
          (acc, branch) => acc + branch.count,
          0,
        );
        labels.push(`${reason} (${totalBranches})`);
        series.push(totalBranches);
        tooltips.push(breakdownTooltip(reason, totalBranches, reasonBranches));
      });
    }

    setPieChartData({ series, labels, tooltips });
  }, [busyModeReasonData, selectedComparisonType]);

  // Helper function to format total value
  const formatTotalValue = w => {
    const total = w.globals.seriesTotals.reduce((a, b) => +a + +b, 0);
    return total;
  };

  const formatPieChartTooltip = (_, { seriesIndex }) =>
    pieChartData.tooltips[seriesIndex];

  const chartConfig = {
    options: {
      tooltip: {
        y: {
          formatter: formatPieChartTooltip,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '60%',
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                showAlways: true,
                formatter: formatTotalValue,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      // colors: chartColors,
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.1,
          },
        },
      },
      stroke: {
        show: false,
      },
      legend: {
        show: false,
      },
      labels: pieChartData.labels || [],
      chart: {
        type: 'donut',
        width: '400',
        animation: {
          enabled: false,
          animateGradually: {
            enabled: false,
            delay: 0,
          },
          dynamicAnimation: {
            enabled: false,
            speed: 0,
          },
        },
        nodata: {
          text: 'No data',
          style: {
            color: '#000000',
            fontSize: '16px',
          },
        },
      },
    },
    series: pieChartData.series || [],
  };

  const renderNoData = (
    <div className="flex justify-center items-center text-grey-darker absolute m-auto left-0 right-0 top-0 mt-5 text-2xl">
      No Data Found!
    </div>
  );

  return (
    <div className="flex flex-col items-center mt-4 lg:mb-4 md:mb-0">
      <div className="relative">
        <Chart
          options={chartConfig.options}
          series={chartConfig.series}
          type="donut"
          width="400"
        />
        {!isEmpty(chartConfig.series) ? null : renderNoData}
      </div>
    </div>
  );
};

BusyModeReasonPieChart.propTypes = {
  busyModeReasonData: PropTypes.object,
  selectedComparisonType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  busyModeReasonData: makeSelectProperty('busyModeReasonData'),
  selectedComparisonType: makeSelectProperty('selectedComparisonType'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BusyModeReasonPieChart);
