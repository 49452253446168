export const LOAD_LOCATION = 'app/Integration/LOAD_LOCATION';
export const LOAD_LOCATION_SUCCESS = 'app/Integration/LOAD_LOCATION_SUCCESS';
export const LOAD_LOCATION_FAILED = 'app/Integration/LOAD_LOCATION_FAILED';
export const LOAD_LOCATION_BY_ID = 'app/Integration/LOAD_LOCATION_BY_ID';
export const LOAD_LOCATION_BY_ID_SUCCESS =
  'app/Integration/LOAD_LOCATION_BY_ID_SUCCESS';
export const LOAD_LOCATION_BY_ID_FAILED =
  'app/Integration/LOAD_LOCATION_BY_ID_FAILED';
export const LOCATION_SELECTED = 'app/Integration/LOCATION_SELECTED';
export const LOAD_BRANDS = 'app/Integration/LOAD_BRANDS';
export const LOAD_BRANDS_SUCCESS = 'app/Integration/LOAD_BRANDS_SUCCESS';
export const LOAD_BRANDS_FAILED = 'app/Integration/LOAD_BRANDS_FAILED';
export const UPDATE_BRAND_STATUS = 'app/Integration/UPDATE_BRAND_STATUS';
export const UPDATE_BRAND_STATUS_SUCCESS =
  'app/Integration/UPDATE_BRAND_STATUS_SUCCESS';
export const UPDATE_BRAND_STATUS_FAILED =
  'app/Integration/UPDATE_BRAND_STATUS_FAILED';
export const SELECTED_BRAND = 'app/Integration/SELECTED_BRAND';
export const DESELECT_LOCATION = 'app/Integration/DESELECT_LOCATION';

export const UPDATE_PAGINATION = 'app/Integration/UPDATE_PAGINATION';
export const LOAD_BRAND_BY_ID = 'app/Integration/LOAD_BRAND_BY_ID';
export const LOAD_BRAND_BY_ID_SUCCESS =
  'app/Integration/LOAD_BRAND_BY_ID_SUCCESS';
export const LOAD_BRAND_BY_ID_FAILED =
  'app/Integration/LOAD_BRAND_BY_ID_FAILED';

export const LOAD_BUSINESS = 'app/Integration/LOAD_BUSINESS';
export const LOAD_BUSINESS_SUCCESS = 'app/Integration/LOAD_BUSINESS_SUCCESS';
export const LOAD_BUSINESS_FAILED = 'app/Integration/LOAD_BUSINESS_FAILED';
export const LOAD_BRANDS_FILTER = 'app/Integration/LOAD_BRANDS_FILTER';
export const LOAD_BRANDS_FILTER_SUCCESS =
  'app/Integration/LOAD_BRANDS_FILTER_SUCCESS';
export const LOAD_BRANDS_FILTER_FAILED =
  'app/Integration/LOAD_BRANDS_FILTER_FAILED';

export const CHANGE_BIZ = 'app/Integration/CHANGE_BIZ';
export const CHANGE_BRAND = 'app/Integration/CHANGE_BRAND';
export const CHANGE_BRANCH = 'app/Integration/CHANGE_BRANCH';
export const CHANGE_PROVIDER = 'app/Integration/CHANGE_PROVIDER';

export const DESELECT_PROVIDER = 'app/Integration/DESELECT_PROVIDER';

export const LOAD_PROVIDERS = 'app/Integration/LOAD_PROVIDERS';
export const LOAD_PROVIDER_SUCCESS = 'app/Integration/LOAD_PROVIDERS_SUCCESS';
export const LOAD_PROVIDER_FAILED = 'app/Integration/LOAD_PROVIDERS_FAILED';

export const LOAD_TIED_PROVIDERS = 'app/Integration/LOAD_TIED_PROVIDERS';
export const LOAD_TIED_PROVIDER_SUCCESS =
  'app/Integration/LOAD_TIED_PROVIDER_SUCCESS';
export const LOAD_TIED_PROVIDERS_FAILED =
  'app/Integration/LOAD_TIED_PROVIDERS_FAILED';

export const GET_PROVIDER_CRED = 'app/Integration/GET_PROVIDER_CRED';
export const GET_PROVIDER_CRED_SUCCESS =
  'app/Integration/GET_PROVIDER_CRED_SUCCESS';
export const GET_PROVIDER_CRED_FAILED =
  'app/Integration/GET_PROVIDER_CRED_FAILED';

export const UPDATE_PROVIDER_CRED = 'app/Integration/UPDATE_PROVIDER_CRED';
export const UPDATE_PROVIDER_CRED_SUCCESS =
  'app/Integration/UPDATE_PROVIDER_CRED_SUCCESS';
export const UPDATE_PROVIDER_CRED_FAILED =
  'app/Integration/UPDATE_PROVIDER_CRED_FAILED';

export const CHANGE_AUTO_ACCEPT = 'app/Integration/CHANGE_AUTO_ACCEPT';
export const CHANGE_INTEGRATION_TYPE =
  'app/Integration/CHANGE_INTEGRATION_TYPE';
export const CHANGE_AUTO_PILOT = 'app/Integration/CHANGE_AUTO_PILOT';
export const CHANGE_MENU_SYNC = 'app/Integration/CHANGE_MENU_SYNC';
export const CHANGE_IS_SINGLE_DEVICE =
  'app/Integration/CHANGE_IS_SINGLE_DEVICE';

export const DELETE_PROVIDER = 'app/Integration/DELETE_PROVIDER';
export const DELETE_PROVIDER_SUCCESS =
  'app/Integration/DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAILED = 'app/Integration/DELETE_PROVIDER_FAILED';

export const SEND_OTP_REQUEST_FOR_GOFOOD =
  'app/Integration/SEND_OTP_REQUEST_FOR_GOFOOD';
export const SEND_OTP_REQUEST_FOR_GOFOOD_SUCCESS =
  'app/Integration/SEND_OTP_REQUEST_FOR_GOFOOD_SUCCESS';
export const SEND_OTP_REQUEST_FOR_GOFOOD_FAILED =
  'app/Integration/SEND_OTP_REQUEST_FOR_GOFOOD_FAILED';

export const VERIFY_OTP_TOKEN_FOR_GOFOOD =
  'app/Integration/VERIFY_OTP_TOKEN_FOR_GOFOOD';
export const VERIFY_OTP_TOKEN_FOR_GOFOOD_SUCCESS =
  'app/Integration/VERIFY_OTP_TOKEN_FOR_GOFOOD_SUCCESS';
export const VERIFY_OTP_TOKEN_FOR_GOFOOD_FAILED =
  'app/Integration/VERIFY_OTP_TOKEN_FOR_GOFOOD_FAILED';

export const LOGIN_SHOPEE_CREDENTIALS =
  'app/Integration/LOGIN_SHOPEE_CREDENTIALS';
export const LOGIN_SHOPEE_CREDENTIALS_SUCCESS =
  'app/Integration/LOGIN_SHOPEE_CREDENTIALS_SUCCESS';
export const LOGIN_SHOPEE_CREDENTIALS_FAILED =
  'app/Integration/LOGIN_SHOPEE_CREDENTIALS_FAILED';

export const VERIFY_OTP_TOKEN_SHOPEE =
  'app/Integration/VERIFY_OTP_TOKEN_SHOPEE';
export const VERIFY_OTP_TOKEN_SHOPEE_SUCCESS =
  'app/Integration/VERIFY_OTP_TOKEN_SHOPEE_SUCCESS';
export const VERIFY_OTP_TOKEN_SHOPEE_FAILED =
  'app/Integration/VERIFY_OTP_TOKEN_SHOPEE_FAILED';

export const VERIFY_OTP_TOKEN_FOR_DEMAECAN =
  'app/Integration/VERIFY_OTP_TOKEN_FOR_DEMAECAN';
export const VERIFY_OTP_TOKEN_FOR_DEMAECAN_SUCCESS =
  'app/Integration/VERIFY_OTP_TOKEN_FOR_DEMAECAN_SUCCESS';
export const VERIFY_OTP_TOKEN_FOR_DEMAECAN_FAILED =
  'app/Integration/VERIFY_OTP_TOKEN_FOR_DEMAECAN_FAILED';

export const GET_UBEREATS_LOGIN_URL = 'app/Integration/GET_UBEREATS_LOGIN_URL';
export const GET_UBEREATS_LOGIN_URL_SUCCESS =
  'app/Integration/GET_UBEREATS_LOGIN_URL_SUCCESS';
export const GET_UBEREATS_LOGIN_URL_FAILED =
  'app/Integration/GET_UBEREATS_LOGIN_URL_FAILED';

export const GET_AVAILABLE_UBEREATS_STORES =
  'app/Integration/GET_AVAILABLE_UBEREATS_STORES';
export const GET_AVAILABLE_UBEREATS_STORES_SUCCESS =
  'app/Integration/GET_AVAILABLE_UBEREATS_STORES_SUCCESS';
export const GET_AVAILABLE_UBEREATS_STORES_FAILED =
  'app/Integration/GET_AVAILABLE_UBEREATS_STORES_FAILED';

export const CHANGE_UBEREATS_STORE = 'app/Integration/CHANGE_UBEREATS_STORE';

export const GET_SQUARE_LOGIN_URL = 'app/Integration/GET_SQUARE_LOGIN_URL';
export const GET_SQUARE_LOGIN_URL_SUCCESS =
  'app/Integration/GET_SQUARE_LOGIN_URL_SUCCESS';
export const GET_SQUARE_LOGIN_URL_FAILED =
  'app/Integration/GET_SQUARE_LOGIN_URL_FAILED';

export const GET_AVAILABLE_SQUARE_STORES =
  'app/Integration/GET_AVAILABLE_SQUARE_STORES';
export const GET_AVAILABLE_SQUARE_STORES_SUCCESS =
  'app/Integration/GET_AVAILABLE_SQUARE_STORES_SUCCESS';
export const GET_AVAILABLE_SQUARE_STORES_FAILED =
  'app/Integration/GET_AVAILABLE_SQUARE_STORES_FAILED';

export const CHANGE_SQUARE_STORE = 'app/Integration/CHANGE_SQUARE_STORE';

export const UPLOAD_CSV = 'app/Integration/UPLOAD_CSV';
export const UPLOAD_CSV_FAILED = 'app/Integration/UPLOAD_CSV_FAILED';
export const UPLOAD_CSV_SUCCESS = 'app/Integration/UPLOAD_CSV_SUCCESS';

export const GET_SETTINGS = 'app/Integration/GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'app/Integration/GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILED = 'app/Integration/GET_SETTINGS_FAILED';

export const CHANGE_SETTINGS = 'app/Integration/CHANGE_SETTINGS';

// New constants for New Aggregator Module

export const LOAD_LIST = 'app/Integration/LOAD_LIST';
export const LOAD_LIST_SUCCESS = 'app/Integration/LOAD_LIST_SUCCESS';
export const LOAD_LIST_FAILED = 'app/Integration/LOAD_LIST_FAILED';

export const EDIT_FIELD = 'app/Integration/EDIT_FIELD';

export const RESET_FIELDS = 'app/Integration/RESET_FIELDS';

export const CREATE_SETTINGS = 'app/Integration/CREATE_SETTINGS';

export const UPDATE_SETTINGS = 'app/Integration/UPDATE_SETTINGS';

export const DELETE_SETTINGS = 'app/Integration/DELETE_SETTINGS';

export const SETTINGS_RESOLVED = 'app/Integration/SETTINGS_RESOLVED';

export const SETTINGS_REJECTED = 'app/Integration/SETTINGS_REJECTED';
