import {
  LOAD_LIST,
  LOAD_PRICING_GROUPS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGES,
} from './constants';

export function getGlobalList(params) {
  return {
    type: LOAD_LIST,
    params,
  };
}

export function uploadImage(params) {
  return {
    type: UPLOAD_IMAGE,
    params,
  };
}

export function uploadImages(params) {
  return {
    type: UPLOAD_IMAGES,
    params,
  };
}

export function getPricingGroups(params) {
  return {
    type: LOAD_PRICING_GROUPS,
    params,
  };
}
