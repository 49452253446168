import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SCButton, SCLoader, SCPagination } from 'rollup-allspark';
import NotificationImg from '../../../assets/images/Notification.png';
import HelpPopover from '../../../components/HelpPopover';
import { DEFAULT_PAGE_SIZES } from '../../../config/constants/pagination';
import { ORDER_BREAKDOWN_HELP } from '../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { makeSelectCurrentUser } from '../../App/selectors';
import { loadAnalyticsCsv, loadOrdersData, updatePagination } from '../actions';
import * as selectors from '../selectors';
import OrderTableHeader from './OrderTableHeader';
import OrderTableRow from './OrderTableRow';

const OrderTable = ({
  ordersData,
  ordersDataLoading,
  paginationObj,
  handleUpdatePagination,
  filterData,
  getOrdersData,
  handleAnalyticsCsvDownload,
  isMoreThan30Days,
  user,
}) => {
  const { orders, pagination } = ordersData || {};
  let renderOrdersDataRow = null;
  const { pageNo, pageSize } = paginationObj || {};
  const [isOrderBreakdownPopoverOpen, setIsOrderBreakdownPopoverOpen] =
    useState(false);
  const [showMoreThan30DaysPlaceholder, setShowMoreThan30DaysPlaceholder] =
    useState(false);

  const getFilteredOrderData = () => {
    const params = { ...filterData };
    params.page_no = pageNo;
    params.page_size = pageSize;
    if (params.brand_id || params.branch_id) {
      getOrdersData(params);
    }
  };

  useEffect(getFilteredOrderData, [paginationObj]);

  useEffect(() => {
    setShowMoreThan30DaysPlaceholder(isMoreThan30Days);
  }, [isMoreThan30Days]);

  const onAnalyticsDownloadButtonClick = () => {
    const params = { ...filterData };
    handleAnalyticsCsvDownload(params);
  };

  if (ordersDataLoading) {
    renderOrdersDataRow = (
      <tr>
        <td
          rowSpan="10"
          colSpan="12"
          className="p-5"
          style={{ height: '380px' }}
        >
          <SCLoader lineWidth={5} diameter={50} />
        </td>
      </tr>
    );
  } else {
    renderOrdersDataRow =
      orders && orders.length ? (
        orders.map(order => <OrderTableRow key={order.id} orderData={order} />)
      ) : (
        <tr className="text-center">
          <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
            No data Found
          </td>
        </tr>
      );
  }

  const getCsvInEmailEl = (
    <tr className="text-center">
      <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
        <div className="flex justify-center items-center flex-col w-full gap-y-1">
          <img
            src={NotificationImg}
            alt="Notification"
            width={150}
            height={150}
          />
          <h3 className="text-xl text-black">Date range Exceeds 30 Days</h3>
          <p className="text-grey-darker text-sm">
            Let us send the CSV in your mail.
          </p>
          <button
            type="button"
            className="rounded-lg flex items-center justify-center p-4 bg-primary text-white text-sm mt-4"
            onClick={onAnalyticsDownloadButtonClick}
          >
            <i className="mr-2 sc-mail"></i> Get the CSV in {user.email}
          </button>
        </div>
      </td>
    </tr>
  );

  const renderPagination =
    !ordersDataLoading && orders && orders.length && !isMoreThan30Days ? (
      <SCPagination
        total={pagination && pagination.total ? pagination.total : 0}
        page={pageNo}
        size={pageSize}
        sizes={DEFAULT_PAGE_SIZES}
        onPageChange={handleUpdatePagination}
      />
    ) : null;

  const renderDownloadCSVButton =
    orders && orders.length ? (
      <SCButton
        label="Download"
        variant="primary-outline"
        size="sm"
        className="h-6 w-auto px-5 text-xs"
        action={onAnalyticsDownloadButtonClick}
      />
    ) : null;

  return (
    <div className="bg-white w-full p-4 rounded">
      <div className="w-full flex justify-between items-center mb-5 mt-2">
        <h5 className="text-base font-medium capitalize flex">
          Order Breakdown
          <HelpPopover
            isPopoverOpen={isOrderBreakdownPopoverOpen}
            position="right"
            isHtmlElement
            content={ORDER_BREAKDOWN_HELP}
            setIsPopoverOpen={setIsOrderBreakdownPopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2"
          />
        </h5>
        <div className="flex items-end">
          <small className="text-center capitalize mr-2 border rounded-full  border-grey-dark py-1 px-2">
            Download to see more data
          </small>
          {renderDownloadCSVButton}
        </div>
      </div>
      <div className="overflow-x-auto mb-8">
        <table className="table-auto w-full">
          <thead>
            <OrderTableHeader />
          </thead>
          <tbody>
            {showMoreThan30DaysPlaceholder
              ? getCsvInEmailEl
              : renderOrdersDataRow}
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-center">{renderPagination}</div>
    </div>
  );
};

OrderTable.propTypes = {
  ordersData: PropTypes.object,
  ordersDataLoading: PropTypes.bool,
  paginationObj: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  filterData: PropTypes.object,
  getOrdersData: PropTypes.func,
  handleAnalyticsCsvDownload: PropTypes.func,
  isMoreThan30Days: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  ordersData: selectors.makeSelectOrdersData(),
  ordersDataLoading: selectors.makeSelectOrdersDataLoading(),
  paginationObj: selectors.makeSelectPaginationObj(),
  analyticsStartDate: selectors.makeSelectAnalyticsStartDate(),
  analyticsEndDate: selectors.makeSelectAnalyticsEndDate(),
  isMoreThan30Days: selectors.makeSelectIsMoreThan30Days(),
  user: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    handleUpdatePagination: (page, size) =>
      dispatch(updatePagination(page, size)),
    getOrdersData: params => dispatch(loadOrdersData(params)),
    handleAnalyticsCsvDownload: params => dispatch(loadAnalyticsCsv(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrderTable);
