import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { printf } from '.';
import { MAX_IMAGE_COUNT_FOR_IMAGE_MANAGER } from '../config';
import { CONNECTION_INTERREPED_MSG } from '../config/constants/errorMessages';

export const PHONE_VALIDATION_REGEX =
  /^(?:\+(?:[0-9]{15}|[0-9]{14}|[0-9]{13}|[0-9]{12}|[0-9]{11}|[0-9]{10}|[0-9]{9}|[0-9]{8}|[0-9]{7}|[0-9]{6})|(?:[0-9]{16}|[0-9]{15}|[0-9]{14}|[0-9]{13}|[0-9]{12}|[0-9]{11}|[0-9]{10}|[0-9]{9}|[0-9]{8}|[0-9]{7}))$/;

// Following Phone Regex is used at backend for further validations.
// Keeping it here for reference, in case we need to check.
// But we will not use it, as it accepts special characters like )-\./
// export const PHONE_VALIDATION_REGEX_FROM_BACKEND =
//   '^(?:(?:\\(?(?:00|\\+)([1-4]\\d\\d|[1-9]\\d?)\\)?)?[\\-\\.\\ \\\\\\/]?)?((?:\\(?\\d{1,}\\)?[\\-\\.\\ \\\\\\/]?){0,})(?:[\\-\\.\\ \\\\\\/]?(?:#|ext\\.?|extension|x)[\\-\\.\\ \\\\\\/]?(\\d+))?$';
export const PHONE_CHAR_LIMIT_SCHEMA = { MIN: 7, MAX: 16 };
const phoneMatchingArgumentsForYup = [
  PHONE_VALIDATION_REGEX,
  'Please enter a valid phone number',
];
const phoneMinArgumentsForYup = [
  PHONE_CHAR_LIMIT_SCHEMA.MIN,
  `Must be between ${PHONE_CHAR_LIMIT_SCHEMA.MIN} and ${PHONE_CHAR_LIMIT_SCHEMA.MAX} digits`,
];

const phoneMaxArgumentsForYup = [
  PHONE_CHAR_LIMIT_SCHEMA.MAX,
  `Must be between ${PHONE_CHAR_LIMIT_SCHEMA.MIN} and ${PHONE_CHAR_LIMIT_SCHEMA.MAX} digits`,
];

export const PHONE_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .matches(...phoneMatchingArgumentsForYup)
  .min(...phoneMinArgumentsForYup)
  .max(...phoneMaxArgumentsForYup)
  .when('isPhoneNumberRequired', {
    is: true,
    then: Yup.string().required('Phone Number is required'),
    otherwise: Yup.string(),
  });

const USER_FIRST_AND_LAST_NAME_REGEX = /^(\p{L}+[\p{L}\p{M}\s'.-]*)$/u;

export const USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA = { MIN: 1, MAX: 50 };
export const USER_FIRST_AND_LAST_NAME_VALIDATION_SCHEMA_FOR_YUP = nameLabel =>
  Yup.string()
    .matches(
      USER_FIRST_AND_LAST_NAME_REGEX,
      "Only Alphabets and -'. signs are allowed",
    )
    .min(
      USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA.MIN,
      `Must be between ${USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA.MIN} and ${USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA.MAX} characters`,
    )
    .max(
      USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA.MAX,
      `Must be between ${USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA.MIN} and ${USER_FIRST_AND_LAST_NAME_CHAR_LIMIT_SCHEMA.MAX} characters`,
    )
    .required(`${nameLabel || 'This field'} is required`);

export const EMAIL_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .email('Email is not valid')
  .required('Email is required');

export const EMAIL_INITIAL_STATE = {
  isValid: true,
  error: '',
};
export const EMAIL_REQUIRED_ERROR = {
  isValid: false,
  error: 'Email is required',
};
export const EMAIL_VALID_ERROR = {
  isValid: false,
  error: 'Invalid email address',
};

export const LATITUDE_VALIDATION_SCHEMA_FOR_YUP = Yup.number()
  .min(-90, 'Must be a number between -90 and 90')
  .max(90, 'Must be a number between -90 and 90')
  .typeError(`Latitude must be a number`)
  .required('Latitude is required');
export const LONGITUDE_VALIDATION_SCHEMA_FOR_YUP = Yup.number()
  .min(-180, 'Must be a number between -180 and 180')
  .max(180, 'Must be a number between -180 and 180')
  .typeError(`Longitude must be a number`)
  .required('Longitude is required');

export const BRANCH_TITLE_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .trim()
  .min(1, 'Must be between 1 and 50 characters')
  .max(50, 'Must be between 1 and 50 characters')
  .required('Branch name is required');

export const BRANCH_ADDRESS_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .min(5, 'Must be between 5 and 255 characters')
  .max(255, 'Must be between 5 and 255 characters')
  .required('Address is required');

export const BRAND_TITLE_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .trim()
  .min(1, 'Must be between 1 and 120 characters')
  .max(120, 'Must be between 1 and 120 characters')
  .required('Brand name is required');

export const PASSWORD_VALIDATION_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#~$%^&*()+|_<>])(?=.{8,})/;
export const PASSWORD_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .required('Please Enter your password')
  .matches(
    PASSWORD_VALIDATION_REGEX,
    'Must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character',
  );

export const LOCATION_SERVICE_CHARGE_FOR_YUP = Yup.number(
  'Service charge should be number',
)
  .typeError('Service charge should be number')
  .required('Service charge is required')
  .positive('Service charge should be a positive number')
  .min(0, 'Service charge should be more than 0')
  .max(100, 'Service charge cannot be more than 100');

export const SECTION_TITLE_REQUIRED_ERR = {
  isValid: false,
  error: 'Title is required',
};

export const SECTION_TITLE_MIN_CHAR_REQUIRED_ERR = {
  isValid: false,
  error: 'Title should contain 5 characters or more',
};

export const SECTION_TITLE_MAX_CHAR_REQUIRED_ERR = {
  isValid: false,
  error: 'Title must be less than 100 characters',
};

export const INITIAL_VALIDATION_STATE = { isValid: true, error: '' };

export const KLIKIT_FEE_PERCENTAGE_VALIDATION_SCHEMA_FOR_YUP = Yup.number(
  'Klikit fee percentage must be a number',
)
  .min(-100, 'Please enter a value between -100 and 100')
  .max(100, 'Please enter a value between -100 and 100')
  .typeError('Klikit fee percentage must be a number')
  .required('Klikit fee percentage is required');

export const PROMO_CODE_VALIDATION_SCHEMA_FOR_YUP = Yup.string()
  .min(3, 'The length of Promo code must be between 3 and 16')
  .max(16, 'The length of Promo code must be between 3 and 16')
  .required('Code is required');

export const MAX_BANNER_COUNT_EXCEEDED_MSG =
  'You can assign a maximum of %s banners to a %s';

export const MAX_IMAGE_UPLOAD_COUNT_EXCEEDED_MSG = `You can upload a maximum of ${MAX_IMAGE_COUNT_FOR_IMAGE_MANAGER} images at a time.`;

export const WHITELABEL_MASK_URL_REQUIRED_ERR = {
  isValid: false,
  error: "Domain can't be empty",
};

export const WHITELABEL_MASK_URL_MAX_LENGTH_ERR = {
  isValid: false,
  error: 'Domain must be less than 200 characters',
};

export const WHITELABEL_MASK_URL_NO_SPACE_ERR = {
  isValid: false,
  error: 'Domain must be without spaces',
};

export const BYO_MERCHANT_NUMBER_ERR_MSG =
  'Please enter a valid merchant number';

export const showErrMsg = err => {
  const { data } = err.response || {};
  const { message = null, validation_error = null } = data || {};
  let errorMsg = message;
  if (validation_error) {
    errorMsg = Object.keys(validation_error)
      .map(key => `${key}: ${validation_error[key]}`)
      .join(', ');
  }
  toast.error(errorMsg || printf(CONNECTION_INTERREPED_MSG));
};

export const TIME_SLOT_ALREADY_EXISTS = {
  isValid: false,
  error: 'Time slot already exists',
};

export const TIME_SLOT_BIGGER_START_TIME = {
  isValid: false,
  error: 'Start time must be less than end time',
};

export const MUST_ADD_AT_LEAST_ONE_TIME_SLOT = {
  isValid: false,
  error: 'Please select at least one time slot for every available day',
};

export const isEmailValid = email => {
  if (typeof email !== 'string' || !email.trim()) {
    return false;
  }

  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email.toLowerCase());
};
