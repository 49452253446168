import React from 'react';
import PropTypes from 'prop-types';
import { SCLoader } from 'rollup-allspark';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  calculateStoreCumulativeTotal,
  exportedCsvHeaderTitleForXAxis,
  getAnalyticsCurrencyObj,
} from '../../../utils/nativeAnalyticsUtils';
import {
  ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE,
  COMPARISON_TYPE,
} from '../../../config/constants/analytics';
import StackedBarChart from '../BarChart/StackedBarChart';
import * as selectors from '../selectors';
import { makeSelectProperty } from '../selectors';
import BarChart from '../BarChart/BarChart';
import { isEmpty } from '../../../utils';

const BranchComparisonChart = ({
  analyticsData,
  analyticsDataLoading,
  targetKey,
  selectedProviderListForProviderComparison,
  selectedTimePeriod,
  selectedComparisonType,
  selectedBranches,
  chartXAxisData,
  branchWiseAnalyticsData,
}) => {
  const currencyObj = getAnalyticsCurrencyObj(analyticsData);
  const exportedCsvHeaderTitle =
    exportedCsvHeaderTitleForXAxis(selectedTimePeriod);

  const xAxisData = chartXAxisData.map(data => data.title);

  const getBrandRevenue = (brandId, providerId) => {
    const allStores = selectedBranches.reduce(
      (brands, branch) => [
        ...brands,
        ...(branchWiseAnalyticsData?.[branch.id] || []),
      ],
      [],
    );

    return (
      calculateStoreCumulativeTotal(
        allStores,
        {
          brand_id: brandId,
          ...(!isEmpty(providerId) && { provider_id: providerId }),
        },
        targetKey,
      ) / 100
    );
  };

  const yAxisData = (() => {
    if (selectedComparisonType === COMPARISON_TYPE.AGGREGATOR) {
      const providerDatas = selectedProviderListForProviderComparison.map(
        provider => {
          const providerData = {
            provider_id: provider.id,
            name: provider.title,
            color: ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE[provider.id],
          };
          providerData.data = chartXAxisData.map(brand =>
            getBrandRevenue(brand.id, provider.id),
          );
          return providerData;
        },
      );

      return providerDatas;
    }
    return chartXAxisData.map(brand => getBrandRevenue(brand.id));
  })();

  const renderGrossRevenueChart = () => {
    if (analyticsDataLoading) {
      return <SCLoader lineWidth={5} diameter={50} />;
    }

    if (
      !isEmpty(analyticsData) &&
      !isEmpty(chartXAxisData) &&
      !isEmpty(selectedBranches) &&
      selectedComparisonType === COMPARISON_TYPE.AGGREGATOR
    ) {
      return (
        <StackedBarChart
          id={targetKey}
          xAxisData={xAxisData}
          yAxisData={yAxisData}
          currencyObj={currencyObj}
          exportedCsvHeaderTitle={exportedCsvHeaderTitle}
        />
      );
    }

    if (
      !isEmpty(analyticsData) &&
      !isEmpty(chartXAxisData) &&
      !isEmpty(selectedBranches) &&
      selectedComparisonType !== COMPARISON_TYPE.AGGREGATOR
    ) {
      return (
        <BarChart
          id={targetKey}
          xAxisData={xAxisData}
          yAxisData={yAxisData}
          seriesName="Gross Order Value"
          currencyObj={currencyObj}
          exportedCsvHeaderTitle={exportedCsvHeaderTitle}
        />
      );
    }
    return (
      <div className="flex justify-center items-center h-16 text-2xl text-grey-darker">
        No Data Found!
      </div>
    );
  };

  return renderGrossRevenueChart();
};

BranchComparisonChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  targetKey: PropTypes.string,
  selectedTimePeriod: PropTypes.string,
  selectedProviderListForProviderComparison: PropTypes.array,
  selectedComparisonType: PropTypes.string,
  selectedBranches: PropTypes.array,
  selectedBrands: PropTypes.array,
  chartXAxisData: PropTypes.array,
  branchWiseAnalyticsData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
  selectedProviderListForProviderComparison:
    selectors.makeSelectSelectedProvidersForProviderComparison(),
  selectedComparisonType: selectors.makeSelectSelectedComparisonType(),
  selectedBrands: selectors.makeSelectAnalyticsSelectedBrand(),
  branchWiseAnalyticsData: makeSelectProperty('branchWiseAnalyticsData'),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(BranchComparisonChart);
