import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SCButton } from 'rollup-allspark';
import { format } from 'date-fns';
import { OOS_HELP } from '../../../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import {
  NATIVE_ANALYTICS_SAGA,
  OOS_TABLE_TYPE,
  OOS_TABS,
} from '../../../../config/constants/analytics';
import HelpPopover from '../../../../components/HelpPopover';
import { makeSelectProperty } from '../../selectors';
import { getList } from '../../actions';
import { isEmpty } from '../../../../utils';
import OosTable from './OosTable';
import { convertToHoursAndMinutes } from '../../../../utils/numToTime';
import { downloadCsvFromJSON } from '../../../../utils/sheetUtils';

const OosTableCard = ({
  filterData,
  selectedBusiness,
  selectedBranch,
  selectedBrand,
  itemReportData,
  itemLogData,
  modifierReportData,
  modifierLogData,
  listLoading,
  handleGetList,
  oosDefaultTab,
  handleDefaultTab,
  oosDefaultTableType,
  handleDefaultTableType,
}) => {
  // CONSTANTS

  // HOOKS
  const [oosPopoverOpen, setOosPopoverOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(oosDefaultTab);
  const [tableData, setTableData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [currentTableType, setCurrentTableType] = useState(oosDefaultTableType);

  // HANDLERS
  useEffect(() => {
    setSelectedTab(oosDefaultTab);
    setCurrentTableType(oosDefaultTableType);
  }, [oosDefaultTab, oosDefaultTableType]);

  // GET Table Data
  const getKeys = (tabType, modeTitle) => {
    if (tabType === 'analytics') {
      if (modeTitle === OOS_TABLE_TYPE.ITEMS.title) {
        return { urlKey: 'OOS_REPORT_ITEM', reducerKey: 'itemReportData' };
      }
      return {
        urlKey: 'OOS_REPORT_MODIFIER',
        reducerKey: 'modifierReportData',
      };
    }
    if (modeTitle === OOS_TABLE_TYPE.MODIFIERS.title) {
      return { urlKey: 'OOS_LOGS_MODIFIER', reducerKey: 'modifierLogData' };
    }
    return { urlKey: 'OOS_LOGS_ITEM', reducerKey: 'itemLogData' };
  };

  const fetchData = (tab, modeTitle) => {
    if (isEmpty(selectedBusiness)) return;

    const params = { ...filterData };
    const { urlKey, reducerKey } = getKeys(tab.type, modeTitle);
    const url = NATIVE_ANALYTICS_SAGA[urlKey]?.GET;

    if (url) {
      handleGetList({
        url,
        params,
        reducerKey,
      });
    }
  };
  // Table Type and Tab
  const handleChangeTab = tab => {
    if (isEmpty(selectedBusiness)) return;
    setSelectedTab(tab);
    fetchData(tab, OOS_TABLE_TYPE.ITEMS.title);
    setCurrentTableType(OOS_TABLE_TYPE.ITEMS.title);
    handleDefaultTab(tab);
    handleDefaultTableType(OOS_TABLE_TYPE.ITEMS.title);
  };
  const handleTableTypeChange = modeTitle => () => {
    if (isEmpty(selectedBusiness)) return;
    setCurrentTableType(modeTitle);
    fetchData(selectedTab, modeTitle);
    handleDefaultTableType(modeTitle);
  };

  // TABLE HEADERS DATA GENERATOR
  const generateTableHeaders = additionalHeaders => {
    const commonHeaders = [
      {
        slug: `${currentTableType === 'item' ? 'title' : 'modifier_title'}`,
        title: `${currentTableType === 'item' ? 'Item' : 'Modifier'} Name`,
      },
      { slug: 'branch_title', title: 'Branch' },
      { slug: 'brand_title', title: 'Brand' },
      { slug: 'duration', title: 'Total OOS Time' },
    ];

    const mergedHeaders = [...commonHeaders];

    if (additionalHeaders && additionalHeaders.length > 0) {
      mergedHeaders.splice(1, 0, ...additionalHeaders);
    }

    return mergedHeaders;
  };

  const REPORT_ITEMS_TABLE_HEADERS = generateTableHeaders();
  const REPORT_MODIFIERS_TABLE_HEADERS = generateTableHeaders([
    { slug: 'modifier_group_title', title: 'Modifier Group' },
  ]);
  const LOGS_ITEMS_TABLE_HEADERS = generateTableHeaders([
    { slug: 'start_time', title: 'Start Time' },
    { slug: 'end_time', title: 'End Time' },
  ]);
  const LOGS_MODIFIERS_TABLE_HEADERS = generateTableHeaders([
    { slug: 'modifier_group_title', title: 'Modifier Group' },
    { slug: 'start_time', title: 'Start Time' },
    { slug: 'end_time', title: 'End Time' },
  ]);

  // TABLE ROW DATA GENERATOR
  function generateTableBody(data = []) {
    if (isEmpty(data)) return [];
    // sort these by durations first
    // then for same duration, sort by title
    const sortedData = [...data].sort((a, b) => {
      if (a?.oos_mins !== b?.oos_mins) {
        return b.oos_mins - a.oos_mins;
      }
      if (!isEmpty(a?.modifier_id)) {
        return a?.modifier_title?.localeCompare(b?.modifier_title);
      }
      return a?.title?.localeCompare(b?.title);
    });
    return sortedData?.map(item => {
      const matchedBranch = selectedBranch.find(
        branch => branch.id === item.branch_id,
      );
      const matchedBrand = selectedBrand.find(
        brand => brand.id === item.brand_id,
      );

      return {
        ...item,
        branch_title: matchedBranch ? matchedBranch.title : null,
        brand_title: matchedBrand ? matchedBrand.title : null,
        duration: convertToHoursAndMinutes(item.oos_mins) || '0m',
      };
    });
  }

  useEffect(() => {
    if (selectedTab.type === 'analytics') {
      if (currentTableType === OOS_TABLE_TYPE.ITEMS.title) {
        setTableData(generateTableBody(itemReportData?.analytics));
        setTableHeaders(REPORT_ITEMS_TABLE_HEADERS);
      } else {
        setTableData(generateTableBody(modifierReportData?.analytics));
        setTableHeaders(REPORT_MODIFIERS_TABLE_HEADERS);
      }
    } else if (currentTableType === OOS_TABLE_TYPE.ITEMS.title) {
      setTableData(generateTableBody(itemLogData?.logs));
      setTableHeaders(LOGS_ITEMS_TABLE_HEADERS);
    } else {
      setTableData(generateTableBody(modifierLogData?.logs));
      setTableHeaders(LOGS_MODIFIERS_TABLE_HEADERS);
    }
  }, [itemReportData, itemLogData, modifierReportData, modifierLogData]);

  // CSV DOWNLOAD
  const handleCSVDownload = () => {
    const CSVData = [tableHeaders.map(header => header.title)];

    tableData.forEach(dataRow => {
      const rowData = tableHeaders.map(header => dataRow[header.slug]);
      CSVData.push(rowData);
    });

    downloadCsvFromJSON(
      CSVData,
      `Discount-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  // RENDERS
  // actionButton
  const renderDownloadCSVButton = !isEmpty(tableData) ? (
    <SCButton
      label="Download"
      variant="primary-outline"
      size="sm"
      className="h-6 w-auto px-5 text-xs"
      action={handleCSVDownload}
    />
  ) : null;

  // Tabs
  const renderTabs = () =>
    OOS_TABS.map(ct => {
      const selectedClassName =
        selectedTab.id === ct.id
          ? 'border-b-2 border-primary bg-primary-lighter text-black'
          : 'text-grey-darker';
      return (
        <div
          key={ct.id}
          role="presentation"
          className={`p-3 w-32 text-center mr-3 cursor-pointer ${selectedClassName}`}
          onClick={() => handleChangeTab(ct)}
        >
          {ct.title}
        </div>
      );
    });

  // Table Type Buttons
  const tableTypeButtonEl = Object.values(OOS_TABLE_TYPE).map(table => {
    const selectedClassName =
      currentTableType === table.title
        ? 'text-white bg-primary '
        : '!text-grey-darker !bg-grey';
    return (
      <SCButton
        key={table.id}
        variant="primary"
        action={handleTableTypeChange(table.title)}
        className={`flex !rounded-full flex-row items-center justify-center gap-x-1 !w-1/2 ${selectedClassName}`}
      >
        <p className="text-sm capitalize font-medium">{table.label}</p>
      </SCButton>
    );
  });

  return (
    <div className="flex flex-col p-4 w-full justify-between relative">
      <div className="flex flex-col text-black mb-2">
        <h5 className="text-base font-medium capitalize mb-2 flex">
          Out of Stock (OOS)
          <HelpPopover
            isPopoverOpen={oosPopoverOpen}
            position="right"
            isHtmlElement
            content={OOS_HELP}
            setIsPopoverOpen={setOosPopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2"
          />
        </h5>
      </div>
      <div className="flex items-center mb-4">{renderTabs()}</div>
      {/* OOS ITEMS AND MODIFIER SWITCH */}
      <div className="flex  w-full">
        <div className="flex w-full lg:w-1/2">
          <p className="font-bold flex flex-col w-1/2">
            OOS {selectedTab.type === 'analytics' ? 'Report' : 'Logs'}
            <span className="text-grey-darker !font-normal text-sm mt-2">
              {selectedTab.type === 'analytics' ? '' : ''}
            </span>
          </p>
          <div className="flex p-1 bg-grey rounded-full w-1/2">
            {tableTypeButtonEl}
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 justify-end">
          {renderDownloadCSVButton}
        </div>
      </div>
      {/* TABLES */}
      <OosTable
        tableHeaderData={tableHeaders}
        tableBodyData={tableData}
        listLoading={listLoading}
        tableType={currentTableType}
        activeTab={selectedTab}
      />
    </div>
  );
};
OosTableCard.propTypes = {
  filterData: PropTypes.object,
  selectedBusiness: PropTypes.any,
  selectedBranch: PropTypes.any,
  selectedBrand: PropTypes.any,
  itemReportData: PropTypes.any,
  itemLogData: PropTypes.any,
  modifierReportData: PropTypes.any,
  modifierLogData: PropTypes.any,
  listLoading: PropTypes.bool,
  handleGetList: PropTypes.func,
  oosDefaultTab: PropTypes.object,
  handleDefaultTab: PropTypes.func,
  oosDefaultTableType: PropTypes.string,
  handleDefaultTableType: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({
  selectedBusiness: makeSelectProperty('selectedBusiness'),
  selectedBranch: makeSelectProperty('selectedBranch'),
  selectedBrand: makeSelectProperty('selectedBrand'),
  itemReportData: makeSelectProperty(NATIVE_ANALYTICS_SAGA.OOS_REPORT_ITEM.KEY),
  itemLogData: makeSelectProperty(NATIVE_ANALYTICS_SAGA.OOS_LOGS_ITEM.KEY),
  modifierReportData: makeSelectProperty(
    NATIVE_ANALYTICS_SAGA.OOS_REPORT_MODIFIER.KEY,
  ),
  modifierLogData: makeSelectProperty(
    NATIVE_ANALYTICS_SAGA.OOS_LOGS_MODIFIER.KEY,
  ),
  listLoading: makeSelectProperty('listLoading'),
});

function mapDispatchToProps(dispatch) {
  return {
    handleGetList: params => dispatch(getList(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(OosTableCard);
