import { DEFAULT_ALL_PAGE_SIZE, DEFAULT_PAGE_NO } from '../../config';
import * as constants from './constants';

const defaultParams = {
  page: DEFAULT_PAGE_NO,
  size: DEFAULT_ALL_PAGE_SIZE,
};

export function loadOrderSourceGroupList(params) {
  return {
    type: constants.LOAD_ORDER_SOURCE,
    params,
  };
}

export function updateOrderStatus(payload) {
  return {
    type: constants.START_ORDER_STATUS,
    payload,
  };
}

export function updatePaymentInfo(data) {
  return {
    type: constants.UPDATE_PAYMENT_INFO,
    data,
  };
}

export function changeOniBiz(selectedBusiness) {
  return {
    type: constants.CHANGE_ONI_BIZ,
    selectedBusiness,
  };
}

export function findRider(params) {
  return {
    type: constants.FIND_RIDER,
    params: { ...params },
  };
}

export function editCancellationReasonDetails(cancellationReasonDetails) {
  return {
    type: constants.EDIT_CANCELLATION_REASON_DETAILS,
    cancellationReasonDetails,
  };
}

export function loadPaymentChannelsByCountry(params) {
  return {
    type: constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY,
    params: { ...params, ...defaultParams },
  };
}

// Integration Status actions

export function loadIntegrationStatus(brandId) {
  return {
    type: constants.LOAD_INTEGRATION_STATUS,
    brandId,
  };
}

export function cancelRiderAllocation(data) {
  return {
    type: constants.CANCEL_RIDER_ALLOCATION,
    data,
  };
}

export function getOrderAttachments(params) {
  return {
    type: constants.GET_ORDER_ATTACHMENTS,
    params,
  };
}

export function deleteOrder(params) {
  return {
    type: constants.DELETE_ORDER,
    params,
  };
}

export function updatePreparationTime(payload) {
  return {
    type: constants.UPDATE_PREP_TIME,
    payload,
  };
}

export function getList(meta) {
  return {
    type: constants.GET_LIST,
    meta,
  };
}

export function loadDetails(meta) {
  return {
    type: constants.LOAD_DETAILS,
    meta,
  };
}

export function editField(params, callback) {
  return {
    type: constants.EDIT_FIELD,
    params,
    callback,
  };
}

export function saveEntity(meta) {
  return {
    type: constants.SAVE_ENTITY,
    meta,
  };
}

export function loadOrders(meta) {
  return {
    type: constants.GET_ORDERS,
    meta,
  };
}

export function deleteEntity(payload) {
  return {
    type: constants.DELETE_ENTITY,
    payload,
  };
}
