import imageCompression from 'browser-image-compression';

import { CDN_URL, IMAGE_EXTENSION_MIME_TYPES, MAX_IMAGE_SIZE } from '../config';
import { isEmpty } from '.';

const compressImage = async (imageFile, MAX_FILE_SIDE = 1000) => {
  const options = {
    maxSizeMB: (MAX_IMAGE_SIZE / 1024) * 1024,
    maxWidthOrHeight: MAX_FILE_SIDE,
    useWebWorker: true,
  };
  try {
    const result = await imageCompression(imageFile, options);
    return result;
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const getImageUrl = (imageUrl, existingUrl) => {
  if (imageUrl && imageUrl.length > 0) {
    return `${CDN_URL}${imageUrl}`;
  }
  return existingUrl && existingUrl.length > 0
    ? `${CDN_URL}${existingUrl}`
    : null;
};

export const resizeImage = (file, opts = {}) =>
  new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('File not found'));
      return;
    }

    const {
      minSize = 0,
      maxSize = MAX_IMAGE_SIZE,
      maxSide = 1000,
      convertToMime,
      convertToGrayScale = false,
      getExtraProps = false,
    } = opts;

    const { size: fileSize, type: fileType, name: fileName } = file;

    if (fileSize < minSize) {
      reject(new Error(`File size is smaller than ${minSize / 1024} KB`));
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = e => {
      const isWebp = fileType === IMAGE_EXTENSION_MIME_TYPES.WEBP;
      const finalFileType = (() => {
        if (isWebp) return IMAGE_EXTENSION_MIME_TYPES.JPEG;
        if (!isEmpty(convertToMime)) return convertToMime;
        return fileType;
      })();

      const image = new Image();
      image.src = e.target.result;

      image.onload = function onImgLoad() {
        const { width, height } = this;
        const isHighRes = width > maxSide || height > maxSide;

        // If low res & is in desired size then resolve immediately
        // If force conversion to a mimeType is needed then proceed to next phase
        // If force conversion to a grayscale is needed then proceed to next phase
        // Webp to converted to JPEG even if it matches the logic above
        if (
          !isHighRes &&
          !isWebp &&
          fileSize <= maxSize &&
          isEmpty(convertToMime) &&
          !convertToGrayScale
        ) {
          if (getExtraProps) resolve({ file, height, width });
          else resolve(file);
          return;
        }

        // Calculate new width and height keeping the original aspect ratio
        const newRatio = width / height;
        const newWidth = (() => {
          if (fileSize <= maxSize) return width;
          if (newRatio > 1) return maxSide;
          return Math.ceil(maxSide * newRatio);
        })();
        const newHeight = (() => {
          if (fileSize <= maxSize) return height;
          if (newRatio > 1) return Math.ceil(maxSide / newRatio);
          return maxSide;
        })();

        try {
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          // draw image and check the blob
          const ctx = canvas.getContext('2d');

          if (convertToMime) {
            ctx.fillStyle = '#FFF'; /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }

          if (convertToGrayScale) {
            ctx.filter = 'grayscale()';
          }
          ctx.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(async blob => {
            canvas.remove();
            const { size: resizedFileSize } = blob;
            const newFile = new File([blob], fileName, {
              type: finalFileType,
            });

            if (resizedFileSize < minSize) {
              // resolve original file if the resized image size is less than minimum file size
              if (getExtraProps) {
                resolve({ file, height: newHeight, width: newWidth });
              } else resolve(file);
              return;
            }

            if (resizedFileSize > maxSize) {
              // If image size is still too large, reduce quality using library
              // Initial plan was to reduce by quality.
              // Web API doesn't support quality reduction for PNG images
              const compressedBlob = await compressImage(newFile);
              const compressedFile = new File([compressedBlob], fileName, {
                type: finalFileType,
              });
              const { size: compressedFileSize } = compressedFile;
              if (compressedFileSize >= minSize) {
                // resolve compressed file if it is above minimum image size
                if (getExtraProps) {
                  resolve({
                    file: compressedFile,
                    height: newHeight,
                    width: newWidth,
                  });
                } else resolve(compressedFile);
                return;
              }
            }

            // resolve if in desired file size as dimension is already in bounds
            if (getExtraProps) {
              resolve({ file: newFile, height: newHeight, width: newWidth });
            } else resolve(newFile);
          }, finalFileType);
        } catch (error) {
          reject(error);
        }
      };
    };
  });

export const resizeBulkImages = async (files, opts) => {
  const resizeImages = files.map(file => resizeImage(file, opts));
  const results = await Promise.allSettled(resizeImages);

  const resolved = [];
  const failed = [];

  results.forEach(result => {
    if (result.value) {
      resolved.push(result.value);
    } else {
      failed.push(
        new Error(result?.reason?.message || 'Failed to resize Image'),
      );
    }
  });

  return { resolved, failed };
};

export const downloadImageFromURL = async (baseURL, path, name = 'image') => {
  const image = await fetch(`${baseURL}${path}`);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default {
  getImageUrl,
  resizeImage,
  resizeBulkImages,
};
