import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RealizedRevenueBarChart from './BarChart/RealizedRevenueBarChart';
import GrossRevenueHeatmapChart from './Heatmap/GrossRevenueHeatmapChart';
import LostRevenueBarChart from './BarChart/LostRevenueBarChart';
import LostRevenueHeatmapChart from './Heatmap/LostRevenueHeatmapChart';
import HelpPopover from '../../components/HelpPopover';
import { GROSS_VS_LOST_HELP } from '../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { HOURLY_TIME_PERIOD } from '../../config/constants/analytics';

const GrossVsLostRevenueChart = ({
  selectedTimePeriod,
  isTimePeriodFilterStatusChanged,
}) => {
  const isHourly = selectedTimePeriod.slug === HOURLY_TIME_PERIOD;
  const COMPARISON_TABS = [
    {
      id: 1,
      title: 'Gross Order Value',
      tabContent: <RealizedRevenueBarChart />,
    },
    {
      id: 2,
      title: 'Lost Revenue',
      tabContent: <LostRevenueBarChart />,
    },
  ];

  let tabContentOne;
  if (!isTimePeriodFilterStatusChanged) {
    tabContentOne = isHourly ? (
      <GrossRevenueHeatmapChart />
    ) : (
      <RealizedRevenueBarChart />
    );
  } else {
    tabContentOne = COMPARISON_TABS[0].tabContent;
  }
  COMPARISON_TABS[0].tabContent = tabContentOne;

  let tabContentTwo;
  if (!isTimePeriodFilterStatusChanged) {
    tabContentTwo = isHourly ? (
      <LostRevenueHeatmapChart />
    ) : (
      <LostRevenueBarChart />
    );
  } else {
    tabContentTwo = COMPARISON_TABS[1].tabContent;
  }
  COMPARISON_TABS[1].tabContent = tabContentTwo;

  const [selectedTab, setSelectedTab] = useState(COMPARISON_TABS[0].id);
  const [isGrossVsLostRevenuePopoverOpen, setIsGrossVsLostRevenuePopoverOpen] =
    useState(false);
  const handleChangeTab = id => {
    setSelectedTab(id);
  };

  const renderTabs = () =>
    COMPARISON_TABS.map(ct => {
      const selectedClassName =
        selectedTab === ct.id
          ? 'border-b-2 border-primary bg-primary-lighter text-black'
          : 'text-grey-darker';
      return (
        <div
          key={ct.id}
          role="presentation"
          className={`p-3 min-w-4 cursor-pointer ${selectedClassName}`}
          onClick={() => handleChangeTab(ct.id)}
        >
          {ct.title}
        </div>
      );
    });

  const renderTabContent = () => {
    const tabContentIndex = COMPARISON_TABS.findIndex(
      ct => ct.id === selectedTab,
    );
    return COMPARISON_TABS[tabContentIndex].tabContent;
  };

  return (
    <div className="flex flex-col p-4 h-full">
      <div className="flex flex-col text-black mb-2">
        <h5 className="text-base font-medium capitalize mb-2 flex">
          Gross Order Value Vs Lost Revenue
          <HelpPopover
            isPopoverOpen={isGrossVsLostRevenuePopoverOpen}
            position="right"
            isHtmlElement
            content={GROSS_VS_LOST_HELP}
            setIsPopoverOpen={setIsGrossVsLostRevenuePopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2"
          />
        </h5>
        {/* <div className="text-sm mb-3">
          Gross Order Value is the total order amount of delivered orders, not
          accounting for discounts, aggregator commissions, or delivery fees.
        </div> */}
      </div>
      <div className="flex items-center mb-4">{renderTabs()}</div>
      {renderTabContent()}
    </div>
  );
};

GrossVsLostRevenueChart.propTypes = {
  selectedTimePeriod: PropTypes.any,
  isTimePeriodFilterStatusChanged: PropTypes.bool,
};

export default GrossVsLostRevenueChart;
