import React, { Component } from 'react';
import propTypes from 'prop-types';
import UserProfile from '../UserProfile';

class RouteChildViewer extends Component {
  render() {
    const { headerTitle, children } = this.props;
    const title = headerTitle ? (
      <div className="text-xl text-black m-0 font-semibold">{headerTitle}</div>
    ) : null;

    return (
      <div className="w-full sc-route-child-viewer-wrapper">
        <div className="header-title pl-4 flex justify-between border-b border-grey-light">
          {title}
          <UserProfile />
        </div>
        <div className="sc-route-child-viewer">{children}</div>
      </div>
    );
  }
}

RouteChildViewer.propTypes = {
  headerTitle: propTypes.string,
};

export default RouteChildViewer;
