import React, { Suspense } from 'react';
import { SCLoader } from 'rollup-allspark';
import RouteChildViewer from './Layout/RouteChildViewer';

// this will show the animation
const Loader = () => (
  <RouteChildViewer headerTitle="Loading...">
    <div className="flex justify-center items-center h-screen">
      <SCLoader diameter={70} lineWidth={5} />
    </div>
  </RouteChildViewer>
);

const Loadable = Component => props =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
