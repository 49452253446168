import { call, put, takeLatest, takeEvery } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import makeRequest from '../../utils/api';
import { isFunction, printf } from '../../utils';
import { LOAD_FAILED_NO_INTERNET_MSG } from '../../config/constants/errorMessages';
import {
  CREATE_SETTINGS,
  DELETE_SETTINGS,
  GET_DETAILS,
  GET_DETAILS_FAILED,
  GET_DETAILS_SUCCESS,
  GET_LIST,
  GET_LIST_FAILED,
  GET_LIST_SUCCESS,
  SETTINGS_REJECTED,
  SETTINGS_RESOLVED,
} from './constants';
import { showErrMsg } from '../../utils/validationSchema';

// common list get function to get any list
function* getList(meta) {
  const { params: metaParams } = meta || {};
  const { url, params, reducerKey } = metaParams || {};

  try {
    const payload = {
      url,
      method: 'GET',
      params,
    };
    const resp = yield call(makeRequest, payload);

    yield put({
      type: GET_LIST_SUCCESS,
      payload: {
        key: reducerKey,
        value: resp?.data?.results || resp?.data,
      },
    });
  } catch (err) {
    yield put({
      type: GET_LIST_FAILED,
      payload: {
        key: reducerKey,
      },
    });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(
      message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'reservation requests'),
    );
  }
}

function* getDetails({ params }) {
  const { url, reducerKey, callbackFn } = params || {};

  try {
    const payload = {
      url,
      method: 'GET',
    };
    if (params.params) {
      payload.params = params.params;
    }
    const res = yield call(makeRequest, payload);

    const resData = res?.data?.data || res?.data?.url || res?.data;

    yield put({
      type: GET_DETAILS_SUCCESS,
      payload: {
        key: reducerKey,
        value: resData,
      },
    });

    if (isFunction(callbackFn)) {
      callbackFn(resData);
    }
  } catch (err) {
    yield put({
      type: GET_DETAILS_FAILED,
      payload: {
        key: reducerKey,
      },
    });
    showErrMsg(err);
  }
}

function* createSettings({ params }) {
  const {
    url,
    data,
    callbackFn,
    successMsg = 'Settings created successfully',
  } = params || {};

  try {
    const payload = {
      url,
      method: 'POST',
      data,
    };
    const res = yield call(makeRequest, payload);

    yield put({
      type: SETTINGS_RESOLVED,
    });

    if (isFunction(callbackFn)) {
      callbackFn(res);
    }
    toast.success(successMsg);
  } catch (err) {
    yield put({
      type: SETTINGS_REJECTED,
    });
    showErrMsg(err);
  }
}

function* deleteSettings({ params }) {
  const {
    url,
    callbackFn,
    successMsg = 'Settings deleted successfully',
  } = params || {};
  try {
    const payload = {
      url,
      method: 'DELETE',
    };
    yield call(makeRequest, payload);

    if (isFunction(callbackFn)) {
      callbackFn();
    }
    toast.success(successMsg);
    yield put({
      type: SETTINGS_RESOLVED,
    });
  } catch (err) {
    yield put({
      type: SETTINGS_REJECTED,
    });
    showErrMsg(err);
  }
}

export default function* ratingAndReviewSaga() {
  yield takeEvery(GET_LIST, getList);
  yield takeEvery(GET_DETAILS, getDetails);
  yield takeEvery(CREATE_SETTINGS, createSettings);
  yield takeEvery(DELETE_SETTINGS, deleteSettings);
}
