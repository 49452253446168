import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import { SCButton } from 'rollup-allspark';
import { format } from 'date-fns';
import { makeSelectProperty } from '../selectors';
import PerformanceTable from './PerformanceTable';
import {
  generatePerformanceCSVData,
  sortAggregatedDataRow,
} from '../../../utils/nativeAnalyticsUtils';
import { editField } from '../actions';
import { isEmpty } from '../../../utils';
import { downloadCsvFromJSON } from '../../../utils/sheetUtils';

const BranchPerformance = ({ branchPerformanceData, updateField }) => {
  const { performanceRowData } = branchPerformanceData;

  const handleSortChange = (sortBy, sortOrder) => {
    const sortedPerformanceRowData = sortAggregatedDataRow(
      performanceRowData,
      sortBy,
      sortOrder,
    );
    updateField({
      branchPerformanceData: {
        ...branchPerformanceData,
        performanceRowData: sortedPerformanceRowData,
      },
    });
  };

  const handleCSVDownload = () => {
    const csvHeader = {
      name: 'Branch',
      net_sales: 'Net Sales',
      net_sales_cent: 'Net Sales (In Cent)',
      total_orders: 'Total Orders',
    };
    const CSVData = generatePerformanceCSVData(
      csvHeader,
      branchPerformanceData,
    );

    downloadCsvFromJSON(
      CSVData,
      `branch-performance-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const downloadCSVButton = !isEmpty(performanceRowData) ? (
    <SCButton
      label="Download"
      variant="primary-outline"
      size="sm"
      className="text-xs self-end"
      action={handleCSVDownload}
    />
  ) : null;

  return (
    <div className="flex flex-col gap-y-4">
      {downloadCSVButton}
      <PerformanceTable
        title="Branch"
        performanceData={branchPerformanceData}
        onSortChange={handleSortChange}
      />
    </div>
  );
};

BranchPerformance.propTypes = {
  branchPerformanceData: PropTypes.object,
  updateField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  branchPerformanceData: makeSelectProperty('branchPerformanceData'),
});

const mapDispatchToProps = dispatch => ({
  updateField: params => dispatch(editField(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BranchPerformance);
