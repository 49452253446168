/**
 *
 * PrivateRouteBak
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Navigate, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from '../Auth/reducer';
import saga from '../Auth/saga';
import Layout from '../../components/Layout';
import { makeSelectCurrentUser } from '../App/selectors';
import localStorage from '../../utils/localStorage';
import Login from '../Auth';
import { IS_MENU_TAB_DISABLED } from '../../config/env';

function PrivateRoute({ element, currentUser, path, ...rest }) {
  // console.log('args: ', rest);

  useInjectReducer({ key: 'auth', reducer });
  useInjectSaga({ key: 'auth', saga });
  const location = useLocation();
  /* eslint-disable */
  const isLoggedIn = checkLogin();
  if (!isLoggedIn) {
    if (path === '/login') {
      return <Login />;
    } else {
      return <Navigate to="/login" />;
    }
  }
  /*
    If redirect from logout to login and if still the user is logged in
    then forcefully redirect to login page
   */
  if (
    path === '/login' &&
    location.state &&
    location.state.referrer === 'logout'
  ) {
    return <Login />;
  }

  if (path === '/') {
    return <Navigate to="/oni?tab=all" />;
  }

  // already logged in, so redirect to home
  if (path === '/login') {
    return <Navigate to="/" />;
  }

  if (path && path.includes('menu') && IS_MENU_TAB_DISABLED) {
    return <Navigate to="/oni?tab=all" />;
  }
  return <Layout children={element} user={currentUser} />;
}

PrivateRoute.propTypes = {
  element: PropTypes.object,
  currentUser: PropTypes.object,
  path: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

function checkLogin() {
  return !!(
    localStorage.getItem('access_token') ||
    localStorage.getItem('refresh_token')
  );
}

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PrivateRoute);
