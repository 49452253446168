/*
 *
 * Profile constants
 *
 */

export const UPDATE_PROFILE = 'app/Profile/UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'app/Profile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'app/Profile/UPDATE_PROFILE_FAILED';

export const PROFILE_PHOTO_UPLOAD = 'app/Profile/PROFILE_PHOTO_UPLOAD';
export const PROFILE_PHOTO_UPLOAD_SUCCESS =
  'app/Profile/PROFILE_PHOTO_UPLOAD_SUCCESS';
export const PROFILE_PHOTO_UPLOAD_FAILED =
  'app/Profile/PROFILE_PHOTO_UPLOAD_FAILED';

export const PASSWORD_CHANGED = 'app/Profile/PASSWORD_CHANGED';
export const CHANGE_PASSWORD_REQUESTED =
  'app/Profile/CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_FAILED = 'app/Profile/CHANGE_PASSWORD_FAILED';
