import PropTypes from 'prop-types';
import React, { useDeferredValue } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { REDUCERS } from '../../../../config/constants';
import { COMPARISON_TYPE } from '../../../../config/constants/analytics';
import { useInjectReducer } from '../../../../utils/injectReducer';
import { useInjectSaga } from '../../../../utils/injectSaga';
import reducer from '../../reducer';
import saga from '../../saga';
import { makeSelectProperty } from '../../selectors';
import PaymentMethodTable from './PaymentMethodTable';
import PaymentMethodTableWithProvider from './PaymentMethodTableWithProvider';

export const PaymentMethodAnalytics = ({ selectedComparisonType }) => {
  useInjectReducer({ key: REDUCERS.NATIVE_ANALYTICS, reducer });
  useInjectSaga({ key: REDUCERS.NATIVE_ANALYTICS, saga });

  const deferredComparisonType = useDeferredValue(selectedComparisonType);

  if (deferredComparisonType === COMPARISON_TYPE.AGGREGATOR) {
    return (
      <PaymentMethodTableWithProvider
        selectedComparisonType={deferredComparisonType}
      />
    );
  }

  return <PaymentMethodTable selectedComparisonType={deferredComparisonType} />;
};

PaymentMethodAnalytics.propTypes = {
  selectedComparisonType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  selectedComparisonType: makeSelectProperty('selectedComparisonType'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PaymentMethodAnalytics);
