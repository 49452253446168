/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_LIST = 'app/LOAD_LIST';
export const GET_LIST_SUCCESS = 'app/GET_LIST_SUCCESS';
export const GET_LIST_FAILED = 'app/GET_LIST_FAILED';
export const UPLOAD_IMAGE = 'app/UPLOAD_IMAGE';
export const UPLOAD_IMAGE_RESOLVED = 'app/UPLOAD_IMAGE_RESOLVED';
export const UPLOAD_IMAGES = 'app/UPLOAD_IMAGES';
export const UPLOAD_IMAGES_SUCCESS = 'app/UPLOAD_IMAGES_SUCCESS';
export const UPLOAD_IMAGES_FAILED = 'app/UPLOAD_IMAGES_FAILED';
export const LOAD_PRICING_GROUPS = 'app/Business/LOAD_PRICING_GROUPS';
export const LOAD_PRICING_GROUPS_SUCCESS =
  'app/Business/LOAD_PRICING_GROUPS_SUCCESS';
export const LOAD_PRICING_GROUPS_FAILED =
  'app/Business/LOAD_PRICING_GROUPS_FAILED';
