import { PROVIDER_FORM } from '../formConfig/integration/providerFormConfig';
import { GRABFOOD_CONFIGURATION, POS_PROVIDER_IDS } from '../env';

export const DEFAULT_GRAB_MENU_STRUCTURE = 'v1';
export const GRABFOOD_MENU_STRUCTURE_LIST = [
  { id: 'v1', label: 'V1' },
  { id: 'v2', label: 'V2' },
];

export const KLIKIT_MENU_STRUCTURE_V2 = 2;

export const AGGREGATORS_SAGA = {
  GOFOOD: {
    INTEGRATE_API: {
      LOGIN_URL: {
        KEY: 'goFoodLoginUrl',
        GET: `v1/klikjek/store`,
      },
      STORE_LIST: {
        KEY: 'goFoodStoreList',
        GET: branchId => `v1/klikjek/stores/${branchId}`,
      },
    },
  },
};

export const INTEGRATION_TYPES = {
  API: 'api',
  INTERCEPTOR: 'interceptor',
};

export const OTP_MEDIUMS = {
  SMS: {
    id: 1,
    value: 'sms',
    label: 'SMS',
  },
  PHONE: {
    id: 2,
    value: 'phone',
    label: 'Phone',
  },
  WHATSAPP: {
    id: 3,
    value: 'whatsapp',
    label: 'WhatsApp',
  },
  EMAIL: {
    id: 4,
    value: 'email',
    label: 'Email',
  },
};

export const GRAB_API_READ_ONLY_INFO = [
  {
    id: 'grab_menu_endpoint',
    label: PROVIDER_FORM.LABEL.GET_MENU_ENDPOINT_LABEL,
    copySuccessMessage: 'GrabFood menu endpoint copied!',
    copyTooltipMessage: 'Copy GrabFood menu endpoint',
    value: GRABFOOD_CONFIGURATION.GET_MENU_ENDPOINT,
  },
  {
    id: 'grab_submit_order_endpoint',
    label: PROVIDER_FORM.LABEL.SUBMIT_ORDER_ENDPOINT_LABEL,
    copySuccessMessage: 'GrabFood submit order endpoint copied!',
    copyTooltipMessage: 'Copy GrabFood submit order endpoint',
    value: GRABFOOD_CONFIGURATION.SUBMIT_ORDER_ENDPOINT,
  },
  {
    id: 'grab_push_order_state_endpoint',
    label: PROVIDER_FORM.LABEL.PUSH_ORDER_STATE_ENDPOINT_LABEL,
    copySuccessMessage: 'GrabFood push order state endpoint copied!',
    copyTooltipMessage: 'Copy GrabFood push order state endpoint',
    value: GRABFOOD_CONFIGURATION.PUSH_ORDER_STATE_ENDPOINT,
  },
  {
    id: 'grab_oauth_token_endpoint',
    label: PROVIDER_FORM.LABEL.OAUTH_TOKEN_ENDPOINT_LABEL,
    copySuccessMessage: 'GrabFood oauth token endpoint copied!',
    copyTooltipMessage: 'Copy GrabFood oauth token endpoint',
    value: GRABFOOD_CONFIGURATION.OAUTH_TOKEN_ENDPOINT,
  },
  {
    id: 'grab_oatuh_scopes',
    label: PROVIDER_FORM.LABEL.OAUTH_SCOPES_LABEL,
    copySuccessMessage: 'GrabFood oauth scopes copied!',
    copyTooltipMessage: 'Copy GrabFood oauth scopes',
    value: GRABFOOD_CONFIGURATION.OAUTH_SCOPES,
  },
  {
    id: 'grab_partner_client_id',
    label: PROVIDER_FORM.LABEL.PARTNER_CLIENT_ID_LABEL,
    copySuccessMessage: 'GrabFood partner client id copied!',
    copyTooltipMessage: 'Copy GrabFood partner client id',
    value: GRABFOOD_CONFIGURATION.PARTNER_CLIENT_ID,
  },
  {
    id: 'grab_partner_client_secret',
    label: PROVIDER_FORM.LABEL.PARTNER_CLIENT_SECRET_LABEL,
    copySuccessMessage: 'GrabFood partner client secret copied!',
    copyTooltipMessage: 'Copy GrabFood partner client secret id',
    value: GRABFOOD_CONFIGURATION.PARTNER_CLIENT_SECRET,
  },
];

export const TIKTOK_DRIVER_LOOKUP_STATUS_LIST = {
  ACCEPTED: {
    label: 'Accepted',
    id: 2,
  },
  READY: {
    label: 'Ready',
    id: 4,
  },
};

export const TIKTOK_DRIVER_LOOKUP_TYPES = {
  AUTO: {
    label: 'Auto Lookup',
    id: 1,
  },
  MANUAL: {
    label: 'Manual Lookup',
    id: 2,
  },
};

export const TIKTOK_MIN_LOOKUP_TIME = 0;

export const TIKTOK_REDIRECT_URL_PARAMS = {
  CODE: 'code',
  STATE: 'state',
};

export const PROVIDERS_TAB_IDS = {
  AGGREGATORS: 1,
  POS: 2,
};

export const PROVIDERS_TABS = [
  {
    id: PROVIDERS_TAB_IDS.AGGREGATORS,
    key: 'aggregators',
    title: 'Aggregators',
  },
  {
    id: PROVIDERS_TAB_IDS.POS,
    key: 'pos',
    title: 'POS',
  },
];

export const DEFAULT_PROVIDERS_TAB = PROVIDERS_TABS[0];

export const BRANCH_ONLY_PROVIDERS = [];
export const BRANCH_ONLY_POS_PROVIDERS = [POS_PROVIDER_IDS.ESB];
