import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

const HelpPopover = ({
  isPopoverOpen,
  content,
  position,
  align,
  setIsPopoverOpen,
  iconClassNames,
  isHtmlElement,
  iconSize,
}) => (
  <Popover
    isOpen={isPopoverOpen}
    positions={[position]}
    align={align}
    transitionDuration={0}
    containerStyle={{
      zIndex: 10,
      cursor: 'auto',
      border: '1px solid #eaf0f6',
      backgroundColor: 'white',
      boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',
      borderRadius: '4px',
      maxWidth: '300px',
      padding: '16px 16px',
    }}
    onClickOutside={() => {
      setIsPopoverOpen(false);
    }}
    content={
      <div>
        {isHtmlElement === true ? (
          <p dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <p>{content}</p>
        )}
      </div>
    }
  >
    <div
      onClick={() => {
        setIsPopoverOpen(!isPopoverOpen);
      }}
      role="presentation"
    >
      <i className={`${iconSize} ${iconClassNames}`} />
    </div>
  </Popover>
);
HelpPopover.defaultProps = {
  isPopoverOpen: false,
  position: ['right'],
  align: 'right',
  iconClassNames: 'text-blue sc-question cursor-pointer',
  isHtmlElement: false,
  iconSize: 'text-base',
};

HelpPopover.propTypes = {
  isPopoverOpen: PropTypes.bool,
  content: PropTypes.string,
  position: PropTypes.string,
  align: PropTypes.string,
  iconClassNames: PropTypes.string,
  setIsPopoverOpen: PropTypes.func,
  isHtmlElement: PropTypes.bool,
  iconSize: PropTypes.string,
};

export default HelpPopover;
