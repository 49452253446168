import React from 'react';
import PropTypes from 'prop-types';
import Heatmap from './Heatmap';

const AggregatedHeatMapChart = ({
  id,
  data,
  seriesName,
  currencySymbol,
  exportedCsvHeaderTitle,
  heatmapColorRanges,
  isComparisonEnabled,
  providerData,
  selectedProviderListForProviderComparison,
}) => (
  <Heatmap
    id={id}
    data={data}
    seriesName={seriesName}
    currencySymbol={currencySymbol}
    exportedCsvHeaderTitle={exportedCsvHeaderTitle}
    heatmapColorRanges={heatmapColorRanges}
    isComparisonEnabled={isComparisonEnabled}
    providerData={providerData}
    selectedProviderListForProviderComparison={
      selectedProviderListForProviderComparison
    }
  />
);

AggregatedHeatMapChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  seriesName: PropTypes.string,
  currencySymbol: PropTypes.string,
  exportedCsvHeaderTitle: PropTypes.string,
  heatmapColorRanges: PropTypes.any,
  isComparisonEnabled: PropTypes.bool,
  providerData: PropTypes.any,
  selectedProviderListForProviderComparison: PropTypes.array,
};

export default AggregatedHeatMapChart;
