import React, { useEffect, useState, useTransition } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SCLoader, SCSelectV2 } from 'rollup-allspark';
import * as selectors from '../selectors';
import { makeSelectProperty } from '../selectors';
import {
  NATIVE_ANALYTICS_SAGA,
  REVENUE_KEYS,
} from '../../../config/constants/analytics';
import BranchComparisonChart from './BranchComparisonChart';
import { changeEntity } from '../actions';
import { checkShouldShowUsd } from '../../../utils/nativeAnalyticsUtils';

const BranchComparison = ({
  analyticsDataLoading,
  analyticsData,
  brands,
  selectedBusiness,
  selectedBranch,
  handleChangeEntity,
  comparisonBrandsChartOne,
  comparisonBrandsChartTwo,
}) => {
  // STATES
  const [selectedBranchOne, setSelectedBranchOne] = useState([]);
  const [selectedBranchTwo, setSelectedBranchTwo] = useState([]);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      if (!analyticsDataLoading) {
        onChangeBranchOne(selectedBranch);
        onChangeBranchTwo(selectedBranch);
      }
    });
  }, [analyticsDataLoading]);

  const shouldShowUsd = checkShouldShowUsd(analyticsData);
  const targetRevenueKey = shouldShowUsd
    ? REVENUE_KEYS.GROSS_REVENUE_USD
    : REVENUE_KEYS.GROSS_REVENUE;

  const handleBranchData = (selected, sagaKey) => {
    const allBrandIds = selected.reduce(
      (acc, branch) => [...acc, ...(branch.brand_ids || [])],
      [],
    );
    const uniqueBrandIds = [...new Set(allBrandIds)];
    handleChangeEntity({
      key: sagaKey.KEY,
      value: brands.filter(brand => uniqueBrandIds.includes(brand.id)),
    });
  };

  const onChangeBranchOne = selected => {
    setSelectedBranchOne(selected);
    handleBranchData(
      selected,
      NATIVE_ANALYTICS_SAGA.COMPARISON_BRANDS_CHART_ONE,
    );
  };

  const onChangeBranchTwo = selected => {
    setSelectedBranchTwo(selected);
    handleBranchData(
      selected,
      NATIVE_ANALYTICS_SAGA.COMPARISON_BRANDS_CHART_TWO,
    );
  };

  const labelEL = item => `${item.id}. ${item.title}`;
  const branchDropDownOneEl = selectedBusiness ? (
    <SCSelectV2
      searchable
      multiple
      options={selectedBranch}
      trackBy="id"
      customLabel={item => labelEL(item)}
      searchAbleAttribute={['title', 'id']}
      title="Branch"
      placeholder="Select branch"
      value={selectedBranchOne}
      onSelect={onChangeBranchOne}
      className="w-full mb-4"
    />
  ) : null;

  const branchDropDownTwoEl = selectedBusiness ? (
    <SCSelectV2
      searchable
      multiple
      options={selectedBranch}
      trackBy="id"
      customLabel={item => labelEL(item)}
      searchAbleAttribute={['title', 'id']}
      title="Branch"
      placeholder="Select branch"
      value={selectedBranchTwo}
      onSelect={onChangeBranchTwo}
      className="w-full mb-4"
    />
  ) : null;

  if (analyticsDataLoading || isPending) {
    return <SCLoader lineWidth={5} diameter={50} />;
  }

  return (
    <div className="w-full flex gap-x-2 my-4">
      <div className="w-1/2">
        {branchDropDownOneEl}
        <BranchComparisonChart
          targetKey={targetRevenueKey}
          selectedBranches={selectedBranchOne}
          chartXAxisData={comparisonBrandsChartOne}
        />
      </div>
      <div className="w-1/2">
        {branchDropDownTwoEl}
        <BranchComparisonChart
          targetKey={targetRevenueKey}
          selectedBranches={selectedBranchTwo}
          chartXAxisData={comparisonBrandsChartTwo}
        />
      </div>
    </div>
  );
};

BranchComparison.propTypes = {
  analyticsDataLoading: PropTypes.bool,
  analyticsData: PropTypes.array,
  brands: PropTypes.array,
  selectedBusiness: PropTypes.object,
  selectedBranch: PropTypes.array,
  handleChangeEntity: PropTypes.func,
  comparisonBrandsChartOne: PropTypes.array,
  comparisonBrandsChartTwo: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  brands: makeSelectProperty('brands'),
  selectedBusiness: selectors.makeSelectAnalyticsSelectedBusiness(),
  selectedBranch: makeSelectProperty('selectedBranch'),
  comparisonBrandsChartOne: selectors.makeSelectProperty(
    NATIVE_ANALYTICS_SAGA.COMPARISON_BRANDS_CHART_ONE.KEY,
  ),
  comparisonBrandsChartTwo: selectors.makeSelectProperty(
    NATIVE_ANALYTICS_SAGA.COMPARISON_BRANDS_CHART_TWO.KEY,
  ),
});

const mapDispatchToProps = dispatch => ({
  handleChangeEntity: params => dispatch(changeEntity(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BranchComparison);
