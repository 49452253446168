export const RATING_REVIEW_SAGA = {
  INTEGRATION_LIST: {
    KEY: 'integrationList',
    GET: 'v1/payment/enterprise/business/accounts',
    POST: 'v1/payment/enterprise/business/accounts?gateway_id=',
  },
  INTEGRATOR_LIST: {
    KEY: 'ratingProviderList',
    GET: (brandId = 0, branchId = 0) =>
      `v1/ratings/integration/${brandId}/${branchId}/status`,
  },
  INTEGRATION_DETAILS: {
    GOOGLE: {
      KEY: 'googleIntegrationDetails',
      GET: 'v1/ratings/integration',
      POST: 'v1/ratings/integration',
      DELETE: 'v1/ratings/integration',
    },
    FACEBOOK: {
      KEY: 'facebookIntegrationDetails',
      GET: 'v1/ratings/integration',
      POST: 'v1/ratings/integration',
      DELETE: 'v1/ratings/integration',
      AUTH_DIALOG_URL: {
        GET: 'v1/rateitfb/public/oauth-dialog',
        KEY: 'facebookAuthDialogUrl',
      },
      LOGIN_DETAILS: {
        GET: 'v1/rateitfb/public/authcallback',
        KEY: 'facebookLoginDetails',
      },
    },
  },
};

export const RATING_PROVIDER_OBJ = {
  GOOGLE: {
    id: 1,
    title: 'Google',
  },
  FACEBOOK: {
    id: 2,
    title: 'Facebook',
  },
};

export const FACEBOOK_PAGE_TYPES = [
  {
    value: 'personal',
    title: 'Personal',
  },
  {
    value: 'business',
    title: 'Business',
  },
];
