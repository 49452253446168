// Settings List sample
import * as constants from './constants';

export function getList(params) {
  return {
    type: constants.GET_LIST,
    params,
  };
}

export function changeEntity(payload) {
  return {
    type: constants.CHANGE_ENTITY,
    payload,
  };
}

export function getDetails(params) {
  return {
    type: constants.GET_DETAILS,
    params,
  };
}

export function createSettings(params) {
  return {
    type: constants.CREATE_SETTINGS,
    params,
  };
}

export function deleteSettings(params) {
  return {
    type: constants.DELETE_SETTINGS,
    params,
  };
}
