import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import {
  SCAvatar,
  SCButton,
  SCModal,
  SCModalBody,
  SCModalHeader,
} from 'rollup-allspark';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { getImageUrl } from '../utils/imageUtils';
import { makeSelectCurrentUser } from '../containers/App/selectors';
import { LOGOUT_ROUTE, PROFILE_ROUTE } from '../config/constants/routes';

function UserProfile({ user }) {
  const [isShowing, setIsShowing] = useState(false);
  const logoutModalRef = useRef(null);
  const profileContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        profileContainerRef.current &&
        !profileContainerRef.current.contains(event.target)
      ) {
        setIsShowing(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const url = getImageUrl(null, user.profile_pic);
  const avatar =
    url && url.length > 0 ? (
      <SCAvatar className="rounded-avatar" size="xs" image={url} />
    ) : (
      <SCAvatar
        className="rounded-avatar"
        size="xs"
        text={user?.first_name?.charAt(0)}
      />
    );

  const handleCloseLogoutModal = () => {
    if (logoutModalRef && logoutModalRef.current)
      logoutModalRef.current.closeModal();
  };

  const logoutModalHeader = (
    <SCModalHeader className="normal-case" closeButtonPosition="right">
      Confirm Logout
    </SCModalHeader>
  );

  const logoutModalBody = (
    <SCModalBody className="flex flex-col">
      <div className="flex justify-center">
        <h3 className="normal-case text-base pb-6 ">
          Are you sure you want to logout?
        </h3>
      </div>
      <div className="flex justify-center">
        <SCButton
          variant="primary-outline"
          className="sc-btn-secondary-outline "
          action={handleCloseLogoutModal}
          label="No"
        />
        <SCButton
          label="Yes"
          className="ml-4"
          action={() => navigate(LOGOUT_ROUTE)}
        />
      </div>
    </SCModalBody>
  );

  const handleLogoutClick = () => {
    if (logoutModalRef && logoutModalRef.current) {
      logoutModalRef.current.showModal();
    }
  };

  const profileContainer = isShowing ? (
    <div className="user-profile-container" ref={profileContainerRef}>
      <div className="mt-2 px-4 py-2 text-grey-darker w-40 truncate">
        klikit Admin
      </div>
      <div className="h-px bg-primary-lighter mx-4 my-1" />
      <Link
        to={PROFILE_ROUTE}
        className="scam-profile-link flex flex-1 h-8 px-4 items-center text-base text-primary"
      >
        <i className="mr-2 sc-profile" />
        <div>Profile</div>
      </Link>
      <div
        role="presentation"
        title="Logout"
        className="flex flex-1 h-8 px-4 items-center text-primary text-base"
        onClick={handleLogoutClick}
      >
        <i className="mr-2 sc-logout" />
        <div>Logout</div>
      </div>
    </div>
  ) : null;

  return (
    <>
      <div className="flex flex-1 justify-end mr-4">
        <div
          role="presentation"
          className="scam-user-info-dd ml-8 flex relative items-center cursor-pointer"
          onClick={() => setIsShowing(true)}
        >
          {avatar}
          <div className="user-profile-name">
            {user.first_name} {user.last_name}
          </div>
          <i className="sc-keyboard-arrow-down text-primary text-xl" />
          {profileContainer}
        </div>
      </div>
      <div className="confirmation-modal-wrapper">
        <SCModal
          variant="white"
          ref={logoutModalRef}
          header={logoutModalHeader}
          body={logoutModalBody}
          className="confirm-modal"
        />
      </div>
    </>
  );
}

UserProfile.propTypes = {
  user: propTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(UserProfile);
