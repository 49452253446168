import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SCLoader } from 'rollup-allspark';
import { makeSelectProperty } from '../selectors';
import { cn, isEmpty } from '../../../utils';
import { getAnalyticsCurrencyObj } from '../../../utils/nativeAnalyticsUtils';
import { readablePrice } from '../../../utils/getCurrency';

const PerformanceTable = ({
  analyticsDataLoading,
  analyticsData,
  title,
  performanceData,
  onSortChange,
}) => {
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    onSortChange(sortBy, sortOrder);
  }, [analyticsData]);

  const currencyObj = getAnalyticsCurrencyObj(analyticsData);

  const { performanceRowData, grandTotalRevenue, grandTotalOrders } =
    performanceData;

  const headerKeys = [
    {
      key: 'name',
      title,
    },
    {
      key: 'netSales',
      title: (
        <div className="flex items-center justify-between">
          <span>Net Sales</span>
          <div>
            <span className="text-xs mr-1 font-medium">Grand Total</span>
            <span>{readablePrice(grandTotalRevenue, currencyObj)}</span>
          </div>
        </div>
      ),
      className: 'text-right',
    },
    {
      key: 'totalOrder',
      title: (
        <div className="flex items-center justify-between">
          <span>Number of Orders</span>
          <div>
            <span className="text-xs mr-1 font-medium">Total</span>
            <span>{grandTotalOrders}</span>
          </div>
        </div>
      ),
      className: 'text-right border-r-0',
    },
  ];

  const thBaseClass =
    'p-3 bg-grey-lighter border-b border-r font-normal font-semibold text-left';

  if (analyticsDataLoading) {
    return <SCLoader lineWidth={5} diameter={50} />;
  }

  if (isEmpty(performanceRowData)) {
    return (
      <div className="flex justify-center items-center h-16 text-2xl text-grey-darker">
        No Data Found!
      </div>
    );
  }

  const handleSorting = newSortBy => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(newSortBy);
    onSortChange(newSortBy, newSortOrder);
  };

  const getSortClass = field =>
    cn({
      'cursor-pointer text-base': true,
      'sc-sort': sortBy !== field,
      'sc-keyboard-arrow-up': sortBy === field && sortOrder === 'asc',
      'sc-keyboard-arrow-down': sortBy === field && sortOrder === 'desc',
    });

  const theadRowsElm = headerKeys.map(cell => {
    const sortEl = cell.key ? (
      <button
        type="button"
        className={getSortClass(cell.key)}
        onClick={() => handleSorting(cell.key)}
      />
    ) : null;

    return (
      <th key={cell.key} className={cn(thBaseClass, cell.className)}>
        <div className="flex justify-between items-center gap-x-4">
          <div className="w-full">{cell.title}</div>
          {sortEl}
        </div>
      </th>
    );
  });

  return (
    <div className="overflow-y-auto max-h-96">
      <table className="table-auto w-full">
        <thead className="sticky top-0">
          <tr>{theadRowsElm}</tr>
        </thead>
        <tbody>
          {performanceRowData.map(row => (
            <tr key={row.name}>
              <td className="p-3 border-b border-r">{row.name}</td>
              <td className="p-3 text-right border-b border-r">
                {readablePrice(row.netSales, currencyObj)}
              </td>
              <td className="p-3 text-right border-b border-r-0">
                {row.totalOrder}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PerformanceTable.propTypes = {
  analyticsDataLoading: PropTypes.bool,
  analyticsData: PropTypes.array,
  title: PropTypes.string,
  performanceData: PropTypes.object,
  onSortChange: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: makeSelectProperty('analyticsDataLoading'),
  analyticsData: makeSelectProperty('analyticsData'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PerformanceTable);
