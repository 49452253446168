export const INDONESIAN_CURRENCY_CODE = 'IDR';
export const JAPAN_CURRENCY_CODE = 'JPY';
export const TAIWAN_CURRENCY_CODE = 'TWD';

export const LOCALE_SETTINGS = {
  AUD: 'en-AU',
  USD: 'en-US',
  SGD: 'en-SG',
  JPY: 'ja-JP',
  IDR: 'id-ID',
  PHP: 'en-PH',
  TWD: 'zh-tw',
  MYR: 'en-MY',
  GBP: 'en-GB',
  HKD: 'zh-HK',
  THB: 'th-TH',
  VND: 'vi-VN',
  BDT: 'bn-BD',
  KHR: 'km-KH',
};

export const ROUNDED_CURRENCIES = [
  INDONESIAN_CURRENCY_CODE,
  JAPAN_CURRENCY_CODE,
  TAIWAN_CURRENCY_CODE,
];
