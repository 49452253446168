import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  SCButton,
  SCModalBody,
  SCModalHeader,
  SCSidebar,
  SCSidebarItem,
  SCTooltip,
} from 'rollup-allspark';
import klikLogo from '../../assets/images/klikit-admin-black.png';
import {
  ADD_ORDER_CONFIG_PAYMENT_METHOD,
  CAMPAIGN_ROUTE,
  CUSTOMER_LIST_ROUTE,
  IMAGE_MANAGER_ROUTE,
  LINKS_IN_BIO_ROUTE,
  LIVE_MONITOR_ROUTE,
  MENU_PULLING_ROUTE,
  MENU_V2_ROUTE,
  PAYMENT_INTEGRATION_ROUTE,
  PROVIDER_INTEGRATION_ROUTE,
  RATING_REVIEW_INTEGRATION_ROUTE,
  RESERVATION_ROUTE,
  WHITELABEL_ROUTE,
} from '../../config/constants/routes';
import { CRM_USERS, IS_MENU_TAB_DISABLED } from '../../config/env';
import {
  hasPermission,
  isAllowedUser,
  PERMISSIONS,
} from '../../utils/permission';
import { isEmpty } from '../../utils';

const SideItemDisableWrapper = ({ children }) => (
  <SCTooltip content="Due to maintenance, menu section is disabled currently. Check back later.">
    <div className="cursor-not-allowed opacity-50">
      <div className="pointer-events-none">{children}</div>
    </div>
  </SCTooltip>
);

function Layout({ children, user }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const logoutModalRef = useRef(null);
  const loc = useLocation();
  const locPath = loc.pathname.split('/');
  // const itemsRoute = '/menu/items';
  // const modifiersRoute = '/menu/modifiers';
  // const rulesRoute = '/menu/rules';

  const navigate = useNavigate();
  const showMenuV2 = hasPermission(user, 'fetch.mmv2');
  const hasAccessToWhitelabel = hasPermission(user, 'crud.white_label');
  const showImageManager = hasPermission(user, 'fetch.image.manager');
  const isMenuV1Hidden = true;

  // const isMenuNavActive = (match, location) =>
  //   location.pathname === itemsRoute ||
  //   location.pathname === modifiersRoute ||
  //   location.pathname === rulesRoute;

  const logoVisibilityClass = `flex flex-1 items-center font-medium ${
    isCollapsed ? 'opacity-0' : ''
  }`;

  const headerWidth = isCollapsed ? 90 : 225;

  const header = (
    <div
      role="presentation"
      className="flex cursor-pointer"
      style={{ width: headerWidth }}
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <div
        className="flex items-center justify-end mr-2"
        style={{
          width: '52px',
          minWidth: '44px',
          height: '60px',
          lineHeight: '60px',
        }}
      >
        <i className="kt-hamburger-menu text-grey-darker text-lg" />
      </div>
      <div className={logoVisibilityClass}>
        <img src={klikLogo} alt="Klikit" style={{ height: '22px' }} />
      </div>
    </div>
  );

  const versionNo = process.env.npm_package_version;

  const copyDisclaimer = !isCollapsed ? (
    <div className="copy-disclaimer p-8 w-full text-mini text-grey-darker">
      <div className="mb-1">Copyright © KLIKIT PTE LTD.</div>
      <div className="mb-1">All Rights Reserved</div>
      <div>v{versionNo}</div>
    </div>
  ) : null;

  const checkRoute = routeName => locPath.includes(routeName);

  const handleCloseLogoutModal = () => {
    if (logoutModalRef && logoutModalRef.current)
      logoutModalRef.current.closeModal();
  };

  const logoutModalHeader = (
    <SCModalHeader className="normal-case" closeButtonPosition="right">
      Do you want to logout?
    </SCModalHeader>
  );

  const logoutModalBody = (
    <SCModalBody>
      <div className="flex justify-end">
        <SCButton
          variant="primary-outline"
          size="lg"
          action={handleCloseLogoutModal}
          className="sc-btn-secondary-outline "
        >
          No
        </SCButton>
        <SCButton
          label="Yes"
          size="lg"
          className="ml-4"
          action={() => navigate('/logout')}
        />
      </div>
    </SCModalBody>
  );

  const sidebarItemClass = isCollapsed ? 'opacity-0' : '';

  const menuSidebarItem = (
    <SCSidebarItem
      icon={<i className="kt-menu text-grey-darker" />}
      title={
        <NavLink title="Menu" to="/menu/items" className={sidebarItemClass}>
          Menu
        </NavLink>
      }
      active={checkRoute('menu')}
    />
  );

  const menuElV1 = IS_MENU_TAB_DISABLED ? (
    <SideItemDisableWrapper>{menuSidebarItem}</SideItemDisableWrapper>
  ) : (
    menuSidebarItem
  );

  const menuElV2 = showMenuV2 ? (
    <SCSidebarItem
      icon={<i className="kt-menu text-grey-darker" />}
      title={
        <NavLink
          title="Menu Management"
          to={MENU_V2_ROUTE}
          className={sidebarItemClass}
        >
          Menu
        </NavLink>
      }
      active={checkRoute('menu-v2')}
    />
  ) : null;

  const promoModuleEl = hasPermission(user, 'fetch.promo') ? (
    <SCSidebarItem
      icon={<i className="kt-promo text-grey-darker" />}
      title={
        <NavLink title="Promo" to="/promo" className={sidebarItemClass}>
          Promo
        </NavLink>
      }
      active={checkRoute('promo')}
    />
  ) : null;

  const campaignModuleEl = hasPermission(user, PERMISSIONS.FETCH_CAMPAIGN) ? (
    <SCSidebarItem
      icon={<i className="kt-discount text-grey-darker" />}
      title={
        <NavLink
          title="Campaign"
          to={CAMPAIGN_ROUTE}
          className={sidebarItemClass}
        >
          Campaign
        </NavLink>
      }
      active={checkRoute('campaign')}
    />
  ) : null;

  const loyaltyModuleEl = hasPermission(user, 'fetch.loyalty') ? (
    <SCSidebarItem
      icon={<i className="kt-gift text-grey-darker" />}
      title={
        <NavLink title="Loyalty" to="/loyalty" className={sidebarItemClass}>
          Loyalty
        </NavLink>
      }
      active={checkRoute('loyalty')}
    />
  ) : null;

  const reservationModuleEl = hasPermission(user, 'fetch.reservation') ? (
    <SCSidebarItem
      icon={<i className="kt-reservation text-grey-darker" />}
      title={
        <NavLink
          title="Reservation"
          to={RESERVATION_ROUTE}
          className={sidebarItemClass}
        >
          Reservation
        </NavLink>
      }
      active={checkRoute('reservation')}
    />
  ) : null;

  const crmModuleEl =
    !isEmpty(CRM_USERS) && !CRM_USERS.includes(user.email) ? null : (
      <SCSidebarItem
        icon={<i className="kt-customer-settings text-grey-darker" />}
        title={
          <NavLink
            title="Customer Hub"
            to={CUSTOMER_LIST_ROUTE}
            className={sidebarItemClass}
          >
            Customer Hub
          </NavLink>
        }
        active={checkRoute('customers')}
      />
    );

  const whiteLabelModuleEl = hasAccessToWhitelabel ? (
    <SCSidebarItem
      icon={<i className="kt-white-label text-grey-darker" />}
      title={
        <NavLink
          title="Whitelabel"
          to={WHITELABEL_ROUTE}
          className={sidebarItemClass}
        >
          White Label
        </NavLink>
      }
      active={checkRoute('whitelabel')}
    />
  ) : null;

  const imageManagerModuleEl = showImageManager && (
    <SCSidebarItem
      icon={<i className="kt-image text-grey-darker" />}
      title={
        <NavLink
          title="Image Manager"
          to={IMAGE_MANAGER_ROUTE}
          className={sidebarItemClass}
        >
          Image Manager
        </NavLink>
      }
      active={checkRoute('image-manager')}
    />
  );

  const linkinBioModuleEl = hasPermission(user, 'fetch.linkinbio') ? (
    <SCSidebarItem
      icon={<i className="kt-sync text-grey-darker" />}
      title={
        <NavLink
          title="Links"
          to={LINKS_IN_BIO_ROUTE}
          className={sidebarItemClass}
        >
          Links
        </NavLink>
      }
      active={checkRoute('links')}
    />
  ) : null;

  const reviewIntegrationModuleEl = hasPermission(
    user,
    'fetch.review.integration',
  ) ? (
    <SCSidebarItem
      icon={<i className="kt-article text-grey-darker" />}
      title={
        <NavLink
          title="Rating & Review Integration"
          to={RATING_REVIEW_INTEGRATION_ROUTE}
          className={sidebarItemClass}
        >
          Rating & Review
        </NavLink>
      }
      active={checkRoute('rating-review-integration')}
    />
  ) : null;

  const liveMonitorModuleEl = hasPermission(
    user,
    PERMISSIONS.FETCH_LIVE_MONITOR,
  ) ? (
    <SCSidebarItem
      icon={<i className="kt-eye-tracking text-grey-darker" />}
      title={
        <NavLink
          title="Live Monitor"
          to={LIVE_MONITOR_ROUTE}
          className={sidebarItemClass}
        >
          Live Monitor
        </NavLink>
      }
      active={checkRoute('live-monitor')}
    />
  ) : null;

  const paymentIntegrationModuleEl = (
    <SCSidebarItem
      icon={<i className="kt-payment text-grey-darker" />}
      title={
        <NavLink
          title="Payment Integration"
          to={PAYMENT_INTEGRATION_ROUTE}
          className={sidebarItemClass}
        >
          Payment Integration
        </NavLink>
      }
      active={checkRoute('payment-integration')}
    />
  );

  const menuPullingModuleEl = (
    <SCSidebarItem
      icon={<i className="kt-menu-pulling text-grey-darker" />}
      title={
        <NavLink
          title="Menu Pulling"
          to={MENU_PULLING_ROUTE}
          className={sidebarItemClass}
        >
          Menu Pulling
        </NavLink>
      }
      active={checkRoute('menu-pulling')}
    />
  );

  const addOrderSettingsEl = hasPermission(user, 'fetch.addOrderConfig') ? (
    <SCSidebarItem
      icon={<i className="kt-payment-setting text-grey-darker" />}
      title={
        <NavLink
          title="Add Order Config"
          to={ADD_ORDER_CONFIG_PAYMENT_METHOD}
          className={sidebarItemClass}
        >
          Add Order Config
        </NavLink>
      }
      active={checkRoute('links')}
    />
  ) : null;

  const featureAccessModuleElm = hasPermission(
    user,
    PERMISSIONS.FETCH_FEATURE_ALL,
  ) ? (
    <SCSidebarItem
      icon={<i className="kt-flag text-grey-darker" />}
      title={
        <NavLink
          title="Feature Access"
          to="/feature-access"
          className={sidebarItemClass}
        >
          Feature Access
        </NavLink>
      }
      active={checkRoute('feature-access')}
    />
  ) : null;

  const roleSidebarElm = isAllowedUser(user) ? (
    <SCSidebarItem
      icon={<i className="kt-role text-grey-darker" />}
      title={
        <NavLink title="Role" to="/role" className={sidebarItemClass}>
          Role
        </NavLink>
      }
      active={checkRoute('role')}
    />
  ) : null;

  const permissionSidebarElm = isAllowedUser(user) ? (
    <SCSidebarItem
      icon={<i className="kt-permission text-grey-darker" />}
      title={
        <NavLink
          title="Permission"
          to="/permission"
          className={sidebarItemClass}
        >
          Permission
        </NavLink>
      }
      active={checkRoute('permission')}
    />
  ) : null;

  return (
    <div className="h-screen flex w-full sc-main">
      <SCSidebar
        header={header}
        collapsed={isCollapsed}
        width={225}
        collapsedWidth={90}
      >
        <>
          <div className="menu-item-group-label">Orders</div>
          <SCSidebarItem
            icon={<i className="sc-dashboard text-grey-darker" />}
            title={
              <NavLink
                title="Order Dashboard"
                to="/oni"
                className={sidebarItemClass}
              >
                Overview
              </NavLink>
            }
            active={checkRoute('oni')}
          />
        </>

        <>
          <div className="menu-item-group-label">Menu</div>
          {!isMenuV1Hidden && menuElV1}
          {menuElV2}
        </>

        <>
          <div className="menu-item-group-label">Marketing</div>
          <SCSidebarItem
            icon={<i className="sc-integration text-grey-darker" />}
            title={
              <NavLink
                title="Webshop"
                to="/webshop"
                className={sidebarItemClass}
              >
                Webshop
              </NavLink>
            }
            active={checkRoute('webshop')}
          />
          {linkinBioModuleEl}
          {promoModuleEl}
          {campaignModuleEl}
          {loyaltyModuleEl}
          {reservationModuleEl}
          {crmModuleEl}
          {whiteLabelModuleEl}
        </>

        <div>
          <div className="menu-item-group-label">Analytics</div>
          <SCSidebarItem
            icon={<i className="kt-analytics text-grey-darker" />}
            title={
              <NavLink
                title="Analytics"
                to="/analytics"
                className={sidebarItemClass}
              >
                Overview
              </NavLink>
            }
            active={checkRoute('analytics')}
          />

          <SCSidebarItem
            icon={<i className="kt-activities text-grey-darker" />}
            title={
              <NavLink
                title="Activities"
                to="/activities"
                className={sidebarItemClass}
              >
                Activities
              </NavLink>
            }
            active={checkRoute('activities')}
          />
          {liveMonitorModuleEl}
        </div>
        <>
          <div className="menu-item-group-label">Integration</div>
          <SCSidebarItem
            icon={<i className="kt-integration text-grey-darker" />}
            title={
              <NavLink
                title="Integration Status"
                to="/integration-status"
                className={sidebarItemClass}
              >
                Integration Status
              </NavLink>
            }
            active={checkRoute('integration-status')}
          />
          <SCSidebarItem
            icon={<i className="kt-aggregators text-grey-darker" />}
            title={
              <NavLink
                title="Aggregators"
                to="/provider"
                className={sidebarItemClass}
              >
                Aggregators
              </NavLink>
            }
            active={checkRoute('provider')}
          />
          <SCSidebarItem
            icon={<i className="kt-rider text-grey-darker" />}
            title={
              <NavLink
                title="3PL Integration"
                to={PROVIDER_INTEGRATION_ROUTE}
                className={sidebarItemClass}
              >
                3PL Integration
              </NavLink>
            }
            active={checkRoute('provider-integration')}
          />
          <SCSidebarItem
            icon={<i className="kt-webhooks text-grey-darker" />}
            title={
              <NavLink
                title="Webhooks"
                to="/webhooks"
                className={sidebarItemClass}
              >
                Webhooks
              </NavLink>
            }
            active={checkRoute('webhooks')}
          />
          {paymentIntegrationModuleEl}
          {reviewIntegrationModuleEl}
          {menuPullingModuleEl}
        </>

        <div>
          <div className="menu-item-group-label">Config</div>
          <SCSidebarItem
            icon={<i className="kt-business text-grey-darker" />}
            title={
              <NavLink
                title="Business"
                to="/business"
                className={sidebarItemClass}
              >
                Business
              </NavLink>
            }
            active={checkRoute('business')}
          />

          <SCSidebarItem
            icon={<i className="sc-brand text-grey-darker" />}
            title={
              <NavLink title="Brand" to="/brand" className={sidebarItemClass}>
                Brand
              </NavLink>
            }
            active={checkRoute('brand')}
          />
          <SCSidebarItem
            icon={<i className="kt-location text-grey-darker" />}
            title={
              <NavLink
                title="Location"
                to="/branch"
                className={sidebarItemClass}
              >
                Location
              </NavLink>
            }
            active={checkRoute('branch')}
          />
          {addOrderSettingsEl}
          <SCSidebarItem
            icon={<i className="kt-printer text-grey-darker" />}
            title={
              <NavLink
                title="Printer Settings"
                to="/printer-settings"
                className={sidebarItemClass}
              >
                Printer Settings
              </NavLink>
            }
            active={checkRoute('printer-settings')}
          />
          {imageManagerModuleEl}
          <SCSidebarItem
            icon={<i className="kt-account-circle text-grey-darker" />}
            title={
              <NavLink title="User" to="/user" className={sidebarItemClass}>
                User
              </NavLink>
            }
            active={checkRoute('user')}
          />

          {roleSidebarElm}
          {permissionSidebarElm}
          {featureAccessModuleElm}
        </div>

        {copyDisclaimer}
      </SCSidebar>
      {/* <SCModal
        ref={logoutModalRef}
        header={logoutModalHeader}
        body={logoutModalBody}
      /> */}
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.object,
  user: PropTypes.object,
};

export default Layout;
