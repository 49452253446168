/* eslint-disable radix */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ORDER_STATUS } from '../../config';
import {
  CONNECTION_INTERREPED_MSG,
  DELETE_FAILED_NO_INTERNET_MSG,
  LOAD_FAILED_NO_INTERNET_MSG,
} from '../../config/constants/errorMessages';
import { ORDER_CANCELLATION_LIST_TYPE_IDS } from '../../config/constants/orders';
import { isEmpty, isFunction, printf } from '../../utils';
import makeRequest from '../../utils/api';
import { showErrMsg } from '../../utils/validationSchema';
import * as constants from './constants';

function* getBrands(meta) {
  try {
    const params = { ...meta.params };
    const { isForIntegrationStatus = false } = meta.params || {};

    if (params?.isForIntegrationStatus !== undefined) {
      delete params.isForIntegrationStatus;
    }

    const payload = {
      url: 'v1/brand',
      method: 'get',
      params,
    };

    const brands = yield call(makeRequest, payload);

    if (isForIntegrationStatus) {
      yield put({
        type: constants.LOAD_BRANDS_FOR_INTEGRATION_STATUS_SUCCESS,
        brands: brands?.data?.results,
      });
    } else {
      yield put({
        type: constants.LOAD_ONI_BRAND_SUCCESS,
        brands: brands?.data?.results,
      });
    }
  } catch (err) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Brand'));
  }
}

function* getBranches(meta) {
  try {
    const params = { ...meta.params };
    const { isForIntegrationStatus = false } = meta.params || {};

    if (params?.isForIntegrationStatus !== undefined) {
      delete params.isForIntegrationStatus;
    }

    const payload = {
      url: 'v1/branch',
      method: 'get',
      params,
    };

    const branches = yield call(makeRequest, payload);

    if (isForIntegrationStatus) {
      yield put({
        type: constants.LOAD_BRANCHES_FOR_INTEGRATION_STATUS_SUCCESS,
        branches: branches?.data?.results,
      });
    } else {
      yield put({
        type: constants.LOAD_ONI_BRANCH_SUCCESS,
        branches: branches.data.results,
      });
    }
  } catch (err) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Branch'));
  }
}

function* getLiveOrders(meta) {
  const { filterByStatus } = meta.params || {};
  const isFetchingScheduledOrder =
    filterByStatus && +filterByStatus === ORDER_STATUS.SCHEDULED;
  try {
    const params = {
      ...meta.params,
    };

    const payload = {
      url: 'v1/oni/order',
      method: 'get',
      params,
    };
    const data = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_LIVE_ORDERS_SUCCESS,
      liveOrders: data.data,
    });
    if (isFetchingScheduledOrder) {
      yield put({
        type: constants.LOAD_LIVE_SCHEDULED_ORDERS_SUCCESS,
        scheduledOrders: data.data,
      });
    }
  } catch (error) {
    yield put({ type: constants.LOAD_LIVE_ORDERS_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Orders'));
  }
}

function* getLiveNewOrders(meta) {
  try {
    const params = {
      ...meta.params,
    };

    const payload = {
      url: 'v1/oni/order',
      method: 'get',
      params,
    };
    const data = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_LIVE_NEW_ORDERS_SUCCESS,
      liveNewOrders: data.data,
    });
  } catch (error) {
    yield put({ type: constants.LOAD_LIVE_NEW_ORDERS_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Orders'));
  }
}

function* getLiveOrder(meta) {
  try {
    const payload = {
      url: `v1/oni/order/${meta.params.id}`,
      method: 'get',
    };
    const data = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_LIVE_ORDER_SUCCESS,
      liveOrder: data.data,
    });
  } catch (error) {
    yield put({ type: constants.LOAD_LIVE_ORDER_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Past Order'));
  }
}

function* updateOrderStatus(meta) {
  const { data, callbackFn } = meta.payload || {};
  const { status } = data || {};

  try {
    const payload = {
      url: `v1/oni/order/status`,
      method: 'patch',
      data,
    };

    if (data.UPDATE_PAYMENT_STATUS) {
      const paymentStatusUpdatePayload = {
        url: `v1/oni/manualorder/payment`,
        method: 'patch',
        data: {
          id: data.id,
          payment_channel_id: data.payment_channel_id,
          payment_status: data.payment_status,
        },
      };
      yield call(makeRequest, paymentStatusUpdatePayload);
    }
    yield call(makeRequest, payload);

    if (isFunction(callbackFn)) {
      callbackFn();
    }

    if (status === ORDER_STATUS.ACCEPTED) {
      toast.success('Order accepted');
    } else if (status === ORDER_STATUS.CANCELLED) {
      toast.success('Order cancelled');
    } else if (status === ORDER_STATUS.READY) {
      toast.success('Order ready');
    } else if (status === ORDER_STATUS.PICKEDUP) {
      toast.success('Order picked up');
    } else if (status === ORDER_STATUS.DELIVERED) {
      toast.success('Order delivered');
    } else if (status === ORDER_STATUS.VOIDED) {
      toast.success('Order voided');
    }
    yield put({ type: constants.START_ORDER_STATUS_SUCCESS, order: data });
  } catch (error) {
    yield put({ type: constants.START_ORDER_STATUS_FAILED });

    if (status === ORDER_STATUS.ACCEPTED) {
      toast.error('Order not accepted');
    } else if (status === ORDER_STATUS.CANCELLED) {
      toast.error('Order not cancelled');
    } else if (status === ORDER_STATUS.READY) {
      toast.error('Order not ready');
    } else if (status === ORDER_STATUS.PICKEDUP) {
      toast.success('Order not picked up');
    } else if (status === ORDER_STATUS.DELIVERED) {
      toast.error('Order not delivered');
    } else if (status === ORDER_STATUS.VOIDED) {
      toast.success('Order not voided');
    }
  }
}

function* updatePaymentInfo({ data }) {
  try {
    const paymentInfoUpdatePayload = {
      url: 'v1/oni/qrorder/payment',
      method: 'patch',
      data: {
        id: data.id,
        payment_channel_id: data.payment_channel_id,
        payment_status: data.payment_status,
      },
    };
    if (data.isManualOrder) {
      paymentInfoUpdatePayload.url = 'v1/oni/manualorder/payment';
    }
    const response = yield call(makeRequest, paymentInfoUpdatePayload);
    const successMessage = response.data && response.data.message;
    toast.success(successMessage || 'Payment info updated');
    yield put({ type: constants.UPDATE_PAYMENT_INFO_SUCCESS });
  } catch (error) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Payment info'));
  }
}

function* getBusinesses(meta) {
  try {
    const params = { ...meta.params };

    const payload = {
      url: 'v1/business',
      method: 'get',
      params,
    };

    const biz = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_BIZ_ONI_SUCCESS,
      businesses: biz.data.results,
    });
  } catch (err) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Business'));
  }
}

function* getOrderSourceGroupList() {
  try {
    const payload = {
      url: 'v1/oni/order-sources',
      method: 'get',
    };
    const orderSourceGroupListResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ORDER_SOURCE_SUCCESS,
      orderSourceGroupList: orderSourceGroupListResp.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_ORDER_SOURCE_FAILED });
    const { message } = err.response.data || {};
    toast.error(
      message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Order Source List'),
    );
  }
}

function* getAllPaymentChannels() {
  try {
    const url = `v1/oni/payment-channels/all`;
    const payload = {
      url,
      method: 'GET',
    };

    const res = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_PAYMENT_CHANNELS_All_SUCCESS,
      paymentChannelsAll: res.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_PAYMENT_CHANNELS_All_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Payment Channel'));
  }
}

function* getPaymentStatusList() {
  try {
    const url = `v1/oni/payment-statuses`;
    const payload = {
      url,
      method: 'GET',
    };

    const res = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_PAYMENT_STATUS_LIST_SUCCESS,
      paymentStatusList: res.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_PAYMENT_STATUS_LIST_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Payment Status List'));
  }
}

function* findRiderForOrder(meta) {
  try {
    const { orderId, findAgain, callbackFn } = meta.params;
    const url = findAgain
      ? `v1/oni/order/fulfillment/dispatch/${orderId}?findAgain=${findAgain}`
      : `v1/oni/order/fulfillment/dispatch/${orderId}`;

    const findRiderPayload = {
      url,
      method: 'patch',
      data: {},
    };
    const response = yield call(makeRequest, findRiderPayload);

    if (isFunction(callbackFn)) {
      callbackFn();
    }
    const successMessage = response.data && response.data.message;
    toast.success(successMessage || 'Find Rider requested!');
    yield put({ type: constants.FIND_RIDER_SUCCESS });
  } catch (error) {
    yield put({ type: constants.FIND_RIDER_FAILED });
    const { message } = error?.response?.data || {};
    toast.error(
      message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Rider request'),
    );
  }
}

function* getCancellationReasons() {
  try {
    const payload = {
      url: `v1/oni/cancel-order-reasons?type_id=${ORDER_CANCELLATION_LIST_TYPE_IDS.RESTAURANT}`,
      method: 'GET',
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: constants.GET_CANCELLATION_REASONS_SUCCESS,
      cancellationReasons: response.data,
    });
  } catch (error) {
    yield put({ type: constants.GET_CANCELLATION_REASONS_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'cancellation reasons'));
  }
}

function* getPaymentChannelsByCountry(meta) {
  const { params } = meta || {};
  try {
    const url = `v1/oni/payment-channels`;
    const payload = {
      url,
      method: 'GET',
      params: {
        country_id: params.countryId,
      },
    };

    const res = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY_SUCCESS,
      paymentChannelsByCountry: res.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'payment methods'));
  }
}

function* loadIntegrationStatus(meta) {
  const { brandId = null } = meta || {};
  try {
    const payload = {
      url: `v1/provider/brand/${brandId}`,
      method: 'GET',
    };
    const response = yield call(makeRequest, payload);

    yield put({
      type: constants.LOAD_INTEGRATION_STATUS_SUCCESS,
      integrationStatuses: response?.data || [],
    });
  } catch (error) {
    const { message } = error.response.data || {};
    toast.error(
      message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Integration Status'),
    );
  }
}

function* cancelRiderAllocation(meta) {
  try {
    const payload = {
      url: `v1/oni/order/${meta.data}/cancel-fulfillment`,
      method: 'delete',
    };
    const response = yield call(makeRequest, payload);
    const successMessage = response.data && response.data.message;
    toast.success(successMessage || 'Rider Allocation cancelled!');
    yield put({ type: constants.CANCEL_RIDER_ALLOCATION_SUCCESS });
  } catch (error) {
    yield put({ type: constants.CANCEL_RIDER_ALLOCATION_FAILED });
    toast.error(
      'Rider Allocation cancellation failed. please check your Internet connection and try again',
    );
  }
}

function* getOrderAttachmentsForSeniorCitizen(meta) {
  try {
    const payload = {
      url: `v1/oni/order/${meta.params}/sc-attachments`,
      method: 'get',
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: constants.GET_ORDER_ATTACHMENTS_SUCCESS,
      orderAttachments: response.data,
    });
  } catch (error) {
    yield put({ type: constants.GET_ORDER_ATTACHMENTS_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'order attachments'));
  }
}

function* deleteOrder({ params }) {
  try {
    const payload = {
      url: `v1/oni/order/${params.id}`,
      method: 'delete',
    };
    const response = yield call(makeRequest, payload);
    const successMessage = response.data && response.data.message;
    toast.success(successMessage || 'Order deleted!');
    yield put({ type: constants.START_ORDER_STATUS_SUCCESS });
  } catch (error) {
    toast.error(printf(DELETE_FAILED_NO_INTERNET_MSG, 'order'));
  }
}
function* updatePrepTime(meta) {
  const { data, callbackFn } = meta.payload || {};
  const { externalId, prepTime, orderId } = data || {};

  try {
    const payload = {
      url: `v1/oni/order/${orderId}/preparation-time`,
      method: 'patch',
      data: {
        preparation_time: prepTime,
        external_id: externalId,
      },
    };
    const response = yield call(makeRequest, payload);
    const successMessage = response.data && response.data.message;

    if (isFunction(callbackFn)) {
      callbackFn();
    }

    yield put({ type: constants.UPDATE_PREP_TIME_SUCCESS });
    toast.success(successMessage || 'Preparation Time successfully updated!');
  } catch (error) {
    yield put({ type: constants.UPDATE_PREP_TIME_FAILED });
    toast.error('Preparation Time update failed!');
  }
}

function* getDetails(req) {
  const { reducerKey, url, method, params, callbackFn } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };
    const res = yield call(makeRequest, payload);
    const details = res.data.details || res.data || {};

    yield put({
      type: constants.LOAD_DETAILS_SUCCESS,
    });

    if (reducerKey) {
      const successReducerPayload = {
        [reducerKey]: details,
      };

      yield put({
        type: constants.EDIT_FIELD,
        params: successReducerPayload,
      });
    }

    if (isFunction(callbackFn)) {
      callbackFn(details, res);
    }
  } catch (error) {
    yield put({
      type: constants.EDIT_FIELD,
      params: {
        [reducerKey]: [],
      },
    });

    const { message } = error?.response?.data || {};
    toast.error(message || `Failed to load ${reducerKey}`);
  }
}

function* getList(req) {
  const {
    reducerKey,
    url,
    method,
    params,
    isPaginated = true,
    callbackFn,
    loaderKey,
  } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };
    const res = yield call(makeRequest, payload);
    const list = res.data.results || res.data || [];

    const successReducerPayload = {
      [reducerKey]: list,
    };

    if (isPaginated) {
      successReducerPayload.total = res.data.total;
      successReducerPayload.page = res.data.page;
      successReducerPayload.size = res.data.size;
    }

    if (loaderKey) {
      yield put({
        type: constants.GET_LIST_SUCCESS,
        params: { loaderKey },
      });
    } else {
      yield put({
        type: constants.GET_LIST_SUCCESS,
      });
    }

    yield put({
      type: constants.EDIT_FIELD,
      params: successReducerPayload,
    });

    if (isFunction(callbackFn)) {
      callbackFn(list, res);
    }
  } catch (err) {
    if (loaderKey) {
      yield put({
        type: constants.GET_LIST_FAILED,
        params: { loaderKey },
      });
    } else {
      yield put({
        type: constants.GET_LIST_FAILED,
      });
    }

    yield put({
      type: constants.EDIT_FIELD,
      params: {
        [reducerKey]: [],
      },
    });

    showErrMsg(err);
  }
}

function* saveEntity(req) {
  const { url, method, data = null, callbackFn, loaderKey } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      data,
    };

    // if (!isEmpty(data)) {
    //   payload.data = data;
    // }

    const resp = yield call(makeRequest, payload);

    if (isFunction(callbackFn)) {
      callbackFn();
    }

    if (loaderKey) {
      yield put({
        type: constants.SAVE_ENTITY_SUCCESS,
        params: { loaderKey },
      });
    } else {
      yield put({
        type: constants.SAVE_ENTITY_SUCCESS,
      });
    }

    toast.success(resp.data.message || 'Saved successfully');
  } catch (error) {
    if (loaderKey) {
      yield put({
        type: constants.SAVE_ENTITY_FAILED,
        params: { loaderKey },
      });
    } else {
      yield put({
        type: constants.SAVE_ENTITY_FAILED,
      });
    }
    const { message } = error?.response?.data || {};
    toast.error(message || 'Failed to save');
  }
}

function* getOrders(req) {
  const { reducerKey, url, method, params, callbackFn, loaderKey } =
    req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };
    const res = yield call(makeRequest, payload);
    const list = res.data.orders || res.data || [];
    const { total, page, size } = res.data;

    const successReducerPayload = {
      [reducerKey]: list,
      total,
      page,
      size,
    };

    if (isEmpty(loaderKey)) {
      yield put({
        type: constants.GET_ORDERS_SUCCESS,
      });
    } else {
      yield put({
        type: constants.GET_ORDERS_SUCCESS,
        params: { loaderKey },
      });
    }

    yield put({
      type: constants.EDIT_FIELD,
      params: successReducerPayload,
    });

    yield put({
      type: constants.UPDATE_SELECTED_ORDER,
      params: successReducerPayload,
    });

    if (isFunction(callbackFn)) {
      callbackFn(list, res);
    }
  } catch (err) {
    if (loaderKey) {
      yield put({
        type: constants.GET_LIST_FAILED,
        params: { loaderKey },
      });
    } else {
      yield put({
        type: constants.GET_LIST_FAILED,
      });
    }

    yield put({
      type: constants.EDIT_FIELD,
      params: {
        [reducerKey]: [],
      },
    });

    showErrMsg(err);
  }
}

function* deleteEntity(meta) {
  const { url, method, callbackFn } = meta.payload;

  try {
    const resp = yield call(makeRequest, { url, method });

    yield put({ type: constants.DELETE_ENTITY_SUCCESS });

    if (isFunction(callbackFn)) {
      callbackFn();
    }

    toast.success(resp.data.message);
  } catch (error) {
    yield put({ type: constants.DELETE_ENTITY_FAILED });

    const { data } = error.response || {};
    const { message, validation_error: validationErr } = data || {};
    toast.error(message || validationErr || CONNECTION_INTERREPED_MSG);
  }
}

export default function* oniSaga() {
  yield takeLatest(constants.LOAD_ONI_BRAND, getBrands);
  yield takeLatest(constants.LOAD_ONI_BRANCH, getBranches);
  yield takeLatest(constants.LOAD_LIVE_ORDERS, getLiveOrders);
  yield takeLatest(constants.LOAD_LIVE_NEW_ORDERS, getLiveNewOrders);
  yield takeLatest(constants.LOAD_LIVE_ORDER, getLiveOrder);
  yield takeLatest(constants.START_ORDER_STATUS, updateOrderStatus);
  yield takeLatest(constants.UPDATE_PAYMENT_INFO, updatePaymentInfo);
  yield takeLatest(constants.LOAD_BIZ_ONI, getBusinesses);
  yield takeLatest(constants.LOAD_ORDER_SOURCE, getOrderSourceGroupList);
  yield takeLatest(constants.LOAD_PAYMENT_CHANNELS_All, getAllPaymentChannels);
  yield takeLatest(constants.LOAD_PAYMENT_STATUS_LIST, getPaymentStatusList);
  yield takeLatest(constants.FIND_RIDER, findRiderForOrder);
  yield takeLatest(constants.GET_CANCELLATION_REASONS, getCancellationReasons);
  yield takeLatest(
    constants.LOAD_PAYMENT_CHANNELS_BY_COUNTRY,
    getPaymentChannelsByCountry,
  );
  yield takeLatest(constants.LOAD_BRANDS_FOR_INTEGRATION_STATUS, getBrands);
  yield takeLatest(constants.LOAD_BRANCHES_FOR_INTEGRATION_STATUS, getBranches);
  yield takeLatest(constants.LOAD_INTEGRATION_STATUS, loadIntegrationStatus);
  yield takeLatest(constants.CANCEL_RIDER_ALLOCATION, cancelRiderAllocation);
  yield takeLatest(
    constants.GET_ORDER_ATTACHMENTS,
    getOrderAttachmentsForSeniorCitizen,
  );
  yield takeLatest(constants.DELETE_ORDER, deleteOrder);
  yield takeLatest(constants.UPDATE_PREP_TIME, updatePrepTime);
  yield takeEvery(constants.GET_LIST, getList);
  yield takeEvery(constants.LOAD_DETAILS, getDetails);
  yield takeLatest(constants.SAVE_ENTITY, saveEntity);
  yield takeLatest(constants.DELETE_ENTITY, deleteEntity);
  yield takeEvery(constants.GET_ORDERS, getOrders);
}
