import { DEFAULT_ALL_PAGE_SIZE, DEFAULT_PAGE_NO } from '../../config';
import {
  CHANGE_AUTO_ACCEPT,
  CHANGE_AUTO_PILOT,
  CHANGE_BIZ,
  CHANGE_INTEGRATION_TYPE,
  CHANGE_IS_SINGLE_DEVICE,
  CHANGE_MENU_SYNC,
  CHANGE_PROVIDER,
  DELETE_PROVIDER,
  DESELECT_LOCATION,
  DESELECT_PROVIDER,
  GET_PROVIDER_CRED,
  LOAD_BRANDS,
  LOAD_BRANDS_FILTER,
  LOAD_BRAND_BY_ID,
  LOAD_BUSINESS,
  LOAD_LOCATION,
  LOAD_LOCATION_BY_ID,
  LOAD_PROVIDERS,
  LOAD_TIED_PROVIDERS,
  LOCATION_SELECTED,
  SELECTED_BRAND,
  UPDATE_BRAND_STATUS,
  UPDATE_PAGINATION,
  UPDATE_PROVIDER_CRED,
  SEND_OTP_REQUEST_FOR_GOFOOD,
  VERIFY_OTP_TOKEN_FOR_GOFOOD,
  LOGIN_SHOPEE_CREDENTIALS,
  VERIFY_OTP_TOKEN_SHOPEE,
  GET_UBEREATS_LOGIN_URL,
  GET_AVAILABLE_UBEREATS_STORES,
  CHANGE_UBEREATS_STORE,
  GET_SQUARE_LOGIN_URL,
  GET_AVAILABLE_SQUARE_STORES,
  CHANGE_SQUARE_STORE,
  UPLOAD_CSV,
  VERIFY_OTP_TOKEN_FOR_DEMAECAN,
  GET_SETTINGS,
  CHANGE_SETTINGS,
  LOAD_LIST,
  EDIT_FIELD,
  CREATE_SETTINGS,
  UPDATE_SETTINGS,
  DELETE_SETTINGS,
  RESET_FIELDS,
} from './constants';

export function loadLocations(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: LOAD_LOCATION,
    params,
  };
}

export function loadBrands(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: LOAD_BRANDS,
    params,
  };
}

export function loadLocationById(locationId) {
  return {
    type: LOAD_LOCATION_BY_ID,
    locationId,
  };
}

export function updateBrandStatus() {
  return {
    type: UPDATE_BRAND_STATUS,
  };
}

export function changeBrand(brand) {
  return {
    type: SELECTED_BRAND,
    brand,
  };
}

export function updatePagination(pageNo, pageSize) {
  return {
    type: UPDATE_PAGINATION,
    pageNo,
    pageSize,
  };
}

export function deselectLocation() {
  return {
    type: DESELECT_LOCATION,
  };
}

export function loadBrandById(brandId) {
  return {
    type: LOAD_BRAND_BY_ID,
    brandId,
  };
}

export function loadBusinesses(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: LOAD_BUSINESS,
    params,
  };
}

export function loadBrandsFilter(
  params = { page: DEFAULT_PAGE_NO, size: DEFAULT_ALL_PAGE_SIZE },
) {
  return {
    type: LOAD_BRANDS_FILTER,
    params,
  };
}

export function changeBiz(business) {
  return {
    type: CHANGE_BIZ,
    business,
  };
}

export function loadProviders(params) {
  return {
    type: LOAD_PROVIDERS,
    params,
  };
}

export function loadTiedProviders(params) {
  return {
    type: LOAD_TIED_PROVIDERS,
    params,
  };
}

export function changeLocation(selectedLocation) {
  return {
    type: LOCATION_SELECTED,
    selectedLocation,
  };
}

export function changeProvider(provider) {
  return {
    type: CHANGE_PROVIDER,
    provider,
  };
}

export function deselectProvider() {
  return {
    type: DESELECT_PROVIDER,
  };
}

export function loadProviderCred(params) {
  return {
    type: GET_PROVIDER_CRED,
    params,
  };
}

export function updateProviderCred(providerCred) {
  return {
    type: UPDATE_PROVIDER_CRED,
    providerCred,
  };
}

export function changeAutoAccept(autoAccept) {
  return {
    type: CHANGE_AUTO_ACCEPT,
    autoAccept,
  };
}

export function changeIntegrationType(selectedIntegrationType) {
  return {
    type: CHANGE_INTEGRATION_TYPE,
    selectedIntegrationType,
  };
}

export function changeAutoPilot(autoPilot) {
  return {
    type: CHANGE_AUTO_PILOT,
    autoPilot,
  };
}

export function changeMenuSync(menuSync) {
  return {
    type: CHANGE_MENU_SYNC,
    menuSync,
  };
}

export function changeIsSingleDevice(isSingleDevice) {
  return {
    type: CHANGE_IS_SINGLE_DEVICE,
    isSingleDevice,
  };
}

export function deleteProvider(providerId) {
  return {
    type: DELETE_PROVIDER,
    providerId,
  };
}

export function sendOTPRequestForGoFood(params) {
  return {
    type: SEND_OTP_REQUEST_FOR_GOFOOD,
    params,
  };
}

export function verifyOTPTokenForGoFood(params) {
  return {
    type: VERIFY_OTP_TOKEN_FOR_GOFOOD,
    params,
  };
}

export function loginShopeeCredentials(params) {
  return {
    type: LOGIN_SHOPEE_CREDENTIALS,
    params,
  };
}

export function verifyOTPTokenForShopee(params) {
  return {
    type: VERIFY_OTP_TOKEN_SHOPEE,
    params,
  };
}

export function verifyOTPTokenForDemaecan(params) {
  return {
    type: VERIFY_OTP_TOKEN_FOR_DEMAECAN,
    params,
  };
}

export function getUberEatsLoginUrl(params) {
  return {
    type: GET_UBEREATS_LOGIN_URL,
    params,
  };
}

export function getSquareLoginUrl(params) {
  return {
    type: GET_SQUARE_LOGIN_URL,
    params,
  };
}

export function getAvailableUberEatsStore(params) {
  return {
    type: GET_AVAILABLE_UBEREATS_STORES,
    params,
  };
}

export function changeUberEatsStore(selectedUberEatsStore) {
  return {
    type: CHANGE_UBEREATS_STORE,
    selectedUberEatsStore,
  };
}

export function changeSquareStore(selectedSquareStore) {
  return {
    type: CHANGE_SQUARE_STORE,
    selectedSquareStore,
  };
}

export function getAvailableSquareStores(params) {
  return {
    type: GET_AVAILABLE_SQUARE_STORES,
    params,
  };
}

export function uploadCSV(params) {
  return {
    type: UPLOAD_CSV,
    params,
  };
}

export function getSettings(params) {
  return {
    type: GET_SETTINGS,
    params,
  };
}

// CHANGE_SETTINGS

export function changeSettings(payload) {
  return {
    type: CHANGE_SETTINGS,
    payload,
  };
}

// for new aggregator component

export function loadList(meta) {
  return {
    type: LOAD_LIST,
    meta,
  };
}

export function editField(params) {
  return {
    type: EDIT_FIELD,
    params,
  };
}

// CREATE_SETTINGS
export function createSettings(params) {
  return {
    type: CREATE_SETTINGS,
    params,
  };
}

export function updateSettings(params) {
  return {
    type: UPDATE_SETTINGS,
    params,
  };
}

export function deleteSettings(params) {
  return {
    type: DELETE_SETTINGS,
    params,
  };
}

export function resetFields(params) {
  return {
    type: RESET_FIELDS,
    params,
  };
}
