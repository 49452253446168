/*
 *
 * Auth actions
 *
 */

import {
  AUTH_REQUESTED,
  CHANGE_PASSWORD,
  CHANGE_USER,
  AUTH_SUCCESS,
  AUTH_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_DONE,
  RE_LOGIN_REQUESTED,
  AUTH_REQUESTED_BY_SOCIAL_SIGN_IN,
} from './constants';

export function submitAuthRequest() {
  return {
    type: AUTH_REQUESTED,
  };
}

/**
 * Sets the form state
 * @param  {object} newFormState          The new state of the form
 * @param  {string} newFormState.username The new text of the username input field of the form
 * @param  {string} newFormState.password The new text of the password input field of the form
 */
export function changeUsername(username) {
  return {
    type: CHANGE_USER,
    username,
  };
}

/**
 * Sets the form state
 * @param  {object} newFormState          The new state of the form
 * @param  {string} newFormState.username The new text of the username input field of the form
 * @param  {string} newFormState.password The new text of the password input field of the form
 */
export function changePassword(password) {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
}

export function userAuthenticated(token) {
  return {
    type: AUTH_SUCCESS,
    token,
  };
}

export function authenticationFailed(err) {
  return {
    type: AUTH_FAILED,
    loginError: err,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_REQUESTED,
  };
}

export function logoutDone() {
  return {
    type: LOGOUT_DONE,
  };
}

export function reLogin() {
  return {
    type: RE_LOGIN_REQUESTED,
  };
}

export function submitAuthRequestBySocialSignIn(params) {
  return {
    type: AUTH_REQUESTED_BY_SOCIAL_SIGN_IN,
    params,
  };
}
