import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { isEmpty, isFunction, printf } from '../../utils';
import makeRequest from '../../utils/api';
import {
  DELETE_PROVIDER,
  DELETE_PROVIDER_FAILED,
  DELETE_PROVIDER_SUCCESS,
  GET_PROVIDER_CRED,
  GET_PROVIDER_CRED_FAILED,
  GET_PROVIDER_CRED_SUCCESS,
  LOAD_BRANDS,
  LOAD_BRANDS_FILTER,
  LOAD_BRANDS_FILTER_SUCCESS,
  LOAD_BRANDS_SUCCESS,
  LOAD_BRAND_BY_ID,
  LOAD_BUSINESS,
  LOAD_BUSINESS_SUCCESS,
  LOAD_LOCATION,
  LOAD_LOCATION_BY_ID,
  LOAD_LOCATION_BY_ID_FAILED,
  LOAD_LOCATION_BY_ID_SUCCESS,
  LOAD_LOCATION_FAILED,
  LOAD_LOCATION_SUCCESS,
  LOAD_PROVIDERS,
  LOAD_PROVIDER_FAILED,
  LOAD_PROVIDER_SUCCESS,
  LOAD_TIED_PROVIDERS,
  LOAD_TIED_PROVIDER_SUCCESS,
  LOCATION_SELECTED,
  SELECTED_BRAND,
  UPDATE_BRAND_STATUS,
  UPDATE_BRAND_STATUS_FAILED,
  UPDATE_BRAND_STATUS_SUCCESS,
  UPDATE_PROVIDER_CRED,
  UPDATE_PROVIDER_CRED_FAILED,
  UPDATE_PROVIDER_CRED_SUCCESS,
  SEND_OTP_REQUEST_FOR_GOFOOD_SUCCESS,
  SEND_OTP_REQUEST_FOR_GOFOOD_FAILED,
  SEND_OTP_REQUEST_FOR_GOFOOD,
  VERIFY_OTP_TOKEN_FOR_GOFOOD_SUCCESS,
  VERIFY_OTP_TOKEN_FOR_GOFOOD_FAILED,
  VERIFY_OTP_TOKEN_FOR_GOFOOD,
  LOGIN_SHOPEE_CREDENTIALS_SUCCESS,
  LOGIN_SHOPEE_CREDENTIALS_FAILED,
  LOGIN_SHOPEE_CREDENTIALS,
  VERIFY_OTP_TOKEN_SHOPEE_SUCCESS,
  VERIFY_OTP_TOKEN_SHOPEE_FAILED,
  VERIFY_OTP_TOKEN_SHOPEE,
  GET_UBEREATS_LOGIN_URL,
  GET_UBEREATS_LOGIN_URL_SUCCESS,
  GET_UBEREATS_LOGIN_URL_FAILED,
  GET_AVAILABLE_UBEREATS_STORES,
  GET_AVAILABLE_UBEREATS_STORES_SUCCESS,
  GET_AVAILABLE_UBEREATS_STORES_FAILED,
  GET_SQUARE_LOGIN_URL,
  GET_SQUARE_LOGIN_URL_SUCCESS,
  GET_SQUARE_LOGIN_URL_FAILED,
  GET_AVAILABLE_SQUARE_STORES,
  GET_AVAILABLE_SQUARE_STORES_SUCCESS,
  GET_AVAILABLE_SQUARE_STORES_FAILED,
  UPLOAD_CSV,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILED,
  VERIFY_OTP_TOKEN_FOR_DEMAECAN_SUCCESS,
  VERIFY_OTP_TOKEN_FOR_DEMAECAN_FAILED,
  VERIFY_OTP_TOKEN_FOR_DEMAECAN,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  GET_SETTINGS,
  LOAD_LIST_SUCCESS,
  EDIT_FIELD,
  LOAD_LIST_FAILED,
  LOAD_LIST,
  CREATE_SETTINGS,
  SETTINGS_RESOLVED,
  UPDATE_SETTINGS,
  DELETE_SETTINGS,
  SETTINGS_REJECTED,
} from './constants';
import {
  makeSelectBrandsWithStatus,
  makeSelectProviderCred,
  makeSelectSelectedBrand,
  makeSelectSelectedBusiness,
  makeSelectSelectedLocation,
} from './selectors';
import { DEFAULT_ALL_PAGE_SIZE, DEFAULT_PAGE_NO } from '../../config';
import {
  DELETE_FAILED_NO_INTERNET_MSG,
  LOAD_FAILED_NO_INTERNET_MSG,
  LOGIN_FAILED_NO_INTERNET_MSG,
  SEND_FAILED_NO_INTERNET_MSG,
  UPDATE_FAILED_NO_INTERNET_MSG,
} from '../../config/constants/errorMessages';
import { downloadCsvFile } from '../../utils/sheetUtils';
import { showErrMsg } from '../../utils/validationSchema';

function* getLocation(meta) {
  try {
    const payload = {
      url: 'v1/branch',
      method: 'get',
      params: meta.params,
    };

    const locations = yield call(makeRequest, payload);

    yield put({
      type: LOAD_LOCATION_SUCCESS,
      locations: {
        list: locations.data.results,
        total: locations.data.total,
        page: locations.data.page,
        size: locations.data.size,
      },
    });
  } catch (err) {
    yield put({ type: LOAD_LOCATION_FAILED, err });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Locations'));
  }
}

function* getLocationById(meta) {
  const selectedBiz = yield select(makeSelectSelectedBusiness());
  try {
    const bizId = selectedBiz ? selectedBiz.id : null;
    const params = {
      page: DEFAULT_PAGE_NO,
      size: DEFAULT_ALL_PAGE_SIZE,
      filterByBusiness: bizId,
      filterByBranch: meta.locationId,
    };
    const payload = {
      url: `v1/branch/${meta.locationId}`,
      method: 'get',
    };
    const payloadForBrandFetching = {
      url: 'v1/brand',
      method: 'get',
      params,
    };

    const loc = yield call(makeRequest, payload);

    const locationSelectedPayload = {
      type: LOCATION_SELECTED,
      selectedLocation: loc.data,
      brandsListWithStatus: [],
      locationWiseBrands: [],
    };

    if (loc.data && loc.data.brand_ids && loc.data.brand_ids.length > 0) {
      const payloadForStatusChecking = {
        url: '/v1/brand/branch/online',
        method: 'post',
        data: {
          brand_ids: loc.data.brand_ids,
          branch_id: loc.data.id,
        },
      };
      const brandsStatus = yield call(makeRequest, payloadForStatusChecking);
      const brands = yield call(makeRequest, payloadForBrandFetching);
      const allBrands = brands.data.results;

      const { brand_ids: brandIds } = loc.data;
      const filteredBrands = allBrands.length
        ? allBrands.filter(b => brandIds.indexOf(b.id) > -1)
        : [];

      if (filteredBrands.length) {
        locationSelectedPayload.locationWiseBrands = filteredBrands;
      }

      if (brandsStatus && brandsStatus.data && brandsStatus.data.length > 0) {
        locationSelectedPayload.brandsListWithStatus = brandsStatus.data;
      }
    }

    yield put(locationSelectedPayload);
    yield put({ type: LOAD_LOCATION_BY_ID_SUCCESS });
  } catch (err) {
    yield put({ type: LOAD_LOCATION_BY_ID_FAILED, err });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Location'));
  }
}

function* getBrand(meta) {
  try {
    const payload = {
      url: 'v1/brand',
      method: 'get',
      params: meta.params,
    };
    const brands = yield call(makeRequest, payload);

    yield put({
      type: LOAD_BRANDS_SUCCESS,
      brands: brands.data.results,
    });
  } catch (err) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Brand'));
  }
}

function* updateBrandStatus() {
  const selectedBrand = yield select(makeSelectSelectedBrand());
  const brandWithStatus = yield select(makeSelectBrandsWithStatus());
  const brandId = (selectedBrand && selectedBrand.id) || null;

  const brandData = brandWithStatus.find(b => b.brand_id === brandId);
  try {
    const payload = {
      url: 'v1/brand/online',
      method: 'patch',
      data: {
        brand_ids: [brandData.brand_id],
        branch_id: brandData.branch_id,
        online: !brandData.online,
      },
    };

    yield call(makeRequest, payload);
    yield put({ type: UPDATE_BRAND_STATUS_SUCCESS });
    yield put({ type: LOAD_LOCATION_BY_ID, locationId: brandData.branch_id });
    toast.success('Brand Status updated successfully');
  } catch (error) {
    const { data } = error.response || {};
    const { message } = data || {};
    toast.error(
      message || printf(UPDATE_FAILED_NO_INTERNET_MSG, 'Brand status'),
    );
    yield put({ type: UPDATE_BRAND_STATUS_FAILED });
  }
}

function* getBrandById(meta) {
  try {
    const payload = {
      url: `v1/brand/${meta.brandId}`,
      method: 'get',
    };

    const result = yield call(makeRequest, payload);

    yield put({ type: SELECTED_BRAND, brand: result.data });
  } catch (error) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Brand'));
  }
}

function* getBusinesses(meta) {
  try {
    const params = { ...meta.params };

    const payload = {
      url: 'v1/business',
      method: 'get',
      params,
    };

    const biz = yield call(makeRequest, payload);

    yield put({
      type: LOAD_BUSINESS_SUCCESS,
      businesses: biz.data.results,
    });
  } catch (err) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Business'));
  }
}

function* getBrandsFilter(meta) {
  try {
    const params = { ...meta.params };

    const payload = {
      url: 'v1/brand',
      method: 'get',
      params,
    };

    const brands = yield call(makeRequest, payload);

    yield put({
      type: LOAD_BRANDS_FILTER_SUCCESS,
      brandsFilter: brands.data.results,
    });
  } catch (err) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Brand'));
  }
}

function* getProviders(meta) {
  try {
    const payload = {
      url: 'v1/provider',
      method: 'get',
      params: meta.params,
    };
    const providerData = yield call(makeRequest, payload);
    yield put({ type: LOAD_PROVIDER_SUCCESS, providers: providerData.data });
  } catch (error) {
    yield put({ type: LOAD_PROVIDER_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Aggregator'));
  }
}

function* getTiedProviders(meta) {
  const { branchId } = meta.params || {};
  try {
    const payload = {
      url: `v1/brand-provider/branch/${branchId}`,
      method: 'get',
    };

    const tiedProviderRes = yield call(makeRequest, payload);
    yield put({
      type: LOAD_TIED_PROVIDER_SUCCESS,
      tiedProviders: tiedProviderRes.data,
    });
  } catch (error) {
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Tied Providers'));
  }
}

function* getProviderCred({ params }) {
  const requestURL = `v1/brand-provider/brand/${params.brandId}/branch/${params.branchId}/provider/${params.providerId}`;
  try {
    const payload = {
      url: requestURL,
      method: 'get',
    };

    const branchProvider = yield call(makeRequest, payload);
    yield put({
      type: GET_PROVIDER_CRED_SUCCESS,
      providerCred: branchProvider.data,
    });
  } catch (err) {
    yield put({ type: GET_PROVIDER_CRED_FAILED });
    const { data, status } = err.response || {};
    const { message } = data || {};

    const errMsg = message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Provider');
    toast.error(errMsg);

    if (status === 404) {
      yield put({
        type: GET_PROVIDER_CRED_SUCCESS,
        providerCred: null,
      });
    }
  }
}

function* updateProviderCred(meta) {
  const providerCred = yield select(makeSelectProviderCred());
  const selectedBranch = yield select(makeSelectSelectedLocation());
  const sBranchId =
    selectedBranch && selectedBranch.id ? selectedBranch.id : null;
  const shouldCreate = isEmpty(providerCred);

  try {
    const base = 'v1/brand-provider';
    const url = shouldCreate ? `${base}` : `${base}/${providerCred.id}`;
    const method = shouldCreate ? 'POST' : 'PATCH';

    const toastMsg = `Partner ${
      shouldCreate ? 'created' : 'updated'
    } successfully`;

    const params = {
      url,
      method,
      data: meta.providerCred.data || meta.params.providerCred.data,
    };

    const providerRes = yield call(makeRequest, params);
    const { client_secret: clientSecret, ...rest } = providerRes.data || {};
    const providerCredData = shouldCreate
      ? { ...meta.providerCred.data, ...rest }
      : { ...providerCred, ...providerRes.data };

    const goFoodLoginRequired =
      providerRes.data &&
      providerRes.data.metadata &&
      providerRes.data.metadata.login_required;
    const goFoodLoginUrl =
      providerRes.data &&
      providerRes.data.metadata &&
      providerRes.data.metadata.login_url;

    yield put({
      type: UPDATE_PROVIDER_CRED_SUCCESS,
      providerCred: {
        ...providerCredData,
        goFoodLoginRequired,
        goFoodLoginUrl,
      },
    });
    yield put({ type: LOAD_TIED_PROVIDERS, params: { branchId: sBranchId } });
    toast.success(toastMsg);
  } catch (err) {
    yield put({ type: UPDATE_PROVIDER_CRED_FAILED });
    const { data } = err.response || {};
    const { message, validation_error } = data || {};
    if (message) {
      toast.error(message);
    } else if (validation_error) {
      const keys = Object.keys(validation_error);
      toast.error(keys.map(k => `${k} ${validation_error[k]}`).join(', '));
    } else {
      toast.error(printf(UPDATE_FAILED_NO_INTERNET_MSG, 'Aggregator'));
    }
  }
}

function* deleteProvider() {
  const providerCred = yield select(makeSelectProviderCred());
  const selectedBranch = yield select(makeSelectSelectedLocation());
  const sBranchId =
    selectedBranch && selectedBranch.id ? selectedBranch.id : null;

  try {
    const url = `v1/brand-provider/${providerCred && providerCred.id}`;

    const params = {
      url,
      method: 'DELETE',
    };

    yield call(makeRequest, params);

    yield put({
      type: DELETE_PROVIDER_SUCCESS,
    });
    yield put({ type: LOAD_TIED_PROVIDERS, params: { branchId: sBranchId } });
    toast.success('Aggregator detached successfully');
  } catch (err) {
    const { message } = err.response.data || {};
    yield put({ type: DELETE_PROVIDER_FAILED });
    toast.error(message || printf(DELETE_FAILED_NO_INTERNET_MSG, 'Aggregator'));
  }
}

function* sendOTPRequestForGoFood(meta) {
  try {
    const payload = {
      url: 'v1/gofood/interceptor/login',
      method: 'POST',
      data: meta.params,
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: SEND_OTP_REQUEST_FOR_GOFOOD_SUCCESS,
      otpTokenForGoFood:
        response.data && response.data.data && response.data.data.otp_token,
    });
  } catch (error) {
    yield put({
      type: SEND_OTP_REQUEST_FOR_GOFOOD_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || printf(SEND_FAILED_NO_INTERNET_MSG, 'OTP'));
  }
}

function* verifyOTPTokenForGoFood(meta) {
  try {
    const payload = {
      url: 'v1/gofood/interceptor/token',
      method: 'POST',
      data: meta.params.payload,
    };

    yield call(makeRequest, payload);
    yield put({
      type: VERIFY_OTP_TOKEN_FOR_GOFOOD_SUCCESS,
    });
    yield put({
      type: UPDATE_PROVIDER_CRED,
      providerCred: { data: meta.params.data },
    });
  } catch (error) {
    yield put({
      type: VERIFY_OTP_TOKEN_FOR_GOFOOD_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || 'This credential is already tied in another store');
  }
}

function* verifyOTPTokenForShopee(meta) {
  try {
    const payload = {
      url: 'v1/shopee/interceptor/otp',
      method: 'POST',
      data: meta.params.payload,
    };

    yield call(makeRequest, payload);
    yield put({
      type: VERIFY_OTP_TOKEN_SHOPEE_SUCCESS,
    });
    yield put({
      type: UPDATE_PROVIDER_CRED,
      providerCred: { data: meta.params.data },
    });
  } catch (error) {
    yield put({
      type: VERIFY_OTP_TOKEN_SHOPEE_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || 'This credential is already tied in another store');
  }
}

function* verifyOTPTokenForDemaecan(meta) {
  try {
    const payload = {
      url: 'v1/demaecan/interceptor/login',
      method: 'POST',
      data: meta.params.payload,
    };

    yield call(makeRequest, payload);
    yield put({
      type: VERIFY_OTP_TOKEN_FOR_DEMAECAN_SUCCESS,
    });
    yield put({
      type: UPDATE_PROVIDER_CRED,
      providerCred: { data: meta.params.data },
    });
  } catch (error) {
    yield put({
      type: VERIFY_OTP_TOKEN_FOR_DEMAECAN_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || 'This credential is already tied in another store');
  }
}

function* loginShopeeCredentials(meta) {
  try {
    const payload = {
      url: 'v1/shopee/interceptor/login',
      method: 'POST',
      data: meta.params,
    };

    const res = yield call(makeRequest, payload);
    yield put({
      type: LOGIN_SHOPEE_CREDENTIALS_SUCCESS,
      shopeeCredentials: res && res.data,
    });
    toast.success(`Successfully logged in with Shopee credentials`);
  } catch (error) {
    yield put({
      type: LOGIN_SHOPEE_CREDENTIALS_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || printf(LOGIN_FAILED_NO_INTERNET_MSG, 'Shopee'));
  }
}

function* getUberEatsLoginUrl(meta) {
  const { brandId = null, branchId = null } = meta.params;
  try {
    const payload = {
      url: 'v1/klikber/store',
      method: 'POST',
      data: {
        brand_id: brandId,
        branch_id: branchId,
      },
    };

    const res = yield call(makeRequest, payload);
    yield put({
      type: GET_UBEREATS_LOGIN_URL_SUCCESS,
      uberEatsLoginData: res?.data,
    });
  } catch (error) {
    yield put({
      type: GET_UBEREATS_LOGIN_URL_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || printf(LOGIN_FAILED_NO_INTERNET_MSG, 'UberEats'));
  }
}

function* getAvailableUberEatsStores(meta) {
  const { brandId = null, branchId = null, isTied = false } = meta.params || {};
  const requestURL = `v1/klikber/stores/${branchId}?brand_id=${brandId}`;
  try {
    const payload = {
      url: requestURL,
      method: 'get',
    };

    const ueStores = yield call(makeRequest, payload);

    yield put({
      type: GET_AVAILABLE_UBEREATS_STORES_SUCCESS,
      availableUberEatsStores: ueStores?.data,
    });
  } catch (err) {
    yield put({ type: GET_AVAILABLE_UBEREATS_STORES_FAILED });
    const { data } = err.response || {};
    const { message } = data || {};

    const errMsg =
      message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'UberEats stores');
    if (isTied) {
      toast.error(errMsg);
    }
  }
}

function* getSquareLoginUrl(meta) {
  const { brandId = null, branchId = null } = meta.params;
  try {
    const payload = {
      url: '/v1/squareit/store',
      method: 'POST',
      data: {
        brand_id: brandId,
        branch_id: branchId,
      },
    };

    const res = yield call(makeRequest, payload);
    yield put({
      type: GET_SQUARE_LOGIN_URL_SUCCESS,
      squareLoginUrl: res?.data?.login_url,
    });
  } catch (error) {
    yield put({
      type: GET_SQUARE_LOGIN_URL_FAILED,
    });
    const { message } = error.response.data || {};
    toast.error(message || printf(LOGIN_FAILED_NO_INTERNET_MSG, 'Square'));
  }
}

function* getAvailableSquareStores(meta) {
  const { brandId = null, branchId = null, isTied = false } = meta.params || {};
  const requestURL = `v1/squareit/stores/${branchId}?brand_id=${brandId}`;
  try {
    const payload = {
      url: requestURL,
      method: 'get',
    };

    const squareStores = yield call(makeRequest, payload);

    yield put({
      type: GET_AVAILABLE_SQUARE_STORES_SUCCESS,
      availableSquareStores: squareStores?.data,
    });
  } catch (err) {
    yield put({ type: GET_AVAILABLE_SQUARE_STORES_FAILED });
    const { data } = err.response || {};
    const { message } = data || {};

    const errMsg =
      message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Square stores');
    if (isTied) {
      toast.error(errMsg);
    }
  }
}

function* uploadCSV(meta) {
  const { params: metaParams } = meta || {};
  const { url, params, reducerKey } = metaParams || {};

  const { data, headers, timeout, aggregatorTitle } = params || {};

  try {
    const payload = {
      url,
      method: 'post',
      data,
      headers,
      timeout,
    };
    const resp = yield call(makeRequest, payload);

    yield put({
      type: UPLOAD_CSV_SUCCESS,
      payload: {
        key: reducerKey,
      },
    });
    // DISCARD FILE DOWNLOAD IF STATUS IS 202
    if (resp.status === 202) {
      toast.success(resp.data.message);
      return;
    }
    toast.success('CSV file uploaded successfully');
    downloadCsvFile(
      resp.data,
      `${aggregatorTitle}-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  } catch (err) {
    yield put({
      type: UPLOAD_CSV_FAILED,
      payload: {
        key: reducerKey,
      },
    });
    showErrMsg(err);
  }
}

function* getSettings({ params }) {
  const {
    url,
    reducerKey,
    callbackFn,
    data,
    successMsg = '',
    failCallbackFn,
    showErr = true,
  } = params || {};

  try {
    const payload = {
      url,
      method: 'GET',
    };
    if (data) {
      payload.data = data;
      payload.method = 'POST';
    } else if (params.params) {
      payload.params = params?.params;
    }
    const res = yield call(makeRequest, payload);

    yield put({
      type: GET_SETTINGS_SUCCESS,
      payload: {
        key: reducerKey,
        value: res.data,
      },
    });

    if (!isEmpty(successMsg)) {
      toast.success(successMsg);
    }

    if (isFunction(callbackFn)) {
      callbackFn(res.data);
    }
  } catch (err) {
    yield put({
      type: GET_SETTINGS_FAILED,
      payload: {
        key: reducerKey,
      },
    });
    if (isFunction(failCallbackFn)) {
      failCallbackFn();
    }
    if (showErr) {
      showErrMsg(err);
    }
  }
}

function* getList(req) {
  const {
    reducerKey,
    url,
    method,
    params,
    isPaginated = true,
    callbackFn,
    data,
    showErr = true,
  } = req.meta || {};

  try {
    const payload = {
      url,
      method,
    };

    // In aggregators, some endpoints require data in the request body
    // Which is post method but works as get method
    if (params) {
      payload.params = params;
    }
    if (data) {
      payload.data = data;
      payload.method = 'POST';
    }
    const res = yield call(makeRequest, payload);
    const list = res.data.results || res.data || [];

    const successReducerPayload = {
      [reducerKey]: list,
    };

    if (isPaginated) {
      successReducerPayload.total = res.data.total;
      successReducerPayload.page = res.data.page;
      successReducerPayload.size = res.data.size;
    }

    yield put({
      type: LOAD_LIST_SUCCESS,
    });

    yield put({
      type: EDIT_FIELD,
      params: successReducerPayload,
    });

    if (isFunction(callbackFn)) {
      callbackFn(list, res);
    }
  } catch (err) {
    yield put({
      type: LOAD_LIST_FAILED,
    });
    yield put({
      type: EDIT_FIELD,
      params: {
        [reducerKey]: [],
      },
    });
    if (showErr) {
      const { message } = err?.response?.data || {};
      toast.error(message || `Failed to load ${reducerKey}`);
    }
  }
}

function* createSettings({ params }) {
  const {
    url,
    data,
    callbackFn,
    successMsg = 'Settings created successfully',
  } = params || {};

  try {
    const payload = {
      url,
      method: 'POST',
      data,
    };
    const res = yield call(makeRequest, payload);

    yield put({
      type: SETTINGS_RESOLVED,
    });

    if (isFunction(callbackFn)) {
      callbackFn(res);
    }
    toast.success(res?.data?.message || successMsg);
  } catch (err) {
    yield put({
      type: SETTINGS_REJECTED,
    });
    showErrMsg(err);
  }
}

function* updateSettings({ params }) {
  const {
    url,
    data,
    callbackFn,
    isPatch = false,
    successMsg = 'Settings updated successfully',
  } = params || {};
  try {
    const payload = {
      url,
      method: 'PUT',
      data,
    };
    if (isPatch) {
      payload.method = 'PATCH';
    }
    const res = yield call(makeRequest, payload);
    if (isFunction(callbackFn)) {
      callbackFn(res);
    }
    yield put({
      type: SETTINGS_RESOLVED,
    });
    toast.success(successMsg);
  } catch (err) {
    yield put({
      type: SETTINGS_REJECTED,
    });
    if (isFunction(callbackFn)) {
      callbackFn({ data });
    }
    showErrMsg(err);
  }
}

function* deleteSettings(meta) {
  const { params: metaParams } = meta || {};
  const {
    url,
    params,
    callbackFn,
    successMsg = 'Settings deleted successfully',
  } = metaParams || {};

  try {
    const payload = {
      url,
      method: 'DELETE',
      params,
    };
    yield call(makeRequest, payload);

    if (isFunction(callbackFn)) {
      callbackFn();
    }
    yield put({
      type: SETTINGS_RESOLVED,
    });
    toast.success(successMsg);
  } catch (err) {
    if (isFunction(callbackFn)) {
      callbackFn();
    }
    yield put({
      type: SETTINGS_REJECTED,
    });
    showErrMsg(err);
  }
}

export default function* locationSaga() {
  yield takeLatest(LOAD_LOCATION, getLocation);
  yield takeLatest(LOAD_LOCATION_BY_ID, getLocationById);
  yield takeLatest(LOAD_BRANDS, getBrand);
  yield takeLatest(UPDATE_BRAND_STATUS, updateBrandStatus);
  yield takeLatest(LOAD_BRAND_BY_ID, getBrandById);
  yield takeLatest(LOAD_BUSINESS, getBusinesses);
  yield takeLatest(LOAD_BRANDS_FILTER, getBrandsFilter);
  yield takeLatest(LOAD_PROVIDERS, getProviders);
  yield takeLatest(LOAD_TIED_PROVIDERS, getTiedProviders);
  yield takeLatest(GET_PROVIDER_CRED, getProviderCred);
  yield takeLatest(UPDATE_PROVIDER_CRED, updateProviderCred);
  yield takeLatest(DELETE_PROVIDER, deleteProvider);
  yield takeLatest(SEND_OTP_REQUEST_FOR_GOFOOD, sendOTPRequestForGoFood);
  yield takeLatest(VERIFY_OTP_TOKEN_FOR_GOFOOD, verifyOTPTokenForGoFood);
  yield takeLatest(VERIFY_OTP_TOKEN_FOR_DEMAECAN, verifyOTPTokenForDemaecan);
  yield takeLatest(LOGIN_SHOPEE_CREDENTIALS, loginShopeeCredentials);
  yield takeLatest(VERIFY_OTP_TOKEN_SHOPEE, verifyOTPTokenForShopee);
  yield takeLatest(GET_UBEREATS_LOGIN_URL, getUberEatsLoginUrl);
  yield takeLatest(GET_AVAILABLE_UBEREATS_STORES, getAvailableUberEatsStores);
  yield takeLatest(GET_SQUARE_LOGIN_URL, getSquareLoginUrl);
  yield takeLatest(GET_AVAILABLE_SQUARE_STORES, getAvailableSquareStores);
  yield takeLatest(UPLOAD_CSV, uploadCSV);
  yield takeEvery(GET_SETTINGS, getSettings);
  yield takeEvery(LOAD_LIST, getList);
  yield takeEvery(CREATE_SETTINGS, createSettings);
  yield takeEvery(UPDATE_SETTINGS, updateSettings);
  yield takeEvery(DELETE_SETTINGS, deleteSettings);
}
