/*
 *
 * Auth constants
 *
 */

export const AUTH_REQUESTED = 'app/Auth/AUTH_REQUESTED';
export const CHANGE_USER = 'app/Auth/CHANGE_USER';
export const CHANGE_PASSWORD = 'app/Auth/CHANGE_PASSWORD';
export const AUTH_SUCCESS = 'app/Auth/AUTH_SUCCESS';
export const AUTH_FAILED = 'app/Auth/AUTH_FAILED';
export const LOGOUT_REQUESTED = 'app/Auth/LOGOUT_REQUESTED';
export const LOGOUT_DONE = 'app/Auth/LOGOUT_DONE';
export const RE_LOGIN_REQUESTED = 'app/Auth/RE_LOGIN_REQUESTED';
export const AUTH_REQUESTED_BY_SOCIAL_SIGN_IN =
  'app/Auth/SOCIAL_AUTH_REQUESTED';
