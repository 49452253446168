/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import './utils/initPolyfills';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
// import '!file-loader?name=[name].[ext]!./images/klikit_admin_icon.ico';
// import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import App from './containers/App';
import store from './stores';
import { setupInterceptor } from './utils/api';
// Import sentry packages
import { SENTRY_DSN_URL, SENTRY_ENV } from './config/env';

// sentry initialization
Sentry.init({
  environment: SENTRY_ENV,
  dsn: SENTRY_DSN_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  debug: SENTRY_ENV === 'development',
});

// Create redux store with history
const MOUNT_NODE = document.getElementById('app');
setupInterceptor(store);

const appRoot = createRoot(MOUNT_NODE);
const appEl = (
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <App />
      <ToastContainer theme="colored" />
    </DndProvider>
  </Provider>
);
appRoot.render(appEl);
