import { takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  AUTH_REQUESTED,
  AUTH_REQUESTED_BY_SOCIAL_SIGN_IN,
  RE_LOGIN_REQUESTED,
} from './constants';
import {
  userAuthenticated,
  authenticationFailed,
  changeUsername,
  changePassword,
} from './actions';

import { makeSelectUserName, makeSelectPassword } from './selectors';
import { makeSelectRefreshToken } from '../App/selectors';
import makeRequest from '../../utils/api';
import history from '../../utils/history';
import { CONNECTION_INTERREPED_MSG } from '../../config/constants/errorMessages';

// import { fixCasesOfText } from '../../utils/stringOperations';

function* getAuthUser() {
  // Select username from store
  const username = yield select(makeSelectUserName());
  const password = yield select(makeSelectPassword());
  if (!username || !password) {
    return;
  }
  const requestURL = `v1/login`;

  try {
    // Call our request helper (see '../../utils/request')
    const params = {
      url: requestURL,
      method: 'post',
      data: { email: username, password, admin: true },
    };
    const token = yield call(makeRequest, params);
    yield put(changeUsername(''));
    yield put(changePassword(''));
    yield put(userAuthenticated(token.data));
    // forwardTo('/oni');
  } catch (err) {
    yield put(authenticationFailed('Authentication Failed'));
    const { data } = err.response || {};
    if (data && data.message && data.message.length > 0) {
      toast.error(data.message);
    } else {
      toast.error(CONNECTION_INTERREPED_MSG);
    }
  }
}

function* getTokenByRefreshToken() {
  const refreshToken = yield select(makeSelectRefreshToken());
  const params = {
    url: 'v1/token/refresh',
    method: 'post',
    data: { refresh_token: refreshToken },
  };
  try {
    const token = yield call(makeRequest, params);
    yield put(userAuthenticated(token.data));
    // forwardTo('/');
  } catch (err) {
    yield put(authenticationFailed('Authentication Failed'));
    const { data } = err.response || {};
    if (data.message && data.message.length > 0) {
      toast.error(data.message);
    } else {
      toast.error(CONNECTION_INTERREPED_MSG);
    }
    // forwardTo('/');
  }
}

function* getAuthUserBySocialSignIn(meta) {
  const { token, loginProvider } = meta.params || {};
  try {
    const requestParams = {
      url: '/v1/login/social',
      method: 'post',
      data: { login_provider: loginProvider, token },
    };
    const responseData = yield call(makeRequest, requestParams);
    yield put(changeUsername(''));
    yield put(changePassword(''));
    yield put(userAuthenticated(responseData.data));
    // forwardTo('/oni');
  } catch (err) {
    yield put(authenticationFailed('Authentication Failed'));
    const { data } = err.response || {};
    if (data && data.message && data.message.length > 0) {
      toast.error(data.message);
      // toast.error(data.message);
    } else {
      toast.error('Unable to SignIn!');
    }
  }
}

// Individual exports for testing
export default function* authSaga() {
  yield takeLatest(AUTH_REQUESTED, getAuthUser);
  yield takeLatest(RE_LOGIN_REQUESTED, getTokenByRefreshToken);
  yield takeLatest(AUTH_REQUESTED_BY_SOCIAL_SIGN_IN, getAuthUserBySocialSignIn);
  // See example in containers/HomePage/saga.js
}

// Little helper function to abstract going to different pages
function forwardTo(location) {
  history.push(location);
}
