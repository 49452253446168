import React from 'react';
import Loadable from './components/Loadable';
import {
  ACTIVITIES_ROUTE,
  ADD_ORDER_CONFIG_PAYMENT_METHOD,
  ADD_ORDER_CONFIG_SETTINGS,
  ADD_RESERVATION_ROUTE,
  ADMINS_ROUTE,
  ANALYTICS_ROUTE,
  BRANCH_ROUTE,
  BRANDS_ROUTE,
  BUSINESS_ROUTE,
  CAMPAIGN_ROUTE,
  CAMPAIGN_ROUTE_ADD,
  CUSTOMER_DETAIL_ROUTE,
  CUSTOMER_LIST_ROUTE,
  FEATURE_ACCESS_ROUTE,
  HOME_ROUTE,
  IMAGE_MANAGER_ROUTE,
  INTEGRATION_ROUTE,
  INTEGRATION_STATUS_ROUTE,
  LANG_EXCEL_TO_JSON_ROUTE,
  LINKS_IN_BIO_ROUTE,
  LIVE_MONITOR_AUTO_READY_ROUTE,
  LIVE_MONITOR_CATEGORY_OOS_LIST_ROUTE,
  LIVE_MONITOR_INTEGRATION_STATUS_ROUTE,
  LIVE_MONITOR_ITEM_OOS_LIST_ROUTE,
  LIVE_MONITOR_MENU_OOS_LIST_ROUTE,
  LIVE_MONITOR_PAUSED_STORES_ROUTE,
  LIVE_MONITOR_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  LOYALTY_ROUTE,
  MENU_ITEMS_ROUTE,
  MENU_PULLING_ROUTE,
  MENU_ROUTE,
  MENU_V2_ROUTE,
  MODIFIERS_ROUTE,
  ONI_ROUTE,
  PAYMENT_INTEGRATION_ROUTE,
  PERMISSION_ROUTE,
  PRINTER_SETTINGS_ROUTE,
  PROFILE_PASSWORD_CHANGE_ROUTE,
  PROFILE_ROUTE,
  PROMO_ROUTE,
  PROMO_ROUTE_ADD,
  PROMO_ROUTE_EDIT,
  PROVIDER_INTEGRATION_ROUTE,
  PROVIDER_ROUTE,
  QR_GENERATOR,
  RATING_REVIEW_INTEGRATION_ROUTE,
  RESERVATION_LOCATIONS_ROUTE,
  RESERVATION_ROUTE,
  RESERVATION_SETTINGS_ROUTE,
  ROLE_ROUTE,
  RULES_ROUTE,
  STRIPE_PAYMENT_INTEGRATION_ROUTE,
  USERS_ROUTE,
  WEBHOOKS_ROUTE,
  WEBSHOP_BRAND_CUSTOMIZATION_ROUTE,
  WEBSHOP_BRAND_CUSTOMIZATION_ROUTE_V2,
  WEBSHOP_DELIVERY_SHUTDOWN_ROUTE,
  WEBSHOP_FOODHALL_ROUTE,
  WEBSHOP_NOTICE_ROUTE,
  WEBSHOP_PAYMENT_SETTINGS_ROUTE,
  WEBSHOP_ROUTE,
  WHITELABEL_ROUTE,
} from './config/constants/routes';
import NativeAnalytics from './containers/NativeAnalytics';
import { lazyWithRetry } from './utils/lazyWithRetry';
import { PERMISSIONS } from './utils/permission';
// import Activities from './containers/activities';
// import Login from './containers/Auth';
// import Branch from './containers/Branch';
// import PartnerIntegration from './containers/Branch/PartnerIntegration';
// import Brand from './containers/Brand';
// import Business from './containers/Business';
// import Integration from './containers/Integration';
// import IntegrationStatus from './containers/IntegrationStatus';
// import BrandListByLocation from './containers/Integration/BrandListByLocation';
// import ProviderList from './containers/Integration/ProviderList';
// import Logout from './containers/Logout';
// import Menu from './containers/Menu';
// import ONI from './containers/ONI';
// import OrderDetails from './containers/ONI/OrderDetails';
// import Permission from './containers/Permission';
// import Profile from './containers/Profile';
// import ChangePassword from './containers/Profile/ChangePassword';
// import Role from './containers/Role';
// import Webhooks from './containers/Webhooks';
// import User from './containers/User';
const Login = Loadable(lazyWithRetry(() => import('./containers/Auth')));
const Branch = Loadable(lazyWithRetry(() => import('./containers/Branch')));
const PartnerIntegration = Loadable(
  lazyWithRetry(() => import('./containers/Branch/PartnerIntegration')),
);
const Brand = Loadable(lazyWithRetry(() => import('./containers/Brand')));
const Business = Loadable(lazyWithRetry(() => import('./containers/Business')));

// Integration is now hidden from the routes
// const Integration = Loadable(
//   lazyWithRetry(() => import('./containers/Integration')),
// );

const IntegrationStatus = Loadable(
  lazyWithRetry(() => import('./containers/IntegrationStatus')),
);
const BrandListByLocation = Loadable(
  lazyWithRetry(() => import('./containers/Integration/BrandListByLocation')),
);
const ProviderList = Loadable(
  lazyWithRetry(() => import('./containers/Integration/ProviderList')),
);
const ProviderIntegrationList = Loadable(
  lazyWithRetry(() => import('./containers/ProviderIntegration')),
);
const Logout = Loadable(lazyWithRetry(() => import('./containers/Logout')));
const Menu = Loadable(lazyWithRetry(() => import('./containers/Menu')));
const ONI = Loadable(lazyWithRetry(() => import('./containers/ONI')));
const OrderDetails = Loadable(
  lazyWithRetry(() => import('./containers/ONI/OrderDetails')),
);
const Permission = Loadable(
  lazyWithRetry(() => import('./containers/Permission')),
);
const Profile = Loadable(lazyWithRetry(() => import('./containers/Profile')));
const ChangePassword = Loadable(
  lazyWithRetry(() => import('./containers/Profile/ChangePassword')),
);
const Role = Loadable(lazyWithRetry(() => import('./containers/Role')));
const Webhooks = Loadable(lazyWithRetry(() => import('./containers/Webhooks')));
const User = Loadable(lazyWithRetry(() => import('./containers/User')));
const Customer = Loadable(
  lazyWithRetry(() => import('./containers/Customer/List')),
);
const CustomerDetail = Loadable(
  lazyWithRetry(() => import('./containers/Customer/Detail')),
);
const Activities = Loadable(
  lazyWithRetry(() => import('./containers/ActivityLog')),
);
const LangExcelToJSON = Loadable(
  lazyWithRetry(() => import('./containers/LangExcelToJSON')),
);

const QRGenerator = Loadable(
  lazyWithRetry(() => import('./containers/QRGenerator')),
);

const PrinterSettings = Loadable(
  lazyWithRetry(() => import('./containers/PrinterSettings')),
);
const MenuV2 = Loadable(lazyWithRetry(() => import('./containers/MenuV2')));

const Webshop = Loadable(lazyWithRetry(() => import('./containers/Webshop')));
const Promo = Loadable(lazyWithRetry(() => import('./containers/Promo')));
const PromoAdd = Loadable(
  lazyWithRetry(() => import('./containers/Promo/PromoAddEdit')),
);
const Loyalty = Loadable(lazyWithRetry(() => import('./containers/Loyalty')));

const LinkInBio = Loadable(
  lazyWithRetry(() => import('./containers/LinkInBio')),
);

const Reservation = Loadable(
  lazyWithRetry(() => import('./containers/Reservation')),
);

const AddReservation = Loadable(
  lazyWithRetry(() => import('./containers/Reservation/AddReservation')),
);

const Whitelabel = Loadable(
  lazyWithRetry(() => import('./containers/Whitelabel')),
);

const ImageManager = Loadable(
  lazyWithRetry(() => import('./containers/ImageManager')),
);

const PaymentIntegration = Loadable(
  lazyWithRetry(() => import('./containers/PaymentIntegration')),
);

const RatingAndReview = Loadable(
  lazyWithRetry(() => import('./containers/RatingAndReview')),
);

const LiveMonitor = Loadable(
  lazyWithRetry(() => import('./containers/LiveMonitor')),
);

const LiveMonitorPausedStores = Loadable(
  lazyWithRetry(() =>
    import('./containers/LiveMonitor/PauseStores/PausedStoreListDetails'),
  ),
);

const LiveMonitorIntegrationStatus = Loadable(
  lazyWithRetry(() =>
    import(
      './containers/LiveMonitor/IntegrationStatus/IntegrationStatusListDetails'
    ),
  ),
);

const AutoReadyActivityList = Loadable(
  lazyWithRetry(() =>
    import(
      './containers/LiveMonitor/AutoReadyActivity/AutoReadyActivityDetails'
    ),
  ),
);

const Campaign = Loadable(lazyWithRetry(() => import('./containers/Campaign')));
const CampaignDetails = Loadable(
  lazyWithRetry(() =>
    import('./containers/Campaign/components/CampaignDetails'),
  ),
);
const CampaignForm = Loadable(
  lazyWithRetry(() => import('./containers/Campaign/components/CampaignForm')),
);
const LiveMonitorMenuOOSActivity = Loadable(
  lazyWithRetry(() =>
    import('./containers/LiveMonitor/OOSActivity/MenuOOSList'),
  ),
);
const LiveMonitorCategoryOOSActivity = Loadable(
  lazyWithRetry(() =>
    import('./containers/LiveMonitor/OOSActivity/CategoryOOSList'),
  ),
);
const LiveMonitorItemOOSActivity = Loadable(
  lazyWithRetry(() =>
    import('./containers/LiveMonitor/OOSActivity/ItemOOSList'),
  ),
);
const FeatureAccess = Loadable(
  lazyWithRetry(() => import('./containers/FeatureAccess')),
);
const AddOrderConfig = Loadable(
  lazyWithRetry(() => import('./containers/AddOrderConfig')),
);

const MenuPulling = Loadable(
  lazyWithRetry(() => import('./containers/MenuPulling')),
);

const routes = [
  {
    path: LOGIN_ROUTE,
    main: () => <Login />,
  },
  {
    path: HOME_ROUTE,
    exact: true,
    main: () => <Business />,
  },
  {
    path: LOGOUT_ROUTE,
    main: () => <Logout />,
  },
  {
    path: BUSINESS_ROUTE,
    main: () => <Business />,
  },
  {
    path: BRANDS_ROUTE,
    main: () => <Brand />,
  },
  {
    exact: true,
    path: BRANCH_ROUTE,
    main: () => <Branch />,
  },
  {
    path: USERS_ROUTE,
    main: () => <User />,
  },
  {
    path: ADMINS_ROUTE,
    main: () => <User />,
  },
  {
    path: PERMISSION_ROUTE,
    main: () => <Permission />,
  },
  {
    path: ROLE_ROUTE,
    main: () => <Role />,
  },
  // Currently Integration Page is hidden
  // {
  //   path: '/integration',
  //   exact: true,
  //   main: () => <Integration />,
  // },
  {
    path: ACTIVITIES_ROUTE,
    exact: true,
    main: () => <Activities />,
  },
  {
    path: WEBHOOKS_ROUTE,
    exact: true,
    main: () => <Webhooks />,
  },
  {
    path: INTEGRATION_STATUS_ROUTE,
    exact: true,
    main: () => <IntegrationStatus />,
  },
  {
    path: `${INTEGRATION_ROUTE}/:bizId/:locId`,
    exact: true,
    main: () => <BrandListByLocation />,
  },
  {
    path: MENU_ROUTE,
    main: () => <Menu />,
  },
  {
    path: MENU_ITEMS_ROUTE,
    main: () => <Menu />,
  },
  {
    path: MODIFIERS_ROUTE,
    main: () => <Menu />,
  },
  {
    path: RULES_ROUTE,
    main: () => <Menu />,
  },
  {
    exact: true,
    path: PROFILE_ROUTE,
    main: () => <Profile />,
  },
  {
    path: `${INTEGRATION_ROUTE}/:branchId/:brandId/:providerId/partner-integration`,
    main: () => <PartnerIntegration />,
  },
  {
    path: PROVIDER_ROUTE,
    exact: true,
    main: () => <ProviderList />,
  },
  {
    path: `${PROVIDER_ROUTE}/:businessId/:branchId/:brandId`,
    exact: true,
    main: () => <ProviderList />,
  },
  {
    path: PROVIDER_INTEGRATION_ROUTE,
    exact: true,
    main: () => <ProviderIntegrationList />,
  },
  {
    exact: true,
    path: PROFILE_PASSWORD_CHANGE_ROUTE,
    main: () => <ChangePassword />,
  },
  {
    path: ONI_ROUTE,
    exact: true,
    main: () => <ONI />,
    permission: 'fetch.oni',
  },
  // {
  //   path: '/past-orders',
  //   exact: true,
  //   main: () => <ONI />,
  // },
  // {
  //   path: '/past-orders/:id',
  //   exact: true,
  //   main: () => <OrderDetails />,
  // },
  {
    path: `${ONI_ROUTE}/:id`,
    exact: true,
    main: () => <OrderDetails />,
    permission: 'fetch.oni',
  },
  {
    path: LANG_EXCEL_TO_JSON_ROUTE,
    exact: true,
    main: () => <LangExcelToJSON />,
  },
  {
    path: QR_GENERATOR,
    exact: true,
    main: () => <QRGenerator />,
  },
  {
    path: ANALYTICS_ROUTE,
    main: () => <NativeAnalytics />,
    permission: 'fetch.native.analytics',
  },
  {
    path: PRINTER_SETTINGS_ROUTE,
    exact: true,
    main: () => <PrinterSettings />,
  },
  {
    path: WEBSHOP_ROUTE,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: WEBSHOP_BRAND_CUSTOMIZATION_ROUTE_V2,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: WEBSHOP_BRAND_CUSTOMIZATION_ROUTE,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: WEBSHOP_FOODHALL_ROUTE,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: WEBSHOP_PAYMENT_SETTINGS_ROUTE,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: WEBSHOP_NOTICE_ROUTE,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: WEBSHOP_DELIVERY_SHUTDOWN_ROUTE,
    exact: true,
    main: () => <Webshop />,
  },
  {
    path: MENU_V2_ROUTE,
    main: () => <MenuV2 />,
  },
  {
    path: LINKS_IN_BIO_ROUTE,
    exact: true,
    main: () => <LinkInBio />,
  },
  {
    path: PROMO_ROUTE,
    exact: true,
    main: () => <Promo />,
  },
  {
    path: LOYALTY_ROUTE,
    exact: true,
    main: () => <Loyalty />,
    permission: 'fetch.loyalty',
  },
  {
    path: PROMO_ROUTE_ADD,
    exact: true,
    main: () => <PromoAdd />,
  },
  {
    path: `${PROMO_ROUTE_EDIT}/:id`,
    exact: true,
    main: () => <PromoAdd />,
  },
  {
    path: RESERVATION_ROUTE,
    exact: true,
    main: () => <Reservation />,
  },
  {
    path: ADD_RESERVATION_ROUTE,
    exact: true,
    main: () => <AddReservation />,
  },
  {
    path: RESERVATION_SETTINGS_ROUTE,
    exact: true,
    main: () => <Reservation />,
  },
  {
    path: RESERVATION_LOCATIONS_ROUTE,
    exact: true,
    main: () => <Reservation />,
  },
  {
    path: WHITELABEL_ROUTE,
    exact: true,
    main: () => <Whitelabel />,
  },
  {
    path: IMAGE_MANAGER_ROUTE,
    exact: true,
    main: () => <ImageManager />,
  },
  {
    path: PAYMENT_INTEGRATION_ROUTE,
    exact: true,
    main: () => <PaymentIntegration />,
  },
  {
    path: STRIPE_PAYMENT_INTEGRATION_ROUTE,
    exact: true,
    main: () => <PaymentIntegration />,
  },
  {
    path: RATING_REVIEW_INTEGRATION_ROUTE,
    exact: true,
    main: () => <RatingAndReview />,
    permission: 'fetch.review.integration',
  },
  {
    path: LIVE_MONITOR_ROUTE,
    exact: true,
    main: () => <LiveMonitor />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: LIVE_MONITOR_PAUSED_STORES_ROUTE,
    exact: true,
    main: () => <LiveMonitorPausedStores />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: LIVE_MONITOR_INTEGRATION_STATUS_ROUTE,
    exact: true,
    main: () => <LiveMonitorIntegrationStatus />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: LIVE_MONITOR_AUTO_READY_ROUTE,
    exact: true,
    main: () => <AutoReadyActivityList />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: CAMPAIGN_ROUTE,
    exact: true,
    main: () => <Campaign />,
    permission: PERMISSIONS.FETCH_CAMPAIGN,
  },
  {
    path: `${CAMPAIGN_ROUTE}/:id`,
    exact: true,
    main: () => <CampaignDetails />,
    permission: PERMISSIONS.FETCH_CAMPAIGN,
  },
  {
    path: CAMPAIGN_ROUTE_ADD,
    exact: true,
    main: () => <CampaignForm />,
    permission: PERMISSIONS.CREATE_CAMPAIGN,
  },
  {
    path: LIVE_MONITOR_MENU_OOS_LIST_ROUTE,
    exact: true,
    main: () => <LiveMonitorMenuOOSActivity />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: LIVE_MONITOR_CATEGORY_OOS_LIST_ROUTE,
    exact: true,
    main: () => <LiveMonitorCategoryOOSActivity />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: LIVE_MONITOR_ITEM_OOS_LIST_ROUTE,
    exact: true,
    main: () => <LiveMonitorItemOOSActivity />,
    permission: PERMISSIONS.FETCH_LIVE_MONITOR,
  },
  {
    path: FEATURE_ACCESS_ROUTE,
    exact: true,
    main: () => <FeatureAccess />,
    permission: PERMISSIONS.FETCH_FEATURE_ALL,
  },
  {
    path: ADD_ORDER_CONFIG_PAYMENT_METHOD,
    exact: true,
    main: () => <AddOrderConfig />,
  },
  {
    path: ADD_ORDER_CONFIG_SETTINGS,
    exact: true,
    main: () => <AddOrderConfig />,
  },
  {
    path: MENU_PULLING_ROUTE,
    exact: true,
    main: () => <MenuPulling />,
    permission: PERMISSIONS.FETCH_MENU_PULLING,
  },
  {
    path: CUSTOMER_LIST_ROUTE,
    exact: true,
    main: () => <Customer />,
    permission: PERMISSIONS.FETCH_CRM_ALL,
  },
  {
    path: CUSTOMER_DETAIL_ROUTE,
    exact: true,
    main: () => <CustomerDetail />,
    permission: PERMISSIONS.FETCH_CRM_ALL,
  },
];
export default routes;
