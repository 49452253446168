import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import RevenueBarChart from './RevenueBarChart';
import {
  ORDER_COUNT_KEYS,
  COMPARISON_TYPE,
} from '../../../config/constants/analytics';
import RevenueStackBarChart from './RevenueStackedBarChart';

const CancelledOrderBarChart = ({
  analyticsDataLoading,
  analyticsData,
  selectedTimePeriod,
  selectedComparisonType,
}) => {
  const renderChart = Object.values(COMPARISON_TYPE).includes(
    selectedComparisonType,
  ) ? (
    <RevenueStackBarChart targetKey={ORDER_COUNT_KEYS.CANCELLED_ORDERS} />
  ) : (
    <RevenueBarChart
      analyticsData={analyticsData}
      analyticsDataLoading={analyticsDataLoading}
      selectedTimePeriod={selectedTimePeriod}
      targetKey={ORDER_COUNT_KEYS.CANCELLED_ORDERS}
      seriesName="Cancelled Order"
    />
  );
  return renderChart;
};

CancelledOrderBarChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  selectedComparisonType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
  selectedComparisonType: selectors.makeSelectSelectedComparisonType(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(CancelledOrderBarChart);
