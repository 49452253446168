import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import HelpPopover from '../../components/HelpPopover';
import {
  BUSY_MODE_CHART_HELP,
  BUSY_MODE_TABLE_HELP,
} from '../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { HOURLY_TIME_PERIOD } from '../../config/constants/analytics';
import BusyModeBarChart from './BarChart/BusyModeBarChart';
import BusyModeHeatmapChart from './Heatmap/BusyModeHeatmapChart';
import BusyModeReasonPieChart from './PieChart/BusyModeReasonPieChart';
import BusyModeLogTable from './Tables/BusyModeLogTable';
import { makeSelectProperty } from './selectors';

const BusyModeCard = ({
  selectedTimePeriod,
  isTimePeriodFilterStatusChanged,
  selectedComparisonType,
}) => {
  const [isBusyModeChartPopoverOpen, setIsBusyModeChartPopoverOpen] =
    useState(false);
  const [isBusyModeTablePopoverOpen, setIsBusyModeTablePopoverOpen] =
    useState(false);
  const [isBusyModePieChartPopoverOpen, setIsBusyModePieChartPopoverOpen] =
    useState(false);

  const isHourly = selectedTimePeriod.slug === HOURLY_TIME_PERIOD;
  let busyModeChartEl;
  if (!isTimePeriodFilterStatusChanged) {
    busyModeChartEl = isHourly ? (
      <BusyModeHeatmapChart />
    ) : (
      <BusyModeBarChart />
    );
  }

  return (
    <div className="mb-4">
      <div className="flex mb-4 flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 mr-0 lg:mr-4">
          <div className="flex flex-col p-4 h-full bg-white  rounded">
            <div className="flex flex-col text-black mb-2">
              <h5 className="text-base font-medium capitalize mb-2 flex">
                Pause Store
                <HelpPopover
                  isPopoverOpen={isBusyModeChartPopoverOpen}
                  position="right"
                  isHtmlElement
                  content={BUSY_MODE_CHART_HELP}
                  setIsPopoverOpen={setIsBusyModeChartPopoverOpen}
                  iconClassNames="sc-information cursor-pointer ml-2"
                />
              </h5>
            </div>
            <div className="w-full h-full">{busyModeChartEl}</div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-4 lg:mt-0">
          <div className="flex flex-col p-4 h-full bg-white rounded relative">
            <div className="flex flex-col text-black mb-2">
              <h5 className="text-base font-medium capitalize mb-2 flex items-center">
                Pause Store Reasons
                <HelpPopover
                  isPopoverOpen={isBusyModePieChartPopoverOpen}
                  position="right"
                  isHtmlElement
                  content="This pie chart summarizes the total counts and reasons for pause store"
                  setIsPopoverOpen={setIsBusyModePieChartPopoverOpen}
                  iconClassNames="sc-information cursor-pointer ml-2"
                />
              </h5>
            </div>
            <div className="w-full">
              <BusyModeReasonPieChart key={selectedComparisonType} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4 lg:mt-0">
        <div className="flex flex-col p-4 h-full bg-white rounded relative">
          <div className="flex flex-col text-black mb-2">
            <h5 className="text-base font-medium capitalize mb-2 flex">
              Pause log
              <HelpPopover
                isPopoverOpen={isBusyModeTablePopoverOpen}
                position="right"
                isHtmlElement
                content={BUSY_MODE_TABLE_HELP}
                setIsPopoverOpen={setIsBusyModeTablePopoverOpen}
                iconClassNames="sc-information cursor-pointer ml-2"
              />
            </h5>
          </div>
          <div className="w-full">
            <BusyModeLogTable />
          </div>
        </div>
      </div>
    </div>
  );
};
BusyModeCard.propTypes = {
  selectedTimePeriod: PropTypes.any,
  isTimePeriodFilterStatusChanged: PropTypes.bool,
  selectedComparisonType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  selectedComparisonType: makeSelectProperty('selectedComparisonType'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BusyModeCard);
