/**
 *
 * Auth
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { SCInput } from 'rollup-allspark';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import {
  changePassword,
  changeUsername,
  submitAuthRequest,
  submitAuthRequestBySocialSignIn,
} from './actions';
import {
  makeSelectLoading,
  makeSelectPassword,
  makeSelectUserName,
} from './selectors';
import logo from '../../assets/images/klikit-admin-black.png';
import bg from '../../assets/images/cafe-asian.jpg';
import LoadingButton from '../../components/LoadingButton';
import isInputAutoFilledByBrowser from '../../utils/isInputAutoFilledByBrowser';
import {
  EMAIL_REQUIRED_ERROR,
  EMAIL_INITIAL_STATE,
  EMAIL_VALID_ERROR,
  isEmailValid,
} from '../../utils/validationSchema';
import { googleSignInScriptInjector } from '../../utils/externalScriptInjector';
import { makeSelectCurrentUser } from '../App/selectors';
import localStorage from '../../utils/localStorage';

export function Auth({
  username,
  password,
  onChangeUserName,
  onChangePassword,
  onSubmitForm,
  onSubmitSocialSignIn,
  loading,
  currentUser,
}) {
  useInjectReducer({ key: 'auth', reducer });
  useInjectSaga({ key: 'auth', saga });

  const navigate = useNavigate();
  const location = useLocation();

  const [isAutoFilled, setIsAutoFilled] = useState(false);
  const [emailError, setEmailError] = useState(EMAIL_INITIAL_STATE);
  const [loginAttemptDone, setLoginAttemptDone] = useState(false);

  const googleSignInButtonRef = useRef(null);

  function handleSocialSignInCredentialResponse(response, loginProvider) {
    onSubmitSocialSignIn({
      token: response.credential,
      loginProvider,
    });
    setLoginAttemptDone(true);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const usernameInput = document.getElementById('username');
      const passwordInput = document.getElementById('password');

      const isAllInputAutoFilled =
        isInputAutoFilledByBrowser(usernameInput) &&
        isInputAutoFilledByBrowser(passwordInput);

      setIsAutoFilled(isAllInputAutoFilled);
    }, 300);

    googleSignInScriptInjector(
      googleSignInButtonRef,
      handleSocialSignInCredentialResponse,
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('access_token') && loginAttemptDone) {
      navigate('/oni?from=l');
    }
  }, [currentUser]);

  // const splitter = (
  //   <div className="flex flex-1 flex-col justify-center">
  //     <div className="border border-b-1 border-grey" />
  //   </div>
  // );
  const handleChangeEmail = event => {
    const email = event.target.value.trim();
    let emailErr = EMAIL_INITIAL_STATE;
    if (!email.length) {
      emailErr = EMAIL_REQUIRED_ERROR;
    } else if (!isEmailValid(email)) {
      emailErr = EMAIL_VALID_ERROR;
    }
    setEmailError(emailErr);
    onChangeUserName(email);
  };

  const emailErr =
    !emailError.isValid && emailError.error.length ? (
      <div className="text-red-light text-xs -mt-4 mb-4">
        {emailError.error}
      </div>
    ) : null;

  const isFieldsValid =
    (!isAutoFilled &&
      (username.length === 0 || password.length === 0 || loading)) ||
    emailError.error.length;

  const onSubmitLoginForm = evt => {
    if (evt !== undefined && evt.preventDefault) evt.preventDefault();
    onSubmitForm();
    setLoginAttemptDone(true);
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Auth</title>
        <meta name="login" content="This is admin login page" />
      </Helmet>
      <div className="flex h-screen">
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="login-wrapper flex flex-col">
            <div className="flex items-end mb-5">
              <img src={logo} alt="klikit" className="h-12" />
            </div>
            <div className="mr-4 mb-5 flex text-primary text-sm">
              Existing Account Login.
            </div>
            <form onSubmit={onSubmitLoginForm}>
              <SCInput
                id="username"
                label="Email"
                placeholder="Type your email here"
                inputClass="border rounded-sm"
                className="mb-4"
                value={username}
                handleChange={handleChangeEmail}
              />
              {emailErr}
              <SCInput
                id="password"
                type="password"
                label="Password"
                placeholder="Type your password here"
                value={password}
                handleChange={onChangePassword}
                inputClass="border rounded-sm"
                className="mb-5"
              />

              <LoadingButton
                variant="primary"
                size="xl"
                label="Login"
                btnClass="w-full rounded-sm text-sm mb-5"
                type="submit"
                loading={loading}
                disabled={isFieldsValid}
              />
            </form>
            <div ref={googleSignInButtonRef} />
          </div>
        </div>
        <img
          src={bg}
          alt="klikit for Business"
          className="h-full object-cover hidden lg:flex w-1/2"
        />
      </div>
    </div>
  );
}

Auth.propTypes = {
  onChangeUserName: PropTypes.func,
  onChangePassword: PropTypes.func,
  onSubmitForm: PropTypes.func,
  onSubmitSocialSignIn: PropTypes.func,
  username: PropTypes.string,
  password: PropTypes.string,
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  username: makeSelectUserName(),
  password: makeSelectPassword(),
  loading: makeSelectLoading(),
  currentUser: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    onChangeUserName: value => dispatch(changeUsername(value)),
    onChangePassword: evt => dispatch(changePassword(evt.target.value)),
    onSubmitForm: () => {
      dispatch(submitAuthRequest());
    },
    onSubmitSocialSignIn: params =>
      dispatch(submitAuthRequestBySocialSignIn(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Auth);
