import { GOOGLE_CLIENT_ID } from '../config/env';
import { SOCIAL_SIGN_IN_PROVIDERS } from '../config';

const loadScript = src =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = err => reject(err);
    document.body.appendChild(script);
  });

export const googleSignInScriptInjector = (
  signInButtonRef,
  credentialResponseCallback,
) => {
  const src = 'https://accounts.google.com/gsi/client';
  loadScript(src).then(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: response => {
          credentialResponseCallback(
            response,
            SOCIAL_SIGN_IN_PROVIDERS.GOOGLE.code,
          );
        },
      });
      window.google.accounts.id.renderButton(
        signInButtonRef.current,
        // Button customization attributes
        { theme: 'outline', size: 'large', width: 360 },
      );
    }
    // else {
    //   // eslint-disable-next-line no-console
    //   console.error('Google Client not found for SSO');
    // }
  });
  // .catch(console.error);
};
