import React from 'react';
import PropTypes from 'prop-types';
const MetricsCard = ({ title, total, activeCount }) => {
  const bgTotal = 'rgba(106, 19, 244, 0.1)';
  const bgActive = 'rgba(0, 158, 53, 0.1)';
  return (
    <div className="flex flex-col w-full p-3">
      <div className="text-sm text-primary mb-4"> {title}</div>
      <div className="flex flex-row justify-between">
        <div
          className="w-1/2 flex flex-col  justify-center h-20 p-2 mr-3"
          style={{ backgroundColor: bgTotal }}
        >
          <p className="text-xs">All</p>
          <p className="text-lg font-medium mt-3">{total}</p>
        </div>
        <div
          className="w-1/2 flex flex-col justify-center h-20 p-2"
          style={{ backgroundColor: bgActive }}
        >
          <p className="text-xs">Active</p>
          <p className="text-lg font-medium mt-3">{activeCount}</p>
        </div>
      </div>
    </div>
  );
};
MetricsCard.propTypes = {
  title: PropTypes.string,
  activeCount: PropTypes.any,
  total: PropTypes.any,
};
export default MetricsCard;
