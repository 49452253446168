import {
  KLIKIT_API_BASE_URL,
  KLIKIT_CDN_BASE_URL,
  KLIKIT_SOCKET_BASE_URL,
  PROVIDERS,
} from '../env';

export const APP_VERSION = '1.2.10';

export const API_BASE_URL = KLIKIT_API_BASE_URL;
export const CDN_BASE_URL = KLIKIT_CDN_BASE_URL;
export const SOCKET_BASE_URL = KLIKIT_SOCKET_BASE_URL;
export const SOCKET_DELAY = 10000;

export const BOTH_INTEGRETION_TYPE_PROVIDERS = [
  PROVIDERS.FOODPANDA,
  PROVIDERS.GOFOOD,
  PROVIDERS.GRABFOOD,
  PROVIDERS.DELIVEROO,
  PROVIDERS.UBEREATS,
];
export const INTERCEPTOR_INTEGRATION_TYPE_PROVIDERS = [
  PROVIDERS.UBEREATS,
  PROVIDERS.ODDLE,
  PROVIDERS.DOORDASH,
  PROVIDERS.SHOPEE,
  PROVIDERS.PICKAROO,
  PROVIDERS.DEMAECAN,
];
export const REVENUE_KEYS = {
  GROSS_REVENUE: 'gross_revenue',
  GROSS_REVENUE_USD: 'gross_revenue_usd',
  LOST_REVENUE: 'lost_revenue',
  LOST_REVENUE_USD: 'lost_revenue_usd',
  REALIZED_REVENUE: 'realized_revenue',
  REALIZED_REVENUE_USD: 'realized_revenue_usd',
};
export const ORDER_COUNT_KEYS = {
  COMPLETED_ORDERS: 'completed_orders',
  CANCELLED_ORDERS: 'cancelled_orders',
  TOTAL_ORDERS: 'total_orders',
};
export const TIME_PERIOD_LIST = [
  { title: 'Daily', id: 'daily', slug: 'daily' },
  { title: 'Weekly', id: 'daily', slug: 'weekly' },
  { title: 'Monthly', id: 'daily', slug: 'monthly' },
];
export const WEEKLY_TIME_PERIOD = TIME_PERIOD_LIST[1].slug;
export const MONTHLY_TIME_PERIOD = TIME_PERIOD_LIST[2].slug;
export const DEFAULT_SUBSCRIPTION_TYPE_ID = 9;
export const KLIKIT_PROVIDER_ID = 1;
export const ENT_CASH_PAYMENT_METHOD_ID = 900;
export const KLIKIT_DEFAULT_COLOR = '#6A13F4';

export const URL_PROTOCOL = 'https://';

export const REDUCERS = {
  NATIVE_ANALYTICS: 'nativeAnalytics',
};
