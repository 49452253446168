import React from 'react';
import PropTypes from 'prop-types';
import { SCLoader } from 'rollup-allspark';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  exportedCsvHeaderTitleForXAxis,
  generateComparisonData,
  generateStackedBarChartData,
} from '../../../utils/nativeAnalyticsUtils';
import {
  MONTHLY_TIME_PERIOD,
  WEEKLY_TIME_PERIOD,
  DEFAULT_CURRENCY_OBJ,
  COMPARISON_TYPE,
} from '../../../config/constants/analytics';
import StackedBarChart from './StackedBarChart';
import * as selectors from '../selectors';
import {
  formatDate,
  getStartOfWeek,
  formatDateWithoutTimezone,
} from '../../../utils/format-date';

const RevenueStackBarChart = ({
  analyticsData,
  analyticsDataLoading,
  targetKey,
  selectedProviderListForProviderComparison,
  selectedTimePeriod,
  selectedComparisonType,
  selectedBranches,
  selectedBrands,
}) => {
  const currencyCodeList =
    analyticsData && analyticsData.length
      ? analyticsData.map(b => b.currency)
      : [];

  const branchCurrencyObj =
    analyticsData && analyticsData.length
      ? {
          code: analyticsData[0].currency,
          symbol: analyticsData[0].currency_symbol,
        }
      : '';

  const shouldShowUsd = [...new Set(currencyCodeList)].length > 1;
  const currencyObj = shouldShowUsd ? DEFAULT_CURRENCY_OBJ : branchCurrencyObj;
  const exportedCsvHeaderTitle =
    exportedCsvHeaderTitleForXAxis(selectedTimePeriod);

  // GENERATE COMPARISON DATA
  const providerComparisonData =
    generateComparisonData(
      analyticsData,
      selectedComparisonType,
      selectedTimePeriod,
    ) || [];

  // GENERATE X AXIS DATA
  const prepareXaxisData = data => {
    if (selectedTimePeriod === WEEKLY_TIME_PERIOD) {
      const reportDate = new Date(data.date);
      const wStartDate = getStartOfWeek(reportDate);
      return formatDate(wStartDate.toISOString(), `MMM dd ''yy`);
    }
    if (selectedTimePeriod === MONTHLY_TIME_PERIOD) {
      return formatDate(data.date, `MMM ''yy`);
    }
    return formatDateWithoutTimezone(data.date, `MMM dd`);
  };
  const xAxisData = providerComparisonData.map(prepareXaxisData) || [];

  // GENERATE Y AXIS DATA
  const selectedComparisonTypeData = (() => {
    if (selectedComparisonType === COMPARISON_TYPE.BRANCH) {
      return selectedBranches;
    }
    if (selectedComparisonType === COMPARISON_TYPE.BRAND) {
      return selectedBrands;
    }
    return selectedProviderListForProviderComparison;
  })();
  const yAxisData =
    generateStackedBarChartData(
      selectedComparisonTypeData,
      providerComparisonData,
      targetKey,
      selectedComparisonType,
      selectedTimePeriod,
    ) || [];

  const renderGrossRevenueChart = () => {
    if (analyticsDataLoading) {
      return (
        <div className="flex items-center justify-center h-full">
          <SCLoader lineWidth={5} diameter={50} />
        </div>
      );
    }

    if (analyticsData && analyticsData.length) {
      return (
        <StackedBarChart
          id={targetKey}
          xAxisData={xAxisData}
          yAxisData={yAxisData}
          currencyObj={currencyObj}
          exportedCsvHeaderTitle={exportedCsvHeaderTitle}
        />
      );
    }
    return (
      <div className="flex justify-center items-center h-full text-2xl text-grey-darker">
        No Data Found!
      </div>
    );
  };

  return renderGrossRevenueChart();
};

RevenueStackBarChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  targetKey: PropTypes.string,
  selectedTimePeriod: PropTypes.string,
  selectedProviderListForProviderComparison: PropTypes.array,
  selectedComparisonType: PropTypes.string,
  selectedBranches: PropTypes.array,
  selectedBrands: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
  selectedProviderListForProviderComparison:
    selectors.makeSelectSelectedProvidersForProviderComparison(),
  selectedComparisonType: selectors.makeSelectSelectedComparisonType(),
  selectedBranches: selectors.makeSelectAnalyticsSelectedBranch(),
  selectedBrands: selectors.makeSelectAnalyticsSelectedBrand(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(RevenueStackBarChart);
