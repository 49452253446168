import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import KTTooltip from '../../../components/KTTooltip';
import { ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE } from '../../../config/constants/analytics';
import { PROVIDERS } from '../../../config/env';
import { isEmpty } from '../../../utils';
import { formatDateExact } from '../../../utils/format-date';
import { readablePrice } from '../../../utils/getCurrency';
import * as selectors from '../selectors';

const OrderTableRow = ({
  orderData,
  branches,
  analyticsProviders,
  analyticsOrderStatus,
}) => {
  const {
    id,
    external_id: externalId,
    created_at: orderCreationTime,
    brand_title: brandTitle,
    branch_id: branchId,
    providerID,
    status: cellStatus,
    item_price: itemPrice,
    delivery_fee: deliveryFee,
    final_price: finalPrice,
    discount,
    discount_breakdown: discountBreakdown = {},
    item_count: orderedItems,
    order_source: orderSource,
    currency_symbol: currencySymbol,
    currency: currencyCode,
    cancellation_reason: cancellationReason,
    cancelled_by: cancelledBy,
  } = orderData || {};

  const currencyObj = { code: currencyCode, symbol: currencySymbol };

  const getTitleForEntity = (items, itemId) => {
    const tableCell =
      items && items.length && itemId
        ? items.find(item => item.id === itemId)
        : null;

    const { title } = tableCell || {};
    return title;
  };

  const getStatusTitle = statusId => {
    const tableCell =
      analyticsOrderStatus && analyticsOrderStatus.length && statusId
        ? analyticsOrderStatus.find(s => s.id === statusId)
        : null;

    const { status } = tableCell || {};

    return status;
  };
  const renderOrderSourceType = providerId => {
    if (providerId === PROVIDERS.KLIKIT) {
      return !isEmpty(orderSource) ? (
        <small
          className="text-center capitalize ml-1 border rounded-full  border-grey-dark p-1"
          style={{ width: '75px' }}
        >
          {' '}
          {orderSource}
        </small>
      ) : null;
    }
    return null;
  };

  const sliceExternalId = idToSLice =>
    `${idToSLice.toString().slice(0, 3)}...${idToSLice.toString().slice(-4)}`;

  const discountBreakdownEl = (() => {
    const breakdownEl = (
      <div className="flex flex-col gap-y-1 w-40">
        <div className="flex justify-between">
          <span>Merchant Discount</span>
          <span>
            {readablePrice(discountBreakdown.merchant_discount, currencyObj)}
          </span>
        </div>
        <div className="flex justify-between">
          <span>Provider Discount</span>
          <span>
            {readablePrice(discountBreakdown.provider_discount, currencyObj)}
          </span>
        </div>
        <div className="flex justify-between">
          <span>Custom Discount</span>
          <span>
            {readablePrice(discountBreakdown.custom_discount, currencyObj)}
          </span>
        </div>
        <div className="flex justify-between">
          <span>Delivery Fee Discount</span>
          <span>
            {readablePrice(
              discountBreakdown.delivery_fee_discount,
              currencyObj,
            )}
          </span>
        </div>
      </div>
    );

    return (
      <KTTooltip content={breakdownEl} position={['right']}>
        <i className="kt-info text-lg text-grey-darker cursor-pointer" />
      </KTTooltip>
    );
  })();

  return (
    <tr>
      <td className="h-10 px-2 text-left">{id}</td>
      <td className="h-10 px-2 text-left">{sliceExternalId(externalId)}</td>
      <td className="h-10 px-2 text-left">
        {formatDateExact(orderCreationTime)}
      </td>
      <td className="h-10 px-2 text-left">{brandTitle}</td>
      <td className="h-10 px-2 text-left">
        {getTitleForEntity(branches, branchId)}
      </td>
      <td className="h-10 px-2 text-left">
        <div className=" flex items-center justify-center gap-x-1">
          <div
            className="w-2 h-2 rounded-full mr-1"
            style={{
              background: ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE[providerID],
            }}
          />
          {getTitleForEntity(analyticsProviders, providerID)}
          {renderOrderSourceType(providerID)}
        </div>
      </td>
      <td className="h-10 px-2 text-left">{getStatusTitle(cellStatus)}</td>
      <td className="h-10 px-2 text-right">{orderedItems}</td>
      <td className="h-10 px-2 text-right">
        {readablePrice(itemPrice, currencyObj)}
      </td>
      <td className="h-10 px-2 text-right">
        {readablePrice(deliveryFee, currencyObj)}
      </td>
      <td className="h-10 px-2 text-right flex gap-x-2">
        <div className="flex items-center justify-end w-full h-full gap-x-1">
          {readablePrice(discount, currencyObj)}
          {discountBreakdownEl}
        </div>
      </td>
      <td className="h-10 px-2 text-right">
        {readablePrice(finalPrice, currencyObj)}
      </td>
      <td className="h-10 px-2 text-left">{cancellationReason || 'N/A'}</td>
      <td className="h-10 px-2 text-left">{cancelledBy || 'N/A'}</td>
    </tr>
  );
};

OrderTableRow.propTypes = {
  orderData: PropTypes.object,
  branches: PropTypes.array,
  analyticsProviders: PropTypes.array,
  analyticsOrderStatus: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

const mapStateToProps = createStructuredSelector({
  branches: selectors.makeSelectAnalyticsBranches(),
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  analyticsOrderStatus: selectors.makeSelectStatus(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(OrderTableRow);
