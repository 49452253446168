const sendNetworkResponseToSentry = errorResponse => {
  const errorStatus = errorResponse.status;
  const errorType = Math.floor(errorStatus / 100);
  const ignoreErrorStatus = [401, 404];
  const isResponseExistsInIgnoredErros =
    ignoreErrorStatus.includes(errorStatus);
  if ((errorType === 4 || errorType === 5) && !isResponseExistsInIgnoredErros) {
    return true;
  }
  return false;
};

export default sendNetworkResponseToSentry;
