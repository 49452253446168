const checkSelectors = input => {
  // Using try-catch, because some browsers through error for some selector checks,
  // like firefox cries, when checking :-internal-autofill-selected
  try {
    return Boolean(
      input.matches(':-internal-autofill-selected') ||
        input.matches(':autofill') ||
        input.matches(':-webkit-autofill') ||
        input.matches(':-moz-autofill'),
    );
  } catch (error) {
    return false;
  }
};

const isInputAutoFilledByBrowser = input =>
  Boolean(
    input && typeof input.matches === 'function' && checkSelectors(input),
  );

export default isInputAutoFilledByBrowser;
