import React, { useState } from 'react';
import { PERFORMANCE_TYPES } from '../../../config/constants/analytics';
import BranchComparison from './BranchComparison';
import BranchPerformance from './BranchPerformance';
import BrandPerformance from './BrandPerformance';
import KTTooltip from '../../../components/KTTooltip';
import { cn } from '../../../utils';

const PerformanceSummaryCard = () => {
  const [selectedComparisonType, setSelectedComparisonType] = useState(
    PERFORMANCE_TYPES.BRANCH_COMPARISON,
  );

  const getButtonClasses = buttonType => {
    const baseClasses =
      'flex flex-col justify-start border rounded px-4 py-2 w-1/3';
    const selectedClasses = 'border-2 border-primary bg-primary-lighter';
    return cn(baseClasses, {
      [selectedClasses]: selectedComparisonType === buttonType,
    });
  };

  const handlePerformanceTypeChange = type => {
    setSelectedComparisonType(type);
  };

  return (
    <div className="w-full bg-white min-h p-4 rounded mb-4">
      <div className="mb-6">
        <h5 className="flex items-center">
          <span className="text-base font-medium capitalize mr-2">
            Performance
          </span>
          <KTTooltip
            content="Track and analyze your restaurant’s overall performance. View key metrics like net sales and number of orders across all brands and branches."
            iconClassNames="kt-info text-grey-darker"
          />
        </h5>
        <p className="text-grey-darker">
          Track and analyze your restaurant’s overall performance.
        </p>
      </div>
      <div className="flex gap-x-2 mb-4">
        <button
          className={getButtonClasses(PERFORMANCE_TYPES.BRAND_PERFORMANCE)}
          type="button"
          onClick={() =>
            handlePerformanceTypeChange(PERFORMANCE_TYPES.BRAND_PERFORMANCE)
          }
        >
          <p className="font-medium flex items-center gap-x-1">
            <span>Brand Performance</span>
            <KTTooltip
              content="View the sales performance of each brand, including net sales and total number of orders, to understand how each brand is contributing to your business."
              iconClassNames="kt-info text-grey-darker"
            />
          </p>
          <p className="text-grey-darker text-xs my-2 text-left">
            View the sales performance of all brands.
          </p>
        </button>
        <button
          className={getButtonClasses(PERFORMANCE_TYPES.BRANCH_PERFORMANCE)}
          type="button"
          onClick={() =>
            handlePerformanceTypeChange(PERFORMANCE_TYPES.BRANCH_PERFORMANCE)
          }
        >
          <p className="font-medium flex items-center gap-x-1">
            <span>Branch Performance</span>
            <KTTooltip
              content="Monitor individual branch performance with insights into net sales and total orders, helping you assess the success of each location."
              iconClassNames="kt-info text-grey-darker"
            />
          </p>
          <p className="text-grey-darker text-xs my-2 text-left">
            Monitor individual branch performance.
          </p>
        </button>
        <button
          className={getButtonClasses(PERFORMANCE_TYPES.BRANCH_COMPARISON)}
          type="button"
          onClick={() =>
            handlePerformanceTypeChange(PERFORMANCE_TYPES.BRANCH_COMPARISON)
          }
        >
          <p className="font-medium flex items-center gap-x-1">
            <span>Branch Comparison</span>
            <KTTooltip
              content="Compare branch performance side-by-side across different brands. Analyze net sales and order volumes to identify top-performing locations."
              iconClassNames="kt-info text-grey-darker"
            />
          </p>
          <p className="text-grey-darker text-xs my-2 text-left">
            Compare branch performance side-by-side across different brands.
          </p>
        </button>
      </div>
      {selectedComparisonType === PERFORMANCE_TYPES.BRANCH_COMPARISON && (
        <BranchComparison />
      )}
      {selectedComparisonType === PERFORMANCE_TYPES.BRANCH_PERFORMANCE && (
        <BranchPerformance />
      )}
      {selectedComparisonType === PERFORMANCE_TYPES.BRAND_PERFORMANCE && (
        <BrandPerformance />
      )}
    </div>
  );
};

export default PerformanceSummaryCard;
