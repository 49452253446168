import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SCLoader } from 'rollup-allspark';
import * as selectors from './selectors';
import MetricsCard from './MetricsCard';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import HelpPopover from '../../components/HelpPopover';
import { METRICS_HELP } from '../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';

const Metrics = ({
  brands,
  branches,
  analyticsProviders,
  summary,
  summaryLoading,
}) => {
  useInjectReducer({ key: 'nativeAnalytics', reducer });
  useInjectSaga({ key: 'nativeAnalytics', saga });

  // HOOKS
  // ======
  // USE-STATES
  const [isActiveCountPopoverOpen, setIsActiveCountPopoverOpen] =
    useState(false);
  const [metrics, setMetrics] = useState(null);
  const [selectedCountryIds, setSelectedCountryIds] = useState(null);
  // USE-EFFECTS
  useEffect(() => {
    if (summary && summary.metrics) {
      setMetrics(summary.metrics);
    }
  }, [summary]);
  useEffect(() => {
    const countryIds =
      branches && branches.length
        ? [...new Set(branches.map(eb => eb.country_id))]
        : [];
    setSelectedCountryIds(countryIds);
  }, [branches]);
  const getActiveCountryIds = activeBranchIds => {
    const activeCountryIds = branches
      .filter(branch => activeBranchIds.includes(branch.id))
      .map(branch => branch.country_id);
    return [...new Set(activeCountryIds)];
  };
  const renderLoader = (
    <div className="flex items-center justify-center h-48 w-full">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );
  const renderActiveCount = (
    <div className="flex flex-col gap-2 lg:flex-row justify-between">
      <div className="flex gap-2 w-full lg:w-1/2">
        <div className="basis-1/2 bg-grey-lighter rounded">
          <MetricsCard
            title="Brands"
            activeCount={
              metrics && metrics.active_brand_ids
                ? metrics.active_brand_ids.length
                : 0
            }
            total={brands && brands.length ? brands.length : 0}
          />
        </div>
        <div className="basis-1/2 bg-grey-lighter rounded">
          <MetricsCard
            title="Branches"
            activeCount={
              metrics && metrics.active_branch_ids
                ? metrics.active_branch_ids.length
                : 0
            }
            total={branches && branches.length ? branches.length : 0}
          />
        </div>
      </div>
      <div className="flex gap-2 w-full lg:w-1/2">
        <div className="basis-1/2 mt-3 lg:mt-0 bg-grey-lighter rounded">
          <MetricsCard
            title="Aggregators"
            activeCount={
              metrics && metrics.active_provider_ids
                ? metrics.active_provider_ids.length
                : 0
            }
            total={
              analyticsProviders && analyticsProviders.length
                ? analyticsProviders.length
                : 0
            }
          />
        </div>
        <div className="basis-1/2 mt-3 lg:mt-0 bg-grey-lighter rounded">
          <MetricsCard
            title="Market"
            activeCount={
              metrics &&
              getActiveCountryIds(metrics ? metrics.active_branch_ids : [])
                .length
                ? getActiveCountryIds(metrics ? metrics.active_branch_ids : [])
                    .length
                : 0
            }
            total={selectedCountryIds ? selectedCountryIds.length : 0}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className="w-full bg-white min-h p-4 rounded">
      <div className="w-full flex mb-4">
        <h5 className="text-base font-medium capitalize mr-2">
          No. Of Brands, Branches, Aggregators, Markets
        </h5>
        <HelpPopover
          isPopoverOpen={isActiveCountPopoverOpen}
          position="right"
          isHtmlElement
          content={METRICS_HELP}
          setIsPopoverOpen={setIsActiveCountPopoverOpen}
          iconClassNames="sc-information cursor-pointer"
        />
      </div>
      {summaryLoading ? renderLoader : renderActiveCount}
    </div>
  );
};
Metrics.propTypes = {
  brands: PropTypes.array,
  branches: PropTypes.array,
  analyticsProviders: PropTypes.array,
  summary: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  summaryLoading: PropTypes.bool,
};
const mapStateToProps = createStructuredSelector({
  brands: selectors.makeSelectAnalyticsBrands(),
  branches: selectors.makeSelectAnalyticsBranches(),
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  summary: selectors.makeSelectSummary(),
  summaryLoading: selectors.makeSelectSummaryLoading(),
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Metrics);
