export const numToTime = num => {
  const mins = num % 100;
  const minStr = mins < 10 ? `0${mins}` : mins;

  const hours = Math.floor(num / 100);
  const hoursStr = hours < 10 ? `0${hours}` : hours;

  return `${hoursStr}:${minStr}`;
};

export const getNumFromTime = (dateTime = '') => {
  if (!dateTime) return 0;
  const date = new Date(dateTime);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return parseInt(hours + minutes, 10);
};

export const numToEndTimeAMPM = num => {
  const hours = Math.floor(num / 100); // Extract hours from the input
  const mins = num % 100; // Extract minutes from the input

  const endHours = hours % 12 || 12;
  const endHoursStr = endHours < 10 ? `0${endHours}` : endHours;
  const endMinutesStr = mins < 10 ? `0${mins}` : mins;

  const amPM = hours < 12 ? 'AM' : 'PM'; // Simplify the AM/PM determination

  return `${endHoursStr}:${endMinutesStr} ${amPM}`;
};

export const convertToHoursAndMinutes = minutes => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const result = [];

  if (hours > 0) {
    result.push(`${hours}h`);
  }
  if (remainingMinutes > 0) {
    result.push(`${remainingMinutes}m`);
  }

  return result.join(' ') || 0;
};

export const formatTimeToHHMM = isoDate => {
  const date = new Date(isoDate);
  const hours = date.getUTCHours().toString().padStart(2, '0'); // Ensure 2-digit format
  const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Ensure 2-digit format

  return hours + minutes;
};

export const generateDurations = (interval = 30, maxHours = 2) => {
  const durations = [];
  const maxMinutes = maxHours * 60;

  for (let i = interval; i <= maxMinutes; i += interval) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    const label =
      hours === 0
        ? `${minutes} Minutes`
        : `${hours} Hour${hours > 1 ? 's' : ''}${
            minutes > 0 ? ` ${minutes} Minutes` : ''
          }`;
    durations.push({ minutesValue: i, label });
  }

  return durations;
};
