import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import RevenueBarChart from './RevenueBarChart';
import {
  REVENUE_KEYS,
  COMPARISON_TYPE,
} from '../../../config/constants/analytics';
import RevenueStackBarChart from './RevenueStackedBarChart';

const RealizedRevenueBarChart = ({
  analyticsDataLoading,
  analyticsData,
  selectedTimePeriod,
  selectedComparisonType,
}) => {
  const currencyCodeList =
    analyticsData && analyticsData.length
      ? analyticsData.map(b => b.currency)
      : [];
  const shouldShowUsd = [...new Set(currencyCodeList)].length > 1;
  const targetRevenueKey = shouldShowUsd
    ? REVENUE_KEYS.REALIZED_REVENUE_USD
    : REVENUE_KEYS.REALIZED_REVENUE;

  const renderChart = Object.values(COMPARISON_TYPE).includes(
    selectedComparisonType,
  ) ? (
    <RevenueStackBarChart targetKey={targetRevenueKey} />
  ) : (
    <RevenueBarChart
      analyticsData={analyticsData}
      analyticsDataLoading={analyticsDataLoading}
      selectedTimePeriod={selectedTimePeriod}
      targetKey={targetRevenueKey}
      seriesName="Realized Revenue"
    />
  );
  return <div className="flex flex-col p-4 h-full">{renderChart}</div>;
};
RealizedRevenueBarChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  selectedComparisonType: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
  selectedComparisonType: selectors.makeSelectSelectedComparisonType(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(RealizedRevenueBarChart);
