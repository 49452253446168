import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ITEM_BREAKDOWN_TABS } from '../../config/constants/analytics';
import {
  DOWNLOAD_FAILED_NO_INTERNET_MSG,
  LOAD_FAILED_NO_INTERNET_MSG,
} from '../../config/constants/errorMessages';
import { cloneDeep, isEmpty, isFunction, printf } from '../../utils';
import makeRequest from '../../utils/api';
import { downloadCsvFile } from '../../utils/sheetUtils';
import { showErrMsg } from '../../utils/validationSchema';
import * as constants from './constants';
import {
  makeSelectAnalyticsSelectedProvider,
  makeSelectSelectedTimePeriod,
} from './selectors';

function* getSummary(meta) {
  const { params } = meta || {};

  try {
    const payload = {
      url: `v2/analyseit/order/summary`,
      method: 'GET',
      params,
    };
    const summaryResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_SUMMARY_SUCCESSFUL,
      summary: summaryResp.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_SUMMARY_FAILED });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Summary'));
  }
}

function* getAnalyticsData(meta) {
  const { params } = meta || {};
  const selectedTimePeriod = yield select(makeSelectSelectedTimePeriod());
  const selectedProviders = yield select(makeSelectAnalyticsSelectedProvider());

  // const timeZone = getUserTimezone();
  // params.tz = timeZone;

  try {
    const payload = {
      url: `v1/analyseit/order/analytics`,
      method: 'GET',
      params,
    };
    const analyticsResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ANALYTICS_DATA_SUCCESSFUL,
      analyticsData: analyticsResp.data,
    });
    yield put({
      type: constants.CHANGE_SELECTED_PROVIDERS_LIST_FOR_PROVIDER_COMPARISON,
      selectedProviderListForProviderComparison: cloneDeep(selectedProviders),
    });
    yield put({
      type: constants.CHANGE_TIME_PERIOD_SLUG_FOR_DATA_CALCULATION,
      timePeriodSlug: selectedTimePeriod && selectedTimePeriod.slug,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_ANALYTICS_DATA_FAILED });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Analytics'));
  }
}

function* getOrdersData(meta) {
  const { params } = meta || {};

  // const timeZone = getUserTimezone();
  // params.tz = timeZone;

  try {
    const payload = {
      url: `v1/analyseit/order`,
      method: 'GET',
      params,
    };
    const ordersDataResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ORDERS_DATA_SUCCESSFUL,
      ordersData: ordersDataResp.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_ORDERS_DATA_FAILED });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Orders Data'));
  }
}

function* getBusinesses(meta) {
  const { params } = meta || {};
  try {
    const payload = {
      url: 'v1/business',
      method: 'GET',
      params,
    };
    const businessResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ANALYTICS_BUSINESS_SUCCESSFUL,
      businesses: businessResp.data.results,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_ANALYTICS_BUSINESS_FAILED });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Business'));
  }
}

function* getBrands(meta) {
  const { params } = meta || {};
  try {
    const payload = {
      url: 'v1/brand',
      method: 'GET',
      params,
    };
    const brandsResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ANALYTICS_BRANDS_SUCCESSFUL,
      brands: brandsResp.data.results,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_ANALYTICS_BRANDS_FAILED });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Brands'));
  }
}

function* getBranches(meta) {
  const { params } = meta || {};
  try {
    const payload = {
      url: 'v1/branch',
      method: 'GET',
      params,
    };
    const branchesResp = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ANALYTICS_BRANCHES_SUCCESSFUL,
      branches: branchesResp.data.results,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_ANALYTICS_BRANCHES_FAILED });
    const { message } = (err && err.response && err.response.data) || {};
    toast.error(message || printf(LOAD_FAILED_NO_INTERNET_MSG, 'Branches'));
  }
}

function* getProviders(meta) {
  try {
    const params = { ...meta.params };

    if (meta.params.countryId) {
      params.filterByCountry = meta.params.countryId;
    }

    const payload = {
      url: 'v1/provider',
      method: 'get',
      params,
    };
    const providerData = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ANALYTICS_PROVIDERS_SUCCESS,
      analyticsProviders: providerData.data,
    });
  } catch (error) {
    yield put({ type: constants.LOAD_ANALYTICS_PROVIDERS_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Providers'));
  }
}

function* getAnalyticsCsv(meta) {
  try {
    const params = { ...meta.params };

    // const timeZone = getUserTimezone();
    // params.tz = timeZone;

    if (meta.params.countryId) {
      params.filterByCountry = meta.params.countryId;
    }

    const payload = {
      url: 'v2/analyseit/order/export',
      method: 'get',
      params,
    };
    const csvData = yield call(makeRequest, payload);
    // DISCARD FILE DOWNLOAD IF STATUS IS 202
    if (csvData.status === 202) {
      toast.success(csvData.data.message);
      return;
    }
    yield put({
      type: constants.LOAD_ANALYTICS_CSV_SUCCESS,
    });
    downloadCsvFile(
      csvData.data,
      `Analytics-Orders-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  } catch (error) {
    yield put({ type: constants.LOAD_ANALYTICS_CSV_FAILED });
    toast.error(printf(DOWNLOAD_FAILED_NO_INTERNET_MSG, 'File'));
  }
}

function* getStatus() {
  try {
    const payload = {
      url: 'v1/oni/statuses',
      method: 'get',
    };
    const statusData = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_STATUS_SUCCESS,
      analyticsOrderStatus: statusData.data,
    });
  } catch (error) {
    yield put({ type: constants.LOAD_STATUS_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Status'));
  }
}
function* getItemBreakdown(meta) {
  try {
    const params = { ...meta.params };
    const { activeTab, ...itemBreakDownParam } = params;
    const urlType =
      ITEM_BREAKDOWN_TABS.find(tab => tab.id === activeTab) &&
      ITEM_BREAKDOWN_TABS.find(tab => tab.id === activeTab).key;

    const payload = {
      url: `v2/analyseit/order/${urlType}/analytics`,
      method: 'get',
      params: itemBreakDownParam,
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_ITEM_BREAKDOWN_DATA_SUCCESSFUL,
      itemBreakDownData: response.data,
    });
  } catch (error) {
    yield put({ type: constants.LOAD_ITEM_BREAKDOWN_DATA_FAILED });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Items'));
  }
}
function* getBusyModeAnalyticsData(meta) {
  const { params } = meta || {};
  const selectedTimePeriod = yield select(makeSelectSelectedTimePeriod());
  const selectedProviders = yield select(makeSelectAnalyticsSelectedProvider());

  try {
    const payload = {
      url: `v2/analyseit/busymode/analytics`,
      method: 'GET',
      params,
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_BUSY_MODE_ANALYTICS_DATA_SUCCESSFUL,
      busyModeAnalyticsData: response.data,
    });
    yield put({
      type: constants.CHANGE_SELECTED_PROVIDERS_LIST_FOR_PROVIDER_COMPARISON,
      selectedProviderListForProviderComparison: cloneDeep(selectedProviders),
    });
    yield put({
      type: constants.CHANGE_TIME_PERIOD_SLUG_FOR_DATA_CALCULATION,
      timePeriodSlug: selectedTimePeriod && selectedTimePeriod.slug,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_BUSY_MODE_ANALYTICS_DATA_FAILED });
    const { message } = (err && err.response && err.response.data) || {
      message: printf(LOAD_FAILED_NO_INTERNET_MSG, 'analytics'),
    };
    toast.error(message);
  }
}

function* getBusyModeLogData(meta) {
  const { params } = meta || {};

  try {
    const payload = {
      url: `v2/analyseit/busymode/logs`,
      method: 'GET',
      params,
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_BUSY_MODE_LOG_DATA_SUCCESSFUL,
      busyModeLogData: response.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_BUSY_MODE_LOG_DATA_FAILED });
    const { message } = (err && err.response && err.response.data) || {
      message: printf(LOAD_FAILED_NO_INTERNET_MSG, 'analytics'),
    };
    toast.error(message);
  }
}

function* getPaymentMethodAnalyticsData(meta) {
  const { params } = meta || {};

  try {
    const payload = {
      url: `v2/analyseit/order/payment/summary`,
      method: 'GET',
      params,
    };
    const response = yield call(makeRequest, payload);
    yield put({
      type: constants.LOAD_PAYMENT_METHOD_DATA_SUCCESSFUL,
      paymentMethodAnalytics: response.data,
    });
  } catch (err) {
    yield put({ type: constants.LOAD_PAYMENT_METHOD_DATA_FAILED });
    const { message } = (err && err.response && err.response.data) || {
      message: printf(LOAD_FAILED_NO_INTERNET_MSG, 'analytics'),
    };
    toast.error(message);
  }
}

function* getList(meta) {
  const { params: metaParams } = meta || {};
  const { url, params, reducerKey } = metaParams || {};

  try {
    const payload = {
      url,
      method: 'GET',
      params,
    };
    const resp = yield call(makeRequest, payload);

    const list =
      resp?.data?.accounts || resp?.data?.results || resp?.data || [];

    yield put({
      type: constants.GET_LIST_SUCCESS,
      payload: {
        key: reducerKey,
        value: list,
      },
    });
  } catch (err) {
    yield put({
      type: constants.GET_LIST_FAILED,
      payload: {
        key: reducerKey,
      },
    });
    showErrMsg(err);
  }
}

function* getData(req) {
  const { reducerKey, url, method, params, callbackFn } = req.meta || {};

  try {
    const payload = {
      url,
      method,
      params,
    };

    const res = yield call(makeRequest, payload);
    const details = res.data.results || res.data || [];
    const successReducerPayload = {};

    if (!isEmpty(reducerKey)) {
      successReducerPayload[reducerKey] = details;
    }

    yield put({
      type: constants.GET_DATA_SUCCESS,
    });

    yield put({
      type: constants.EDIT_FIELD,
      params: successReducerPayload,
    });

    if (isFunction(callbackFn)) {
      callbackFn(details, res);
    }
  } catch (err) {
    yield put({
      type: constants.GET_DATA_FAILED,
    });

    if (!isEmpty(reducerKey)) {
      yield put({
        type: constants.EDIT_FIELD,
        params: {
          [reducerKey]: null,
        },
      });
    }

    const { message } = err?.response?.data || {};
    toast.error(message || `Failed to load ${reducerKey}`);
  }
}

function* getCSV(meta) {
  try {
    const { url, method, params: parameters, fileName } = meta.payload || {};

    // const timeZone = getUserTimezone();
    // params.tz = timeZone;

    const params = { ...parameters };

    if (params.countryId) {
      params.filterByCountry = parameters.countryId;
    }

    const payload = {
      url,
      method,
      params,
    };

    const response = yield call(makeRequest, payload);
    const { status, data } = response || {};

    yield put({
      type: constants.LOAD_CSV_SUCCESS,
    });

    // DISCARD FILE DOWNLOAD IF STATUS IS 202
    if (status === 202) {
      toast.success(data.message);
      return;
    }

    downloadCsvFile(
      data,
      `${fileName}-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  } catch (error) {
    yield put({ type: constants.LOAD_CSV_FAILED });
    toast.error(printf(DOWNLOAD_FAILED_NO_INTERNET_MSG, 'File'));
  }
}

export default function* nativeAnalyticsSaga() {
  yield takeLatest(constants.LOAD_SUMMARY, getSummary);
  yield takeLatest(constants.LOAD_ANALYTICS_DATA, getAnalyticsData);
  yield takeLatest(constants.LOAD_ORDERS_DATA, getOrdersData);
  yield takeLatest(constants.LOAD_ANALYTICS_BUSINESS, getBusinesses);
  yield takeLatest(constants.LOAD_ANALYTICS_BRANDS, getBrands);
  yield takeLatest(constants.LOAD_ANALYTICS_BRANCHES, getBranches);
  yield takeLatest(constants.LOAD_ANALYTICS_PROVIDERS, getProviders);
  yield takeLatest(constants.LOAD_ANALYTICS_CSV, getAnalyticsCsv);
  yield takeLatest(constants.LOAD_STATUS, getStatus);
  yield takeLatest(constants.LOAD_ITEM_BREAKDOWN_DATA, getItemBreakdown);
  yield takeLatest(
    constants.LOAD_BUSY_MODE_ANALYTICS_DATA,
    getBusyModeAnalyticsData,
  );
  yield takeLatest(constants.LOAD_BUSY_MODE_LOG_DATA, getBusyModeLogData);
  yield takeLatest(
    constants.LOAD_PAYMENT_METHOD_DATA,
    getPaymentMethodAnalyticsData,
  );
  yield takeLatest(constants.GET_DATA, getData);
  yield takeEvery(constants.GET_LIST, getList);
  yield takeLatest(constants.LOAD_CSV, getCSV);
}
