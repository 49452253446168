/**
 *
 * LoadingButton
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { SCButton, SCLoader } from 'rollup-allspark';

function LoadingButton({
  label,
  loading = false,
  disabled = false,
  variant = 'primary',
  size = 'lg',
  btnClass = 'text-sm',
  action = () => {},
  visible = true,
}) {
  const loader = loading ? (
    <span id="loader">
      <SCLoader lineWidth={2} diameter={20} />
    </span>
  ) : null;

  return visible ? (
    <SCButton
      variant={variant}
      size={size}
      label={label}
      className={btnClass}
      type="submit"
      disabled={disabled || loading}
      action={action}
    >
      {loader}
    </SCButton>
  ) : null;
}

LoadingButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  btnClass: PropTypes.string,
  action: PropTypes.func,
  visible: PropTypes.bool,
};

export default LoadingButton;
