import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SCLoader, SCPagination } from 'rollup-allspark';
import { formatDateExact } from '../../../../utils/format-date';
import { isEmpty } from '../../../../utils';
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZES,
} from '../../../../config';

const OosTable = ({
  tableHeaderData,
  tableBodyData,
  listLoading,
  tableType,
  activeTab,
}) => {
  // CONSTANTS

  // HOOKS
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NO);
  const [currentSize, setCurrentSize] = useState(DEFAULT_PAGE_SIZE);
  const [paginatedTableRowData, setPaginatedTableRowData] = useState([]);

  useEffect(() => {
    const indexOfLastPerm = currentPage * currentSize;
    const indexOfFirstPerm = indexOfLastPerm - currentSize;
    setPaginatedTableRowData(
      tableBodyData?.slice(indexOfFirstPerm, indexOfLastPerm),
    );
  }, [
    tableHeaderData,
    tableBodyData,
    listLoading,
    tableType,
    activeTab,
    currentPage,
    currentSize,
  ]);

  // HANDLERS AND GENERATORS

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
  };

  // RENDERS

  // loaders
  const renderLoader = (
    <div className="flex items-center justify-center h-48 w-full">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );

  // tableHeader
  const renderHeaderCol = tableHeaderData?.map((cell, index) => (
    <th
      key={`discount-header-cell-${index + 1}`}
      className="bg-grey-lighter border-b capitalize p-3 text-left"
    >
      {cell.title}
    </th>
  ));
  // tableBody
  const renderTableBodyRows = !isEmpty(paginatedTableRowData) ? (
    paginatedTableRowData.map((cell, index) => (
      <tr key={`discount-row-${index + 1}`}>
        <td className="p-4 border-r border-b">
          {tableType === 'modifier' ? cell.modifier_title : cell.title}
        </td>
        {tableType === 'modifier' ? (
          <td className="p-4 border-r border-b">{cell.modifier_group_title}</td>
        ) : null}
        {activeTab.type === 'logs' ? (
          <>
            <td className="p-4 border-r border-b">
              {!isEmpty(cell.start_time)
                ? formatDateExact(cell.start_time)
                : ''}
            </td>
            <td className="p-4 border-r border-b">
              {!isEmpty(cell.end_time) ? formatDateExact(cell.end_time) : ''}
            </td>
          </>
        ) : null}
        <td className="p-4  border-b border-r ">{cell.branch_title}</td>
        <td className="p-4  border-b border-r ">{cell.brand_title}</td>
        <td className="p-4  border-b border-r ">{cell.duration}</td>
      </tr>
    ))
  ) : (
    <tr className="text-center">
      <td colSpan="11" className="p-5 text-2xl text-grey-darker h-20">
        No data Found
      </td>
    </tr>
  );
  // table
  const renderTable = (
    <table className="table-auto w-full mb-4">
      <thead>
        <tr>{renderHeaderCol}</tr>
      </thead>
      <tbody>{renderTableBodyRows}</tbody>
    </table>
  );
  // pagination
  const pagination =
    !listLoading && !isEmpty(tableBodyData) ? (
      <SCPagination
        className="mt-4"
        total={!isEmpty(tableBodyData) ? tableBodyData.length : 0}
        page={currentPage}
        size={currentSize}
        sizes={DEFAULT_PAGE_SIZES}
        onPageChange={handlePageChange}
      />
    ) : null;

  return (
    <div className="flex flex-col items-center mb-4 mt-4">
      {listLoading ? renderLoader : renderTable}
      {pagination}
    </div>
  );

  // Tabs
};

OosTable.propTypes = {
  tableHeaderData: PropTypes.array,
  tableBodyData: PropTypes.array,
  listLoading: PropTypes.bool,
  tableType: PropTypes.string,
  activeTab: PropTypes.object,
};

export default OosTable;
