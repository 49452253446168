import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CompletedOrderBarChart from './BarChart/CompletedOrderBarChart';
import CompletedOrderHeatmapChart from './Heatmap/CompletedOrderHeatmapChart';
import CancelledOrderBarChart from './BarChart/CancelledOrderBarChart';
import CancelledOrderHeatmapChart from './Heatmap/CancelledOrderHeatmapChart';
import HelpPopover from '../../components/HelpPopover';
import { COMPLETED_VS_CANCELLED_HELP } from '../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';
import { HOURLY_TIME_PERIOD } from '../../config/constants/analytics';

const CompletedVsCancelledOrderChart = ({
  selectedTimePeriod,
  isTimePeriodFilterStatusChanged,
}) => {
  const isHourly = selectedTimePeriod.slug === HOURLY_TIME_PERIOD;
  const COMPARISON_TABS = [
    {
      id: 1,
      title: 'Completed Orders',
      tabContent: null,
    },
    {
      id: 2,
      title: 'Cancelled Orders',
      tabContent: null,
    },
  ];
  let tabContentOne;
  if (!isTimePeriodFilterStatusChanged) {
    tabContentOne = isHourly ? (
      <CompletedOrderHeatmapChart />
    ) : (
      <CompletedOrderBarChart />
    );
  } else {
    tabContentOne = COMPARISON_TABS[0].tabContent;
  }
  COMPARISON_TABS[0].tabContent = tabContentOne;

  let tabContentTwo;
  if (!isTimePeriodFilterStatusChanged) {
    tabContentTwo = isHourly ? (
      <CancelledOrderHeatmapChart />
    ) : (
      <CancelledOrderBarChart />
    );
  } else {
    tabContentTwo = COMPARISON_TABS[1].tabContent;
  }
  COMPARISON_TABS[1].tabContent = tabContentTwo;
  const [selectedTab, setSelectedTab] = useState(COMPARISON_TABS[0].id);
  const [
    isCompletedVsCancelledPopoverOpen,
    setIsCompletedVsCancelledPopoverOpen,
  ] = useState(false);

  const handleChangeTab = id => {
    setSelectedTab(id);
  };

  const tabsEl = (() =>
    COMPARISON_TABS.map(ct => {
      const selectedClassName =
        selectedTab === ct.id
          ? 'border-b-2 border-primary bg-primary-lighter text-black'
          : 'text-grey-darker';
      return (
        <div
          key={ct.id}
          role="presentation"
          className={`p-3 min-w-4 cursor-pointer ${selectedClassName}`}
          onClick={() => handleChangeTab(ct.id)}
        >
          {ct.title}
        </div>
      );
    }))();

  const tabContentEl = (() => {
    const tabContentIndex = COMPARISON_TABS.findIndex(
      ct => ct.id === selectedTab,
    );
    return COMPARISON_TABS[tabContentIndex].tabContent;
  })();

  return (
    <div className="flex flex-col p-4 h-full">
      <div className="flex flex-col text-black mb-2">
        <h5 className="text-base font-medium capitalize mb-2 flex">
          Completed Vs Cancelled Orders
          <HelpPopover
            isPopoverOpen={isCompletedVsCancelledPopoverOpen}
            position="right"
            isHtmlElement
            content={COMPLETED_VS_CANCELLED_HELP}
            setIsPopoverOpen={setIsCompletedVsCancelledPopoverOpen}
            iconClassNames="sc-information cursor-pointer ml-2"
          />
        </h5>
        {/* <div className="text-sm mb-3">
          Comparison of Number of completed orders and cancelled orders as per
          defined filter type and time period
        </div> */}
      </div>
      <div className="flex items-center mb-4">{tabsEl}</div>
      {tabContentEl}
    </div>
  );
};

CompletedVsCancelledOrderChart.propTypes = {
  selectedTimePeriod: PropTypes.any,
  isTimePeriodFilterStatusChanged: PropTypes.bool,
};

export default CompletedVsCancelledOrderChart;
