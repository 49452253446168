/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState as authInitials } from '../Auth/reducer';
import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;
const selectAuth = state => state.auth || authInitials;

const selectRouter = state => state.router;

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, globalState => globalState.currentUser);

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.error);

const makeSelectLocation = () =>
  createSelector(selectRouter, routerState => routerState.location);

const makeSelectAccessToken = () =>
  createSelector(selectGlobal, globalState => globalState.access_token);

const makeSelectRefreshToken = () =>
  createSelector(selectGlobal, globalState => globalState.refresh_token);

export const makeSelectGlobalProperty = key =>
  createSelector(selectGlobal, globalState => globalState[key]);

export const makeSelectUploadedImages = () =>
  createSelector(selectGlobal, globalState => globalState.uploadedImages);

export const makeSelectAreImagesUploading = () =>
  createSelector(selectGlobal, globalState => globalState.areImagesUploading);

export {
  makeSelectAccessToken,
  makeSelectCurrentUser,
  makeSelectError,
  makeSelectLoading,
  makeSelectLocation,
  makeSelectRefreshToken,
  selectAuth,
  selectGlobal,
};
