import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { SCLoader } from 'rollup-allspark';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readablePrice, centToMoney } from '../../../utils/getCurrency';
import {
  exportedCsvHeaderTitleForXAxis,
  getCumulativeSumForDailyData,
  getCumulativeSumForMonthlyData,
  getCumulativeSumForWeeklyData,
  findDateKey,
} from '../../../utils/nativeAnalyticsUtils';
import {
  MONTHLY_TIME_PERIOD,
  WEEKLY_TIME_PERIOD,
  DEFAULT_CURRENCY_OBJ,
} from '../../../config/constants/analytics';
import {
  formatDate,
  getStartOfWeek,
  formatDateWithoutTimezone,
} from '../../../utils/format-date';
import {
  makeSelectAnalyticsDataLoading,
  makeSelectAnalyticsData,
  makeSelectTimePeriodSlug,
  makeSelectSelectedProvidersForProviderComparison,
} from '../selectors';

const LineChart = ({
  targetKey,
  chartData,
  chartDataLoading,
  seriesName,
  selectedTimePeriod,
  selectedProviderListForProviderComparison,
}) => {
  // CONSTANTS
  const exportedCsvHeaderTitle =
    exportedCsvHeaderTitleForXAxis(selectedTimePeriod);
  const currencyCodeList =
    chartData && chartData.length ? chartData.map(b => b.currency) : [];
  const branchCurrencyObj =
    chartData && chartData.length
      ? {
          code: chartData[0].currency,
          symbol: chartData[0].currency_symbol,
        }
      : '';
  const shouldShowUsd = [...new Set(currencyCodeList)].length > 1;
  const currencyObj = shouldShowUsd ? DEFAULT_CURRENCY_OBJ : branchCurrencyObj;

  // CUMULATIVE DATA
  const getCumulativeSumForSelectedTimePeriod = () => {
    if (selectedTimePeriod === WEEKLY_TIME_PERIOD)
      return getCumulativeSumForWeeklyData(chartData, targetKey);
    if (selectedTimePeriod === MONTHLY_TIME_PERIOD)
      return getCumulativeSumForMonthlyData(chartData, targetKey);
    return getCumulativeSumForDailyData(chartData, targetKey);
  };
  const cumulativeData = getCumulativeSumForSelectedTimePeriod() || [];

  const prepareXaxisData = data => {
    const dateKey = findDateKey([data]);
    if (selectedTimePeriod === WEEKLY_TIME_PERIOD) {
      const reportDate = new Date(data[dateKey]);
      const wStartDate = getStartOfWeek(reportDate);
      return formatDate(wStartDate.toISOString(), `MMM dd ''yy`);
    }
    if (selectedTimePeriod === MONTHLY_TIME_PERIOD) {
      return formatDate(data[dateKey], `MMM ''yy`);
    }
    return formatDateWithoutTimezone(data[dateKey], `MMM dd`);
  };

  // CHART DATA

  const xAxisData = cumulativeData.map(prepareXaxisData) || [];
  const numberOfProviders = selectedProviderListForProviderComparison.length;

  const yAxisData =
    cumulativeData.map(d => centToMoney(d.count / numberOfProviders)) || [];
  const chartConfig = {
    options: {
      tooltip: {
        y: {
          formatter(value) {
            return readablePrice(value * 100, currencyObj);
          },
        },
      },
      chart: {
        targetKey,
        type: 'line',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: targetKey,
              headerCategory: exportedCsvHeaderTitle,
              headerValue: 'value',
            },
            svg: {
              filename: targetKey,
            },
            png: {
              filename: targetKey,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'category',
        categories: xAxisData,
      },
      colors: ['#6A13F4'],
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.1,
          },
        },
      },
      markers: {
        size: 6,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
    },
    series: [
      {
        name: seriesName,
        data: yAxisData,
      },
    ],
  };

  if (chartDataLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <SCLoader lineWidth={5} diameter={50} />
      </div>
    );
  }

  if (chartData && chartData.length) {
    return (
      <Chart
        options={chartConfig.options}
        series={chartConfig.series}
        type="line"
        height="350"
      />
    );
  }

  return (
    <div className="flex justify-center items-center h-full text-2xl text-grey-darker">
      No Data Found!
    </div>
  );
};

LineChart.propTypes = {
  targetKey: PropTypes.string,
  seriesName: PropTypes.string,
  chartData: PropTypes.array,
  chartDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  selectedProviderListForProviderComparison: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  chartDataLoading: makeSelectAnalyticsDataLoading(),
  chartData: makeSelectAnalyticsData(),
  selectedTimePeriod: makeSelectTimePeriodSlug(),
  selectedProviderListForProviderComparison:
    makeSelectSelectedProvidersForProviderComparison(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(LineChart);
