import axios from 'axios';
import * as Sentry from '@sentry/react';
import localStorage from './localStorage';
import {
  authenticationFailed,
  userAuthenticated,
} from '../containers/Auth/actions';
import { API_BASE_URL } from '../config';
import sendNetworkResponseToSentry from '../config/analytics/sentryRequestsConfig';

const baseURL = API_BASE_URL;
export const axiosInstance = axios.create({
  baseURL,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
  },
});

function makeRequest(meta) {
  // console.log('makeRequest');
  const { url, method = 'get', params, headers, data, ...rest } = meta;
  const sessionToken = localStorage.getItem('access_token');

  let requestHeaders = headers || {};

  if (sessionToken) {
    requestHeaders = {
      ...requestHeaders,
      Authorization: `Bearer ${sessionToken}`,
    };
  }

  const httpOptions = {
    method: method.toLowerCase(),
    headers: requestHeaders,
    url,
    params,
    data,
    ...rest,
  };

  return axiosInstance.request(httpOptions);
}

export const setupInterceptor = store =>
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      // Sentry Error Response send
      const errorResponse = error.response;
      if (sendNetworkResponseToSentry(errorResponse)) {
        Sentry.captureException(error);
      }

      if (isTokenExpiredError(errorResponse)) {
        return resetTokenAndReattemptRequest(error, store);
      }
      // If the error is due to other reasons, we just throw it back to axios
      return Promise.reject(error);
    },
  );

function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
  return errorResponse.status === 401;
}
let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error, store) {
  try {
    const { response: errorResponse } = error;
    const resetToken = await localStorage.getItem('refresh_token'); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!resetToken) {
      // We can't refresh, throw the error anyway
      store.dispatch(authenticationFailed(error));
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
      /* We need to add the request retry to the queue
      since there another request that already attempt to
      refresh the token */
      addSubscriber(() => {
        resolve(makeRequest(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios({
        method: 'post',
        url: `${baseURL}/v1/token/refresh`,
        data: {
          refresh_token: resetToken, // Just an example, your case may vary
        },
      });
      if (!response.data) {
        /* Refresh token failed. So clean auth data and logout the user */
        store.dispatch(authenticationFailed(response));
        return Promise.reject(error);
      }
      const newToken = response.data;
      store.dispatch(userAuthenticated(newToken));
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched();
    }
    return retryOriginalRequest;
  } catch (err) {
    store.dispatch(authenticationFailed(err));
    return Promise.reject(err);
  }
}

function onAccessTokenFetched() {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback());
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default makeRequest;
