import { createSelector } from 'reselect';
import { initialState } from './reducer';

// INITIALIZATION
const selectRatingAndReviewDomain = state =>
  state.ratingAndReview || initialState;

// Common selector to get the property from the state
export const makeSelectProperty = key =>
  createSelector(selectRatingAndReviewDomain, substate => substate[key]);
