import { saveAs } from 'file-saver';
export const convertJsonToCsv = jsonData => {
  const data = typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData;
  let csv = '';
  csv += '\uFEFF';
  for (let i = 0; i < data.length; i += 1) {
    const values = Object.values(data[i]).map(value => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`; // Enclose value in double quotes if it contains a comma
      }
      return value;
    });
    csv += `${values.join(',')}\n`;
  }
  return csv;
};

export const downloadCsvFile = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, `${fileName}.csv`);
};

export const downloadCsvFromJSON = (data, fileName) => {
  const csv = convertJsonToCsv(data);
  downloadCsvFile(csv, fileName);
};
