import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { APP_SAGA } from '../../config/constants/app';
import {
  LOAD_FAILED_NO_INTERNET_MSG,
  UPLOAD_FAILED_NO_INTERNET_MSG,
} from '../../config/constants/errorMessages';
import { isFunction, printf } from '../../utils';
import makeRequest from '../../utils/api';
import {
  GET_LIST_FAILED,
  GET_LIST_SUCCESS,
  LOAD_LIST,
  LOAD_PRICING_GROUPS,
  LOAD_PRICING_GROUPS_FAILED,
  LOAD_PRICING_GROUPS_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_RESOLVED,
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_FAILED,
  UPLOAD_IMAGES_SUCCESS,
} from './constants';

function* getList({ params }) {
  const { reducerKey, callbackFn } = params || {};

  const URL_CONFIG = {
    [APP_SAGA.BUSINESS_LIST.KEY]: APP_SAGA.BUSINESS_LIST.GET,
    [APP_SAGA.BRAND_LIST.KEY]: APP_SAGA.BRAND_LIST.GET,
    [APP_SAGA.BRANCH_LIST.KEY]: APP_SAGA.BRANCH_LIST.GET,
    [APP_SAGA.COUNTRY_LIST.KEY]: APP_SAGA.COUNTRY_LIST.GET,
    [APP_SAGA.CURRENCY_LIST.KEY]: APP_SAGA.CURRENCY_LIST.GET,
    [APP_SAGA.ONI_PAYMENT_METHODS.KEY]: APP_SAGA.ONI_PAYMENT_METHODS.GET,
    [APP_SAGA.ENT_PAYMENT_METHODS.KEY]: APP_SAGA.ENT_PAYMENT_METHODS.GET,
    [APP_SAGA.PROVIDER_LIST.KEY]: APP_SAGA.PROVIDER_LIST.GET,
    [APP_SAGA.SUBSCRIPTION_TYPE_LIST.KEY]: APP_SAGA.SUBSCRIPTION_TYPE_LIST.GET,
  };

  try {
    const payload = {
      url: URL_CONFIG[reducerKey],
      method: 'GET',
      params: params.params,
    };
    const res = yield call(makeRequest, payload);
    const list = res.data.results || res.data || [];

    if (isFunction(callbackFn)) {
      callbackFn(list, res);
      return;
    }

    yield put({
      type: GET_LIST_SUCCESS,
      payload: {
        key: reducerKey,
        value: list,
      },
    });
  } catch (err) {
    yield put({
      type: GET_LIST_FAILED,
      payload: {
        key: reducerKey,
      },
    });
    const { message } = err?.response?.data || {};
    toast.error(message || 'Failed to load');
  }
}

function* uploadFile({ params }) {
  const { img, params: queryParams, callbackFn } = params || {};

  try {
    const formData = new FormData();
    formData.append('file', img);
    formData.append('type', 'img');
    const payload = {
      url: APP_SAGA.FILE_UPLOAD.POST,
      method: 'POST',
      params: queryParams,
      data: formData,
    };

    const res = yield call(makeRequest, payload);

    if (isFunction(callbackFn)) {
      callbackFn(res.data.path);
    }
    yield put({
      type: UPLOAD_IMAGE_RESOLVED,
    });
  } catch (error) {
    yield put({
      type: UPLOAD_IMAGE_RESOLVED,
    });
    const { message } = error?.response?.data || {};
    toast.error(message || printf(UPLOAD_FAILED_NO_INTERNET_MSG, 'image'));
  }
}

function* uploadImages({ params }) {
  const { files, callbackFn, errorCallbackFn } = params;
  try {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('type', 'img');

    const payload = {
      url: 'v2/uploads',
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = yield call(makeRequest, payload);

    const { data } = response;
    const imagePaths = data.map(image => image.path);

    if (isFunction(callbackFn)) {
      callbackFn(imagePaths);
    }

    yield put({
      type: UPLOAD_IMAGES_SUCCESS,
      uploadedImages: imagePaths,
    });
  } catch (error) {
    if (isFunction(errorCallbackFn)) {
      errorCallbackFn();
    }
    yield put({ type: UPLOAD_IMAGES_FAILED, error });
    toast.error(printf(UPLOAD_FAILED_NO_INTERNET_MSG, 'Images'));
  }
}

function* getPricingGroups(meta) {
  try {
    const payload = {
      url: 'v1/price/groups',
      method: 'get',
      params: meta.params,
    };

    const response = yield call(makeRequest, payload);

    yield put({
      type: LOAD_PRICING_GROUPS_SUCCESS,
      priceGroups: {
        list: response.data,
      },
    });
  } catch (err) {
    yield put({ type: LOAD_PRICING_GROUPS_FAILED, err });
    toast.error(printf(LOAD_FAILED_NO_INTERNET_MSG, 'Pricing Groups'));
  }
}

export default function* appSaga() {
  yield takeEvery(LOAD_LIST, getList);
  yield takeLatest(UPLOAD_IMAGE, uploadFile);
  yield takeLatest(UPLOAD_IMAGES, uploadImages);
  yield takeLatest(LOAD_PRICING_GROUPS, getPricingGroups);
}
