import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { getDetails } from '../../actions';
import { useInjectReducer } from '../../../../utils/injectReducer';
import reducer from '../../reducer';
import { useInjectSaga } from '../../../../utils/injectSaga';
import saga from '../../saga';
import { isEmpty } from '../../../../utils';
import { RATING_REVIEW_SAGA } from '../../../../config/constants/rating-and-review';
import { makeSelectProperty } from '../../selectors';

function FacebookRedirect({ handleGetDetails, facebookLoginDetails }) {
  useInjectReducer({ key: 'ratingAndReview', reducer });
  useInjectSaga({ key: 'ratingAndReview', saga });

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.hash?.slice(1));

  const longLivedToken = searchParams.get('long_lived_token');
  const state = searchParams.get('state');

  useEffect(() => {
    // if longLivedToken and state is present then call for Facebook Page Details
    if (!isEmpty(longLivedToken) && !isEmpty(state)) {
      const { FACEBOOK } = RATING_REVIEW_SAGA.INTEGRATION_DETAILS;

      handleGetDetails({
        url: FACEBOOK.LOGIN_DETAILS.GET,
        params: {
          long_lived_token: longLivedToken,
          state,
        },
        reducerKey: FACEBOOK.LOGIN_DETAILS.KEY,
      });
    }
  }, [longLivedToken, state]);

  useEffect(() => {
    // if facebookLoginDetails is present then pass page details to parent window
    if (!isEmpty(facebookLoginDetails)) {
      if (window.opener && window.opener.getFacebookLoginDetails) {
        window.opener.getFacebookLoginDetails(facebookLoginDetails);
      }
      window.close();
    }
  }, [facebookLoginDetails]);

  return null;
}

FacebookRedirect.propTypes = {
  handleGetDetails: PropTypes.func,
  facebookLoginDetails: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  facebookLoginDetails: makeSelectProperty('facebookLoginDetails'),
});

function mapDispatchToProps(dispatch) {
  return {
    handleGetDetails: params => dispatch(getDetails(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(FacebookRedirect);
