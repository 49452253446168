export const PROVIDER_FORM = {
  LABEL: {
    CLIENT_ID_LABEL: {
      DEFAULT: 'Client id / Username / Email / App key',
      FOODPANDA: 'Foodpanda vendor username',
      DELIVEROO: 'Deliveroo Vendor username',
      ODDLE: 'Oddle Vendor username',
      UBEREATS: 'Uber eats Vendor username',
      SHOPEE: 'Shopee username',
      DOORDASH: 'Doordash email',
      PICKAROO: 'Pickaroo email',
      GRABFOOD: 'Grabfood username',
      WOLT: 'Wolt Menu API Username',
      DEMAECAN: 'Demaecan vendor username',
    },
    CLIENT_SECRET_LABEL: {
      DEFAULT: 'Client secret / Password',
      FOODPANDA: 'Foodpanda vendor password',
      DELIVEROO: 'Deliveroo Vendor password',
      ODDLE: 'Oddle Vendor password',
      UBEREATS: 'Uber eats Vendor password',
      SHOPEE: 'Shopee password',
      DOORDASH: 'Doordash password',
      PICKAROO: 'Pickaroo password',
      GRABFOOD: 'Grabfood password',
      WOLT: 'Wolt Menu API Password',
      DEMAECAN: 'Demaecan vendor password',
    },
    ADDITIONAL_SECRET_LABEL: {
      DEFAULT: 'Additional secret',
      WOLT: 'Wolt Order API Key',
    },
    PROVIDER_STORE_ID_LABEL: {
      DEFAULT: 'Aggregator store ID',
      FOODPANDA: 'Foodpanda outlet ID',
      GRABFOOD: 'Grabfood store ID',
      DELIVEROO: 'Deliveroo Store ID',
      UBEREATS: 'UberEats Store ID',
      WOLT: 'Wolt Venue ID',
      GOFOOD: 'GoFood store ID',
    },
    CHAIN_ID_LABEL: 'Foodpanda chain ID',
    LOCATION_ID_LABEL: 'Location ID',
    GLOBAL_ID_LABEL: 'Global ID',
    KLIKIT_STORE_ID_LABEL: 'Klikit store ID',
    GET_MENU_ENDPOINT_LABEL: 'Grabfood menu endpoint',
    SUBMIT_ORDER_ENDPOINT_LABEL: 'Grabfood submit order endpoint',
    PUSH_ORDER_STATE_ENDPOINT_LABEL: 'Grabfood push order endpoint',
    OAUTH_TOKEN_ENDPOINT_LABEL: 'Grabfood oauth token endpoint',
    OAUTH_SCOPES_LABEL: 'Grabfood oauth scopes',
    PARTNER_CLIENT_ID_LABEL: 'Grabfood partner client ID',
    PARTNER_CLIENT_SECRET_LABEL: 'Grabfood partner client secret',
    GOFOOD_LOGIN_URL: 'Gojek login url',
    UBEREATS_LOGIN_URL: 'UE Login Url',
    SQUARE_LOGIN_URL: 'Square login url',
  },
  PLACEHOLDER: {
    CLIENT_ID_PLACEHOLDER: {
      DEFAULT: 'Type your client id / username / email / app key',
      FOODPANDA: 'Type your foodpanda vendor username',
      DELIVEROO: 'Type your deliveroo vendor username',
      ODDLE: 'Type your oddle vendor username',
      UBEREATS: 'Type your uber eats vendor username',
      SHOPEE: 'Type your shopee username',
      DOORDASH: 'Type your doordash email',
      PICKAROO: 'Type your pickaroo email',
      GRABFOOD: 'Type your grab food username',
      WOLT: 'Type your wolt username',
      DEMAECAN: 'Type your demaecan vendor username',
    },
    CLIENT_SECRET_PLACEHOLDER: {
      DEFAULT: 'Type your client secret / password',
      FOODPANDA: 'Type your foodpanda vendor password',
      DELIVEROO: 'Type your deliveroo vendor password',
      ODDLE: 'Type your oddle vendor password',
      UBEREATS: 'Type your uber eats vendor password',
      SHOPEE: 'Type your shopee password',
      DOORDASH: 'Type your doordash password',
      PICKAROO: 'Type your pickaroo password',
      GRABFOOD: 'Type your grab food password',
      WOLT: 'Type your wolt password',
      DEMAECAN: 'Type your demaecan vendor password',
    },
    ADDITIONAL_SECRET_PLACEHOLDER: {
      DEFAULT: 'Additional secret',
      WOLT: 'API Token',
    },
    PROVIDER_STORE_ID_PLACEHOLDER: {
      DEFAULT: 'Type your provider store ID',
      FOODPANDA: 'Type foodpanda outlet ID',
      GRABFOOD: 'Type your grabfood store ID',
      UBEREATS: 'Type your uberEats store ID',
      GOFOOD: 'Type your GoFood store ID',
      WOLT: 'Type your Wolt venue ID',
    },
    CHAIN_ID_PLACEHOLDER: 'Type your foodpanda chain ID',
    LOCATION_ID_PLACEHOLDER: 'Type your location ID',
    GLOBAL_ID_PLACEHOLDER: 'Type your global ID',
  },
};

export const PROVIDER_FORM_INITIAL_VALUES = {
  email: null,
  password: null,
  provider_store_id: null,
  go_live_date: null,
  is_interceptor: false,
  menu_sync_enabled: false,
  auto_accept: false,
  otp: '',
  toc_password: '',
  additional_secret: '',
  klikit_store_id: '',
  provider_location_id: '',
  provider_chain_id: '',
  phone_number: '',
  provider_global_id: '',
  preparation_time: 15,
  auto_ready: false,
  provider_brand_id: '',
};
