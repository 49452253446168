import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SCLoader } from 'rollup-allspark';
import * as selectors from './selectors';
import SummaryCard from './SummaryCard';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import {
  getAggregatedSummary,
  getStackedDonutChartDataForColors,
  getStackedDonutChartDataForLabels,
  getStackedDonutChartDataForSeries,
} from '../../utils/nativeAnalyticsUtils';
import {
  ORDER_CANCELLATION_RATE_KEY,
  ORDER_COUNT_KEYS,
  REVENUE_KEYS,
} from '../../config/constants/analytics';
import HelpPopover from '../../components/HelpPopover';
import { SUMMARY_HELP } from '../../config/HelpPopoverTexts/nativeAnalyticsPopoverTexts';

const Summary = ({
  summary,
  comparisonEnabled,
  analyticsProviders,
  summaryLoading,
}) => {
  useInjectReducer({ key: 'nativeAnalytics', reducer });
  useInjectSaga({ key: 'nativeAnalytics', saga });
  const [initialSummaryData, setInitialSummaryData] = useState([]);

  useEffect(() => {
    setInitialSummaryData(summary && summary.summary ? summary.summary : []);
  }, [summary]);

  const summaryData = getAggregatedSummary(initialSummaryData);

  const currencyObj = {
    code: summaryData.currency,
    symbol: summaryData.currency_symbol,
  };
  const donutChartDataForColors = comparisonEnabled
    ? getStackedDonutChartDataForColors(initialSummaryData)
    : ['#6a13f4'];
  const [isSummaryPopoverOpen, setIsSummaryPopoverOpen] = useState(false);

  const handleDonutChartSeries = key => {
    if (comparisonEnabled) {
      return getStackedDonutChartDataForSeries(initialSummaryData, key);
    }
    const cancelled = summaryData.cancelled_orders || [];
    const total = summaryData.total_orders || [];

    if (key === ORDER_COUNT_KEYS.TOTAL_ORDERS) {
      return total;
    }

    if (key === ORDER_CANCELLATION_RATE_KEY) {
      const cancellationRate = cancelled.map((cancelledOrder, i) => {
        const totalOrder = total[i] || 0;
        return totalOrder === 0 ? 0 : (cancelledOrder / totalOrder) * 100;
      });
      return cancellationRate;
    }

    return summaryData[key] || [];
  };

  const handleDonutChartLabel = label => {
    if (comparisonEnabled) {
      return getStackedDonutChartDataForLabels(
        initialSummaryData,
        analyticsProviders,
      );
    }
    return [label];
  };

  const renderLoader = (
    <div className="flex items-center justify-center h-48 w-full">
      <SCLoader lineWidth={5} diameter={50} />
    </div>
  );

  const renderAggregatedSummaryChart2 = (
    <div className="flex w-full gap-x-2">
      <div className="w-1/6 bg-grey-lighter rounded">
        <SummaryCard
          title="Completed Orders"
          count={handleDonutChartSeries(ORDER_COUNT_KEYS.COMPLETED_ORDERS)}
          chartLabel={handleDonutChartLabel('Completed Orders')}
          chartColors={donutChartDataForColors}
          chartKey={ORDER_COUNT_KEYS.COMPLETED_ORDERS}
        />
      </div>
      <div className="w-1/6 bg-grey-lighter rounded">
        <SummaryCard
          title="Cancelled Orders"
          count={handleDonutChartSeries(ORDER_COUNT_KEYS.CANCELLED_ORDERS)}
          chartLabel={handleDonutChartLabel('Cancelled Orders')}
          chartColors={donutChartDataForColors}
          chartKey={ORDER_COUNT_KEYS.CANCELLED_ORDERS}
        />
      </div>
      <div className="w-1/6 bg-grey-lighter rounded">
        <SummaryCard
          title="Total Orders"
          count={handleDonutChartSeries(ORDER_COUNT_KEYS.TOTAL_ORDERS)}
          chartLabel={handleDonutChartLabel('Total Orders')}
          chartColors={donutChartDataForColors}
          chartKey={ORDER_COUNT_KEYS.TOTAL_ORDERS}
        />
      </div>
      <div className="w-1/6 bg-grey-lighter rounded">
        <SummaryCard
          title="Gross Order Value"
          count={handleDonutChartSeries(REVENUE_KEYS.REALIZED_REVENUE)}
          chartLabel={handleDonutChartLabel('Gross Order Value')}
          chartColors={donutChartDataForColors}
          currencyObj={currencyObj}
          chartKey={REVENUE_KEYS.REALIZED_REVENUE}
        />
      </div>
      <div className="w-1/6 bg-grey-lighter rounded">
        <SummaryCard
          title="Lost Revenue"
          count={handleDonutChartSeries(REVENUE_KEYS.LOST_REVENUE)}
          chartLabel={handleDonutChartLabel('Lost Revenue')}
          chartColors={donutChartDataForColors}
          currencyObj={currencyObj}
          chartKey={REVENUE_KEYS.LOST_REVENUE}
        />
      </div>
      <div className="w-1/6 bg-grey-lighter rounded">
        <SummaryCard
          title="Cancellation Rate"
          count={handleDonutChartSeries(ORDER_CANCELLATION_RATE_KEY)}
          chartLabel={handleDonutChartLabel('Cancellation Rate')}
          chartColors={donutChartDataForColors}
          chartKey={ORDER_CANCELLATION_RATE_KEY}
        />
      </div>
    </div>
  );
  return (
    <div className="w-full bg-white min-h p-4 rounded">
      <div className="w-full flex mb-4">
        <h5 className="flex">
          <div className="text-base font-medium capitalize mr-2">Summary</div>
          <HelpPopover
            isPopoverOpen={isSummaryPopoverOpen}
            position="right"
            isHtmlElement
            content={SUMMARY_HELP}
            setIsPopoverOpen={setIsSummaryPopoverOpen}
            iconClassNames="sc-information cursor-pointer"
          />
        </h5>
      </div>
      {summaryLoading ? renderLoader : renderAggregatedSummaryChart2}
    </div>
  );
};

Summary.propTypes = {
  summary: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  comparisonEnabled: PropTypes.bool,
  analyticsProviders: PropTypes.array,
  summaryLoading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  summary: selectors.makeSelectSummary(),
  comparisonEnabled: selectors.makeSelectComparisonEnabled(),
  analyticsProviders: selectors.makeSelectAnalyticsProviders(),
  summaryLoading: selectors.makeSelectSummaryLoading(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Summary);
