import { produce } from 'immer';
import { LOGOUT_DONE } from '../Auth/constants';
import {
  GET_SETTINGS_LIST,
  GET_SETTINGS_LIST_FAILED,
  GET_SETTINGS_LIST_SUCCESS,
} from '../Reservation/constants';
import {
  CHANGE_ENTITY,
  CREATE_SETTINGS,
  DELETE_SETTINGS,
  GET_DETAILS,
  GET_DETAILS_FAILED,
  GET_DETAILS_SUCCESS,
  GET_LIST,
  GET_LIST_FAILED,
  GET_LIST_SUCCESS,
  SETTINGS_REJECTED,
  SETTINGS_RESOLVED,
} from './constants';

export const initialState = {
  businessList: [],
  selectedBusiness: null,
  brandList: [],
  selectedBrand: null,
  branchList: null,
  selectedBranch: null,
  listLoading: false,
  detailsLoading: false,
  showDetailForm: false,
  selectedProvider: null,
  googleIntegrationDetails: null,
  facebookAuthDialogUrl: null,
  randomTest: null,
  facebookIntegrationDetails: null,
  facebookLoginDetails: null,
  ratingProviderList: [],
};

const ratingAndReviewReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LIST:
        draft.listLoading = true;
        draft.showDetailForm = initialState.showDetailForm;
        break;
      case GET_LIST_SUCCESS:
        draft.listLoading = false;
        draft[action.payload.key] = action.payload.value;
        break;
      case GET_LIST_FAILED:
        draft.listLoading = false;
        draft[action.payload.key] = initialState[action.payload.key];
        break;
      case CHANGE_ENTITY:
        draft[action.payload.key] = action.payload.value;
        break;
      case LOGOUT_DONE:
        break;
      case GET_DETAILS:
        draft.detailsLoading = true;
        break;
      case GET_DETAILS_SUCCESS:
        draft.detailsLoading = false;
        draft[action.payload.key] = action.payload.value;
        break;
      case GET_DETAILS_FAILED:
        draft.detailsLoading = false;
        draft[action.payload.key] = initialState[action.payload.key];
        draft.googleIntegrationDetails = initialState.googleIntegrationDetails;
        break;
      case CREATE_SETTINGS:
        draft.detailsLoading = true;
        break;
      case DELETE_SETTINGS:
        draft.detailsLoading = true;
        draft.showDetailForm = initialState.showDetailForm;
        break;
      case SETTINGS_RESOLVED:
        draft.detailsLoading = false;
        break;
      case SETTINGS_REJECTED:
        draft.detailsLoading = false;
        break;
      default:
        break;
    }
  });
export default ratingAndReviewReducer;
