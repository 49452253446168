import { isEmpty } from '../utils';

export const {
  VITE_APP_KLIKIT_API_BASE_URL: KLIKIT_API_BASE_URL,
  VITE_APP_KLIKIT_CDN_BASE_URL: KLIKIT_CDN_BASE_URL,
  VITE_APP_KLIKIT_SOCKET_BASE_URL: KLIKIT_SOCKET_BASE_URL,
  VITE_APP_SENTRY_ENV: SENTRY_ENV,
  VITE_APP_GRABFOOD_PARTNER_CLIENT_ID: GRABFOOD_PARTNER_CLIENT_ID,
  VITE_APP_GRABFOOD_PARTNER_CLIENT_SECRET: GRABFOOD_PARTNER_CLIENT_SECRET,
  VITE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID: GOOGLE_ANALYTICS_MEASUREMENT_ID,
  VITE_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID,
  VITE_APP_VIZ_MENU_LOCATION_QR_VISIBILITY: VIZ_MENU_LOCATION_QR_VISIBILITY,

  VITE_APP_VIZ_MENU_BASE_URL: VIZ_MENU_BASE_URL,
  VITE_APP_RESERVATION_BASE_URL: RESERVATION_BASE_URL,
  VITE_APP_WEBSITE_BUILDER_BASE_URL: WEBSITE_BUILDER_BASE_URL,
  VITE_APP_LINK_IN_BIO_BASE_URL: LINK_IN_BIO_BASE_URL,

  VITE_APP_WHITELABEL_WEBSHOP_FREE_BASE_URL: WHITELABEL_WEBSHOP_FREE_BASE_URL,
  VITE_APP_WHITELABEL_RESERVATION_FREE_BASE_URL:
    WHITELABEL_RESERVATION_FREE_BASE_URL,
  VITE_APP_WHITELABEL_WEBSITE_BUILDER_FREE_BASE_URL:
    WHITELABEL_WEBSITE_BUILDER_FREE_BASE_URL,
  VITE_APP_WHITELABEL_LINK_IN_BIO_FREE_BASE_URL:
    WHITELABEL_LINK_IN_BIO_FREE_BASE_URL,

  VITE_APP_WHITELABEL_WEBSHOP_PAID_URL: WHITELABEL_WEBSHOP_PAID_URL,
  VITE_APP_WHITELABEL_RESERVATION_PAID_URL: WHITELABEL_RESERVATION_PAID_URL,
  VITE_APP_WHITELABEL_WEBSITE_BUILDER_PAID_URL:
    WHITELABEL_WEBSITE_BUILDER_PAID_URL,
  VITE_APP_WHITELABEL_LINK_IN_BIO_PAID_URL: WHITELABEL_LINK_IN_BIO_PAID_URL,

  VITE_APP_WIDGET_SCRIPT_PATH: WIDGET_SCRIPT_PATH,
  VITE_APP_SENTRY_DSN_URL: SENTRY_DSN_URL,
  VITE_APP_CAMPAIGN_START_TIME_BUFFER_IN_MINS: CAMPAIGN_START_TIME_BUFFER_MINS,
  VITE_APP_CRM_USERS: CRM_USERS,
  VITE_APP_SUPER_ADMIN_USERS: SUPER_ADMIN_USERS,
  VITE_APP_AGGREGATOR_V2_PROVIDERS: AGGREGATOR_V2_PROVIDERS,
  VITE_APP_ONI_REFRESH_INTERVAL,
} = import.meta.env || {}; // Read from .env file using ViteJS's import.meta.env

export const ONI_REFRESH_INTERVAL =
  parseInt(VITE_APP_ONI_REFRESH_INTERVAL, 10) || 30000;

export const IS_VIZ_QR_VISIBLE = VIZ_MENU_LOCATION_QR_VISIBILITY === 'true';

// ============================================================
export const ITEM_STOCK_OUT_SNOOZE_MAX_HOURS = 8760; // 1 Year in hours
export const ITEM_STOCK_OUT_DEFAULT_DURATION = 0; // 0 for until turn back on

export const ADDITIONAL_TIME_FOR_GO_LIVE_DATE = 5 * 60 * 1000; // in minute
export const SHOW_PRICE_MISMATCH_WARNING_OF_BRANCH = true;

export const IS_MENU_TAB_DISABLED = false;

export const IS_CONTINUOUSLY_FETCH_ORDER = true;

export const AGGREGATOR_V2_IDS = (() => {
  if (isEmpty(AGGREGATOR_V2_PROVIDERS)) {
    return [];
  }

  const arr = AGGREGATOR_V2_PROVIDERS.split(',');

  return arr.map(p => parseInt(p, 10));
})();

export const PROVIDERS = {
  KLIKIT: 1,
  UBEREATS: 2,
  DELIVEROO: 3,
  JUSTEAT: 4,
  DOORDASH: 5,
  GRABFOOD: 6,
  FOODPANDA: 7,
  SHOPIFY: 8,
  GOFOOD: 9,
  ODDLE: 10,
  SHOPEE: 11,
  DEMAECAN: 12,
  PICKAROO: 13,
  WOLT: 14,
  SQUARE: 15,
  TIKTOK: 16,
};

export const POS_PROVIDER_IDS = {
  ESB: 2,
};

export const HIDDEN_VISIBLE_STATUS = {
  ALL_HIDDEN: 'ALL_HIDDEN',
  ALL_VISIBLE: 'ALL_VISIBLE',
  MIXED: 'MIXED',
};

export const SOCKET_EVENT = {
  KLIKIT_EVENT: 'order_placed',
  THIRD_PARTY_EVENT: 'tpp_order_placed',
  ORDER_CANCEL_EVENT: 'order_cancelled',
};

export const GRABFOOD_CONFIGURATION = {
  GET_MENU_ENDPOINT: `${KLIKIT_API_BASE_URL}/v1/grabit/merchant/menu`,
  SUBMIT_ORDER_ENDPOINT: `${KLIKIT_API_BASE_URL}/v1/grabit/order`,
  PUSH_ORDER_STATE_ENDPOINT: `${KLIKIT_API_BASE_URL}/v1/grabit/order/state`,
  OAUTH_TOKEN_ENDPOINT: `${KLIKIT_API_BASE_URL}/v1/grabit/oauth/token`,
  OAUTH_SCOPES: 'grab.all',
  PARTNER_CLIENT_ID: GRABFOOD_PARTNER_CLIENT_ID,
  PARTNER_CLIENT_SECRET: GRABFOOD_PARTNER_CLIENT_SECRET,
};
