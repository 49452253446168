import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the auth state domain
 */

const selectAuthDomain = state => state.auth || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Auth
 */

export const makeSelectUserName = () =>
  createSelector(selectAuthDomain, substate => substate.username);
export const makeSelectPassword = () =>
  createSelector(selectAuthDomain, substate => substate.password);
export const makeSelectError = () =>
  createSelector(selectAuthDomain, substate => substate.loginError);
export const makeSelectLoading = () =>
  createSelector(selectAuthDomain, substate => substate.loading);
