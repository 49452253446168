/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import { produce } from 'immer';
import jwtDecode from 'jwt-decode';
import localStorage from '../../utils/localStorage';
import { AUTH_FAILED, AUTH_SUCCESS, LOGOUT_DONE } from '../Auth/constants';
import { UPDATE_PROFILE_SUCCESS } from '../Profile/constants';
import {
  GET_LIST_SUCCESS,
  LOAD_PRICING_GROUPS,
  LOAD_PRICING_GROUPS_FAILED,
  LOAD_PRICING_GROUPS_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_RESOLVED,
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_FAILED,
  UPLOAD_IMAGES_SUCCESS,
} from './constants';
// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  currentUser: localStorage.getItem('current_user') || {},
  refresh_token: localStorage.getItem('refresh_token'),
  access_token: localStorage.getItem('access_token'),
  businessList: [],
  providerList: [],
  oniPaymentMethodsList: [],
  isFileUploading: false,
  uploadedImages: [],
  priceGroups: [],
  isPriceGroupsLoading: false,
  areImagesUploading: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  /* eslint-disable no-case-declarations */
  /* eslint-disable camelcase */
  produce(state, draft => {
    switch (action.type) {
      case AUTH_SUCCESS:
        const now = new Date().getTime();
        const decoded = jwtDecode(action.token.access_token);
        const decoded_refresh = jwtDecode(action.token.refresh_token);
        const ttl = decoded.exp * 1000 - now;
        const ttl_refresh = decoded_refresh.exp * 1000 - now;
        draft.refresh_token = action.token.refresh_token;
        draft.access_token = action.token.access_token;
        draft.currentUser = action.token.user;
        localStorage.setItem('current_user', draft.currentUser, ttl);
        localStorage.setItem('access_token', action.token.access_token, ttl);
        localStorage.setItem(
          'refresh_token',
          action.token.refresh_token,
          ttl_refresh,
        );
        break;
      case UPDATE_PROFILE_SUCCESS:
        draft.currentUser = action.currentUser;
        break;
      case AUTH_FAILED:
        draft = logoutUser(draft);
        break;
      case LOGOUT_DONE:
        draft = logoutUser(draft);
        break;
      case GET_LIST_SUCCESS:
        draft[action.payload.key] = action.payload.value;
        break;
      case LOAD_PRICING_GROUPS:
        draft.isPriceGroupsLoading = true;
        break;
      case LOAD_PRICING_GROUPS_SUCCESS:
        draft.isPriceGroupsLoading = false;
        draft.priceGroups = action.priceGroups.list;
        break;
      case LOAD_PRICING_GROUPS_FAILED:
        draft.isPriceGroupsLoading = false;
        draft.priceGroups = [];
        break;
      case UPLOAD_IMAGE:
        draft.isFileUploading = true;
        break;
      case UPLOAD_IMAGE_RESOLVED:
        draft.isFileUploading = initialState.isFileUploading;
        break;
      case UPLOAD_IMAGES:
        draft.areImagesUploading = true;
        break;
      case UPLOAD_IMAGES_SUCCESS:
        draft.areImagesUploading = initialState.areImagesUploading;
        draft.uploadedImages = action.uploadedImages;
        break;
      case UPLOAD_IMAGES_FAILED:
        draft.areImagesUploading = initialState.areImagesUploading;
        draft.uploadedImages = initialState.uploadedImages;
        break;
    }
  });

const logoutUser = draft => {
  draft.access_token = '';
  draft.refresh_token = '';
  draft.currentUser = {};
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('current_user');
  return draft;
};
export default appReducer;
