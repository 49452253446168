import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import { format } from 'date-fns';
import { SCButton } from 'rollup-allspark';
import { makeSelectProperty } from '../selectors';
import PerformanceTable from './PerformanceTable';
import {
  generatePerformanceCSVData,
  sortAggregatedDataRow,
} from '../../../utils/nativeAnalyticsUtils';
import { editField } from '../actions';
import { downloadCsvFromJSON } from '../../../utils/sheetUtils';
import { isEmpty } from '../../../utils';

const BrandPerformance = ({ brandPerformanceData, updateField }) => {
  const { performanceRowData } = brandPerformanceData;

  const handleSortChange = (sortBy, sortOrder) => {
    const sortedPerformanceRowData = sortAggregatedDataRow(
      performanceRowData,
      sortBy,
      sortOrder,
    );
    updateField({
      brandPerformanceData: {
        ...brandPerformanceData,
        performanceRowData: sortedPerformanceRowData,
      },
    });
  };

  const handleCSVDownload = () => {
    const csvHeader = {
      name: 'Brand',
      net_sales: 'Net Sales',
      net_sales_cent: 'Net Sales (In Cent)',
      total_orders: 'Total Orders',
    };
    const CSVData = generatePerformanceCSVData(csvHeader, brandPerformanceData);

    downloadCsvFromJSON(
      CSVData,
      `brand-performance-${format(new Date(), 'yyyy-mm-dd hh:mm')}`,
    );
  };

  const downloadCSVButton = !isEmpty(performanceRowData) ? (
    <SCButton
      label="Download"
      variant="primary-outline"
      size="sm"
      className="text-xs self-end"
      action={handleCSVDownload}
    />
  ) : null;

  return (
    <div className="flex flex-col gap-y-4">
      {downloadCSVButton}
      <PerformanceTable
        title="Brand"
        performanceData={brandPerformanceData}
        onSortChange={handleSortChange}
      />
    </div>
  );
};

BrandPerformance.propTypes = {
  brandPerformanceData: PropTypes.object,
  updateField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  brandPerformanceData: makeSelectProperty('brandPerformanceData'),
});

const mapDispatchToProps = dispatch => ({
  updateField: params => dispatch(editField(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BrandPerformance);
