export const APP_SAGA = {
  BUSINESS_LIST: {
    KEY: 'businessList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/business',
  },
  BRAND_LIST: {
    KEY: 'brandList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/brand',
  },
  BRANCH_LIST: {
    KEY: 'branchList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/branch',
  },
  COUNTRY_LIST: {
    KEY: 'countryList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/countries',
  },
  CITY_DETAILS: {
    reducerKey: 'cityDetails',
    url: cityId => `v1/cities/${cityId}`,
  },
  CURRENCY_LIST: {
    KEY: 'currencyList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/currencies',
  },
  FILE_UPLOAD: {
    KEY: 'fileUpload',
    LOADER_KEY: 'isFileUploading',
    ACTION_TYPE: 'FILE_UPLOAD',
    POST: 'v1/upload',
  },
  ONI_PAYMENT_METHODS: {
    KEY: 'oniPaymentMethodsList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/oni/payment-channels',
  },
  ENT_PAYMENT_METHODS: {
    KEY: 'entPaymentMethodsList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/payment/enterprise/payment-channels',
  },
  PROVIDER_LIST: {
    KEY: 'providerList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: 'v1/provider',
  },
  SUBSCRIPTION_TYPE_LIST: {
    KEY: 'subscriptionTypeList',
    ACTION_TYPE: 'LOAD_LIST',
    GET: '/v1/subscription-types',
  },
};
