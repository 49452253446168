import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import LineChart from './LineChart';
import {
  AVG_BASKET_KEYS,
  COMPARISON_TYPE,
} from '../../../config/constants/analytics';
import StackedLineChart from './StackedLineChart';
const CancelledBasketSizeLineChart = ({ selectedComparisonType }) => {
  const renderChart = Object.values(COMPARISON_TYPE).includes(
    selectedComparisonType,
  ) ? (
    <StackedLineChart targetKey={AVG_BASKET_KEYS.CANCELLED_BASKET_SIZE} />
  ) : (
    <LineChart
      targetKey={AVG_BASKET_KEYS.CANCELLED_BASKET_SIZE}
      seriesName="Completed Basket Size"
    />
  );
  return renderChart;
};
CancelledBasketSizeLineChart.propTypes = {
  selectedComparisonType: PropTypes.string,
};
const mapStateToProps = createStructuredSelector({
  selectedComparisonType: selectors.makeSelectSelectedComparisonType(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(CancelledBasketSizeLineChart);
