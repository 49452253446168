import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import { ORDER_COUNT_KEYS } from '../../../config/constants/analytics';
import AnalyticsHeatmapContainer from './AnalyticsHeatmapContainer';

const CancelledOrderHeatmapChart = ({
  analyticsDataLoading,
  analyticsData,
  selectedTimePeriod,
  isComparisonEnabled,
}) => {
  const targetCompletedOrderKey = ORDER_COUNT_KEYS.CANCELLED_ORDERS;
  const renderChart = (
    <AnalyticsHeatmapContainer
      analyticsData={analyticsData}
      analyticsDataLoading={analyticsDataLoading}
      selectedTimePeriod={selectedTimePeriod}
      targetKey={targetCompletedOrderKey}
      seriesName="Realized Revenue"
      isComparisonEnabled={isComparisonEnabled}
    />
  );
  return <div className="flex flex-col p-4 h-full">{renderChart}</div>;
};
CancelledOrderHeatmapChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  isComparisonEnabled: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
  isComparisonEnabled: selectors.makeSelectComparisonEnabled(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(CancelledOrderHeatmapChart);
