export const LOAD_FAILED_NO_INTERNET_MSG =
  'Failed to load %s, please check your Internet connection and try again.';
export const CREATE_FAILED_NO_INTERNET_MSG =
  'Failed to create %s, please check your Internet connection and try again.';
export const UPDATE_FAILED_NO_INTERNET_MSG =
  'Failed to update %s, please check your Internet connection and try again.';
export const DELETE_FAILED_NO_INTERNET_MSG =
  'Failed to delete %s, please check your Internet connection and try again.';
export const MODIFIER_ALREADY_ATTACHED_MSG =
  "The modifier group is already attached to %s. Kindly remove the modifiers from the attached item's before deleting.";
export const CONNECTION_INTERREPED_MSG =
  'No internet connection. Please check your network settings and try again.';
export const PASSWORD_CHANGE_FAILED_MSG =
  'Failed to save password, please check your Internet connection and try again.';
export const PASSWORD_RESET_LINK_FAILED =
  'Failed to sent password reset link. Please check your Internet connection and try again.';
export const PASSWORD_RESET_REQUEST_FAILED =
  'Failed to reset password. Please check your Internet connection and try again.';
export const FILE_DOWNLOAD_FAILED_MSG =
  'Failed to download %s, please check your Internet connection and try again.';
export const UPLOAD_FAILED_NO_INTERNET_MSG =
  'Failed to upload %s, please check your Internet connection and try again.';
export const SEND_FAILED_NO_INTERNET_MSG =
  'Failed to send %s, please check your Internet connection and try again.';
export const LOGIN_FAILED_NO_INTERNET_MSG =
  'Failed to login to %s, please check your Internet connection and try again.';
export const CLONE_FAILED_NO_INTERNET_MSG =
  'Cloning Operation Failed. Please check your internet connection and try again.';
export const COPY_FAILED_NO_INTERNET_MSG =
  'Copying Operation Failed. Please check your internet connection and try again.';
export const ADD_FAILED_NO_INTERNET_MSG =
  'Failed to add %s, please check your Internet connection and try again.';
export const LINK_FAILED_NO_INTERNET_MSG =
  'Failed to link %s, please check your Internet connection and try again.';
export const DOWNLOAD_FAILED_NO_INTERNET_MSG =
  'Failed to download %s, please check your Internet connection and try again.';
export const SAVE_FAILED_NO_INTERNET_MSG =
  'Failed to save %s, please check your Internet connection and try again.';
export const GET_FAILED_NO_INTERNET_MSG =
  'Failed to get %s, please check your Internet connection and try again.';

export const MULTI_TRANSACTION_TYPE_ACTION_MSG =
  'You can only have one transaction type action point';

export default {
  LOAD_FAILED_NO_INTERNET_MSG,
  CREATE_FAILED_NO_INTERNET_MSG,
  UPDATE_FAILED_NO_INTERNET_MSG,
  DELETE_FAILED_NO_INTERNET_MSG,
  MODIFIER_ALREADY_ATTACHED_MSG,
  CONNECTION_INTERREPED_MSG,
  PASSWORD_CHANGE_FAILED_MSG,
  PASSWORD_RESET_LINK_FAILED,
  PASSWORD_RESET_REQUEST_FAILED,
  FILE_DOWNLOAD_FAILED_MSG,
  UPLOAD_FAILED_NO_INTERNET_MSG,
  SEND_FAILED_NO_INTERNET_MSG,
  LOGIN_FAILED_NO_INTERNET_MSG,
  CLONE_FAILED_NO_INTERNET_MSG,
  COPY_FAILED_NO_INTERNET_MSG,
  ADD_FAILED_NO_INTERNET_MSG,
  LINK_FAILED_NO_INTERNET_MSG,
  DOWNLOAD_FAILED_NO_INTERNET_MSG,
  SAVE_FAILED_NO_INTERNET_MSG,
  GET_FAILED_NO_INTERNET_MSG,
  MULTI_TRANSACTION_TYPE_ACTION_MSG,
};
