import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SCButton, SCInput } from 'rollup-allspark';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from '../../utils';
import { changeEntity } from './actions';
import { makeSelectProperty } from './selectors';

const AnalyticsHourPicker = ({
  handleChangeEntity,
  containerClass,
  analyticsStartTime,
  analyticsEndTime,
  analyticsHourReset,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [startTime, setStartTime] = useState(analyticsStartTime);
  const [startAmPm, setStartAmPm] = useState('AM');
  const [endTime, setEndTime] = useState(analyticsEndTime);
  const [endAmPm, setEndAmPm] = useState('PM');
  const [error, setError] = useState('');
  const [isOpenPickerDropdown, setIsOpenPickerDropdown] = useState(false);
  const analyticsHourPickerRef = useRef(null);

  useEffect(() => {
    if (analyticsHourReset) {
      setStartTime(analyticsStartTime);
      setEndTime(analyticsEndTime);
    }
  }, [analyticsStartTime, analyticsEndTime, analyticsHourReset]);

  const handleOutsideSelectClick = useCallback(
    event => {
      if (!isOpenPickerDropdown) return;
      if (
        analyticsHourPickerRef.current &&
        !analyticsHourPickerRef.current.contains(event.target) &&
        !event.target.closest('.hour-picker-viewer')
      ) {
        setIsOpenPickerDropdown(false);
        setError('');
        if (!isEmpty(analyticsStartTime) && !isEmpty(analyticsEndTime)) {
          setStartTime(convertTo12HourFormat(analyticsStartTime).hours);
          setStartAmPm(convertTo12HourFormat(analyticsStartTime).amPm);
          setEndTime(convertTo12HourFormat(analyticsEndTime).hours);
          setEndAmPm(convertTo12HourFormat(analyticsStartTime).amPm);
        } else {
          setStartTime('');
          setEndTime('');
          setStartAmPm('AM');
          setEndAmPm('PM');
        }
      }
    },
    [isOpenPickerDropdown],
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideSelectClick);
    return () =>
      document.removeEventListener('click', handleOutsideSelectClick);
  }, [handleOutsideSelectClick]);

  useEffect(() => {
    if (initialLoad) {
      const formattedStartTime = formatTime(startTime, startAmPm);
      const formattedEndTime = formatTime(endTime, endAmPm);
      handleChangeEntity({
        key: 'analyticsStartTime',
        value: formattedStartTime,
      });
      handleChangeEntity({
        key: 'analyticsEndTime',
        value: formattedEndTime,
      });
    }
    compareStartAndEndTime();
    setInitialLoad(false);
  }, [startTime, endTime, startAmPm, endAmPm, initialLoad]);

  const formatTime = (time, amPm) => {
    if (!time) return ''; // Handle case where time is not selected yet
    const [hours] = time.split(':');
    let formattedHours = parseInt(hours, 10);
    if (amPm === 'PM' && formattedHours !== 12) {
      formattedHours += 12;
    } else if (amPm === 'AM' && formattedHours === 12) {
      formattedHours = 0;
    }
    return `${formattedHours}`;
  };

  const convertTo12HourFormat = time => {
    if (!time) return '';
    let [hours] = time.split(':');
    hours = parseInt(hours, 10);
    const amPm = hours >= 12 ? 'PM' : 'AM';
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }
    return {
      hours: `${hours}`,
      amPm,
    };
  };

  const validateTime = time => {
    const [hours] = time.split(':');
    const formattedHours = parseInt(hours, 10);
    if (formattedHours < 1 || formattedHours > 12) {
      setError('Time should be in the range of 1 to 12');
      return false;
    }
    setError('');
    return true;
  };

  const compareStartAndEndTime = () => {
    let valid = true;

    if (isEmpty(startTime) && isEmpty(endTime)) {
      setError('');
    } else {
      const formattedStartTime = formatTime(startTime, startAmPm);
      const formattedEndTime = formatTime(endTime, endAmPm);
      const start24Hour = formatTime(formattedStartTime, startAmPm);
      const end24Hour = formatTime(formattedEndTime, endAmPm);
      if (Number(start24Hour) >= Number(end24Hour)) {
        setError('Start time must be before end time');
        valid = false;
      } else {
        setError('');
        valid = true;
      }
    }
    return valid;
  };

  const handleHourSubmit = () => {
    const formattedStartTime = formatTime(startTime, startAmPm);
    const formattedEndTime = formatTime(endTime, endAmPm);

    handleChangeEntity({
      key: 'analyticsStartTime',
      value: formattedStartTime,
    });
    handleChangeEntity({
      key: 'analyticsEndTime',
      value: formattedEndTime,
    });
    handleChangeEntity({
      key: 'analyticsHourReset',
      value: false,
    });
    setIsOpenPickerDropdown(false);
  };

  const handleAmPmClickStart = amPm => {
    setStartAmPm(amPm);
  };

  const handleAmPmClickEnd = amPm => {
    setEndAmPm(amPm);
  };

  const handleChangeStartTime = e => {
    const { value } = e.target;
    if (validateTime(value)) {
      setStartTime(value);
    }
  };

  const handleChangeEndTime = e => {
    const { value } = e.target;
    if (validateTime(value)) {
      setEndTime(value);
    }
  };

  const amPmButton = (currentAmPm, handleAmPmClick, selectedAmPm) => {
    const isSelected = selectedAmPm === currentAmPm;
    return (
      <SCButton
        variant="primary"
        size="sm"
        action={() => handleAmPmClick(currentAmPm)}
        className={`flex rounded-full items-center justify-center gap-x-1 w-1/2 ${
          isSelected ? 'text-white bg-primary' : '!text-grey-darker !bg-grey'
        }`}
      >
        <p className="text-xs capitalize font-medium">{currentAmPm}</p>
      </SCButton>
    );
  };

  return (
    <div
      className={`analytics-hour-picker-container relative ${containerClass}`}
    >
      <div
        className="hour-picker-viewer bg-primary-lighter rounded-sm px-2 cursor-pointer items-center flex justify-between h-[36px]"
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsOpenPickerDropdown(!isOpenPickerDropdown);
        }}
        onKeyDown={() => {}}
        ref={analyticsHourPickerRef}
      >
        {isEmpty(startTime) && isEmpty(endTime) ? (
          <p className="text-grey-darker"> Select Start and End time</p>
        ) : (
          <p className="text-black text-sm">
            {`${startTime} ${
              !isEmpty(startTime) ? startAmPm : ''
            } - ${endTime} ${!isEmpty(endTime) ? endAmPm : ''}`}
          </p>
        )}
        <i
          className={`sc-keyboard-arrow-${
            isOpenPickerDropdown ? 'up' : 'down'
          } text-2xl text-primary font-medium`}
        ></i>
      </div>
      {/* DROPDOWN */}
      {isOpenPickerDropdown && (
        <div
          className="analytics-hour-picker p-4 bg-white rounded-sm border mt-1 drop-shadow-sm absolute w-full z-50"
          ref={analyticsHourPickerRef}
        >
          <div className="flex mb-4 items-center w-full">
            <SCInput
              type="number"
              inputClass="border rounded-sm "
              placeholder="Start Time"
              className="flex-1 mr-2"
              label="Start Time"
              value={startTime}
              handleChange={handleChangeStartTime}
            />
            <div className="flex p-1 bg-grey rounded-full items-center mt-2">
              {amPmButton('AM', handleAmPmClickStart, startAmPm)}
              {amPmButton('PM', handleAmPmClickStart, startAmPm)}
            </div>
          </div>
          <div className="flex mb-4 items-center w-full">
            <SCInput
              type="number"
              inputClass="border rounded-sm"
              className="flex-1 mr-2"
              placeholder="End Time"
              label="End Time"
              value={endTime}
              handleChange={handleChangeEndTime}
            />
            <div className="flex p-1 bg-grey rounded-full items-center mt-2">
              {amPmButton('AM', handleAmPmClickEnd, endAmPm)}
              {amPmButton('PM', handleAmPmClickEnd, endAmPm)}
            </div>
          </div>
          <div className="text-xs text-red-dark text-center p-2">
            {!isEmpty(error) ? error : ''}
          </div>
          <SCButton
            label="Ok"
            className="w-full rounded-sm"
            action={handleHourSubmit}
            disabled={!isEmpty(error) || isEmpty(startTime) || isEmpty(endTime)}
          />
        </div>
      )}
    </div>
  );
};

AnalyticsHourPicker.propTypes = {
  handleChangeEntity: PropTypes.func,
  containerClass: PropTypes.string,
  analyticsStartTime: PropTypes.any,
  analyticsEndTime: PropTypes.any,
  analyticsHourReset: PropTypes.bool,
};

AnalyticsHourPicker.defaultProps = {
  containerClass: '',
};

const mapStateToProps = createStructuredSelector({
  analyticsStartTime: makeSelectProperty('analyticsStartTime'),
  analyticsEndTime: makeSelectProperty('analyticsEndTime'),
  analyticsHourReset: makeSelectProperty('analyticsHourReset'),
});

function mapDispatchToProps(dispatch) {
  return {
    handleChangeEntity: params => dispatch(changeEntity(params)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AnalyticsHourPicker);
