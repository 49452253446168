import { isString, isNumber, isObject } from './index';

const compareNumbers = (a, b) => a - b;
const compareDate = (a, b, dateKey) =>
  new Date(a[dateKey]) - new Date(b[dateKey]);

const diffInCollection = (a = [], b = [], key) => {
  if (!a.length && !b.length) return [];

  let bigger;
  let smaller;

  if (a.length > b.length) {
    bigger = [...a];
    smaller = [...b];
  } else {
    bigger = [...b];
    smaller = [...a];
  }

  return key && key.length > 0
    ? bigger.filter(bg => !smaller.some(sm => bg[key] === sm[key]))
    : bigger.filter(bg => !smaller.some(sm => bg === sm));
};

const sortArray = arr => arr.sort(compareNumbers);
const sortArrayByDate = (arr, dateKey) =>
  arr.sort((a, b) => compareDate(a, b, dateKey));

const findObjectByProperty = (arrayObjects, key, keyValue) =>
  arrayObjects.find(object => object[key] === keyValue) || null;

const getValueByDotKey = (object, dotKey) => {
  const keys = dotKey.split('.');
  return keys.reduce((value, key) => {
    if (isObject(value) && key in value) {
      return value[key];
    }
    return undefined; // Property not found
  }, object);
};

const sortArrayByKey = (arr, sortKey, isAsc = true) => {
  const sorted = [...arr].sort((a, b) => {
    const aKey = getValueByDotKey(a, sortKey);
    const bKey = getValueByDotKey(b, sortKey);

    if (isString(aKey) && isString(bKey)) {
      return isAsc ? aKey.localeCompare(bKey) : bKey.localeCompare(aKey);
    }
    if (isNumber(aKey) && isNumber(bKey)) {
      return isAsc ? aKey - bKey : bKey - aKey;
    }

    return 0;
  });
  return sorted;
};

export {
  sortArray,
  sortArrayByDate,
  diffInCollection,
  findObjectByProperty,
  sortArrayByKey,
};

export const hasValue = (array, key, value) =>
  array.some(obj => obj[key] !== '' && obj[key] === value);
