import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../selectors';
import { AVG_BASKET_KEYS } from '../../../config/constants/analytics';
import AnalyticsHeatmapContainer from './AnalyticsHeatmapContainer';

const CompletedBasketSizeHeatmapChart = ({
  analyticsDataLoading,
  analyticsData,
  selectedTimePeriod,
  isComparisonEnabled,
}) => {
  const targetRevenueKey = AVG_BASKET_KEYS.COMPLETED_BASKET_SIZE;
  const renderChart = (
    <AnalyticsHeatmapContainer
      analyticsData={analyticsData}
      analyticsDataLoading={analyticsDataLoading}
      selectedTimePeriod={selectedTimePeriod}
      targetKey={targetRevenueKey}
      seriesName="Realized Revenue"
      isComparisonEnabled={isComparisonEnabled}
    />
  );
  return <div className="flex flex-col p-4 h-full">{renderChart}</div>;
};
CompletedBasketSizeHeatmapChart.propTypes = {
  analyticsData: PropTypes.array,
  analyticsDataLoading: PropTypes.bool,
  selectedTimePeriod: PropTypes.string,
  isComparisonEnabled: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  analyticsDataLoading: selectors.makeSelectAnalyticsDataLoading(),
  analyticsData: selectors.makeSelectAnalyticsData(),
  selectedTimePeriod: selectors.makeSelectTimePeriodSlug(),
  isComparisonEnabled: selectors.makeSelectComparisonEnabled(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(CompletedBasketSizeHeatmapChart);
