import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { ORDER_COUNT_KEYS } from '../../../config/constants/analytics';
import {
  getIntervalAndMax,
  getNonZeroStackedAxisSeries,
} from '../../../utils/nativeAnalyticsUtils';
import { readablePrice } from '../../../utils/getCurrency';

const StackedBarChart = ({
  id,
  xAxisData,
  yAxisData,
  currencyObj,
  exportedCsvHeaderTitle,
}) => {
  const isOrderCountChart = Object.values(ORDER_COUNT_KEYS).includes(id);
  const flattenedYAxisData =
    yAxisData && yAxisData.length
      ? yAxisData
          .reduce((acc, curr) => {
            curr.data.forEach((value, index) => {
              acc[index] = (acc[index] || 0) + parseFloat(value);
            });
            return acc;
          }, [])
          .map(value => Math.round(value * 100) / 100)
      : [];

  const maxValueOfSeries = Math.max(...flattenedYAxisData);
  const { yAxisMaxValue, yAxisTickAmount } =
    getIntervalAndMax(maxValueOfSeries) || {};

  const { xAxisSeries, yAxisSeries } = getNonZeroStackedAxisSeries(
    xAxisData,
    yAxisData,
  );

  const chartConfig = {
    series: yAxisSeries,
    options: {
      tooltip: {
        y: {
          formatter(value) {
            return isOrderCountChart
              ? value
              : readablePrice(value * 100, currencyObj);
          },
        },
      },
      chart: {
        id,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: `provider_wise_${id}`,
              headerCategory: exportedCsvHeaderTitle,
              headerValue: 'value',
            },
            svg: {
              filename: `provider_wise_${id}`,
            },
            png: {
              filename: `provider_wise_${id}`,
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.1,
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: xAxisSeries,
        labels: {
          hideOverlappingLabels: false,
          trim: true,
        },
      },
      yaxis: {
        max: yAxisMaxValue || 10,
        tickAmount: yAxisTickAmount || 1,
        labels: {
          formatter(val) {
            return val.toFixed(0);
          },
        },
      },
    },
  };
  return (
    <Chart
      options={chartConfig.options}
      series={chartConfig.series}
      type="bar"
      height="350"
    />
  );
};

StackedBarChart.propTypes = {
  xAxisData: PropTypes.array,
  yAxisData: PropTypes.array,
  id: PropTypes.string,
  currencyObj: PropTypes.object,
  exportedCsvHeaderTitle: PropTypes.string,
};
export default StackedBarChart;
