import React from 'react';

const OrderTableHeader = () => {
  const headerKeys = [
    {
      key: 'order_uuid',
      title: 'Order ID',
      align: 'left',
    },
    {
      key: 'external_id',
      title: 'External ID',
      align: 'left',
    },
    {
      key: 'order_created_time',
      title: 'Created At',
      align: 'left',
    },
    {
      key: 'brand',
      title: 'Brand',
      align: 'left',
    },
    {
      key: 'branch',
      title: 'Branch',
      align: 'left',
    },
    {
      key: 'provider',
      title: 'Aggregator',
      align: 'left',
    },
    {
      key: 'status',
      title: 'Status',
      align: 'left',
    },
    {
      key: 'menu_items_ordered',
      title: 'Items Ordered',
      align: 'right',
    },
    {
      key: 'total_amount_base',
      title: 'Gross Order Value',
      align: 'right',
    },
    {
      key: 'total_delivery_fee',
      title: 'Delivery Fee',
      align: 'right',
    },
    {
      key: 'discount',
      title: 'Discount',
      align: 'right',
    },
    {
      key: 'total_amount_paid',
      title: 'Net Order Value',
      align: 'right',
    },
    {
      key: 'cancellation_reason',
      title: 'Cancellation Reason',
      align: 'left',
    },
    {
      key: 'cancelled_by',
      title: 'Cancelled By',
      align: 'left',
    },
  ];
  const baseClass = [
    'p-3 bg-primary-lighter border-color-white border-r-2 font-normal',
  ];
  const renderHeaderCol = headerKeys.map(cell => {
    const alignClass = `text-${cell.align}`;

    return (
      <th key={cell.key} className={`${baseClass} ${alignClass}`}>
        {cell.title}
      </th>
    );
  });
  return <tr>{renderHeaderCol}</tr>;
};

export default OrderTableHeader;
